import { Input } from "@mailbrew/uikit";
import ConnectTwitterAccountSection from "components/ConnectTwitterAccountSection";
import ImagesStyleSetting from "components/ImagesStyleSetting";
import MaxTweetsPerUserSection from "components/MaxTweetsPerUserSection";
import MaxTweetsSection from "components/MaxTweetsSection";
import { Disable } from "react-disable";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import { updateSourceField } from "reducers/newslettersReducer";
import ConnectedTwitterAccount from "components/editor/ConnectedTwitterAccount";
import EditorSubpage from "components/editor/EditorSubpage";
import SettingSwitchRow from "components/editor/SettingSwitchRow";
import SourceEditorSection from "components/editor/SourceEditorSection";
import LayoutOptions from "components/editor/LayoutOptions";
import PopularOrLatestSelector from "components/editor/PopularOrLatestSelector";

const minTweets = 1;
const maxPosts = 200;

const TwitterTimelineSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  const twitterAccount = user?.connected_accounts?.find((acc) => acc.type === "twitter");
  const twitterAccountConnected = twitterAccount && !twitterAccount.error;

  return (
    <EditorSubpage
      title={source.title?.length ? source.title : "Twitter Timeline"}
      type={source.type}
      sourceIndex={sourceIndex}
    >
      {!twitterAccountConnected && (
        <SourceEditorSection icon="twitter" title="Connected Twitter Account" noBorderTop>
          <ConnectedTwitterAccount />
        </SourceEditorSection>
      )}
      <Disable disabled={!(twitterAccount && !twitterAccount.error)}>
        <Disable disabled={!twitterAccountConnected}>
          {/* TITLE */}
          <SourceEditorSection title="Title" icon="writeBold" noBorderTop>
            <Input
              placeholder="Twitter Timeline"
              type="text"
              autoComplete="off"
              value={source.title}
              onChange={(e) => dispatch(updateSourceField(sourceIndex, "title", e.target.value))}
            />
          </SourceEditorSection>

          <SourceEditorSection title="Tweets to include" icon="twitter">
            {/* MODE */}
            <PopularOrLatestSelector source={source} sourceIndex={sourceIndex} />
            {/* RETWEETS */}
            <SettingSwitchRow
              mt={3}
              icon="retweet"
              copy="Show Retweets"
              state={source.show_retweets}
              onChange={() => {
                dispatch(updateSourceField(sourceIndex, "show_retweets", !source.show_retweets));
              }}
            />
          </SourceEditorSection>

          {/* MAX NUMBER OF TWEETS */}
          <MaxTweetsSection source={source} sourceIndex={sourceIndex} min={minTweets} max={maxPosts} />

          {/* MAX TWEETS PER USER */}
          <MaxTweetsPerUserSection source={source} sourceIndex={sourceIndex} />

          <SourceEditorSection>
            <ImagesStyleSetting
              style={source.images_style}
              onChange={(style) => dispatch(updateSourceField(sourceIndex, "images_style", style))}
              pb={0}
              pt={0}
            />
            <LayoutOptions
              selected={source.columns || 1}
              onChange={(option) => dispatch(updateSourceField(sourceIndex, "columns", option))}
            />
          </SourceEditorSection>
        </Disable>
      </Disable>

      {twitterAccountConnected && <ConnectTwitterAccountSection noBorderBottom />}
    </EditorSubpage>
  );
};

export default TwitterTimelineSourceEditor;
