import { Icon, Spinner, useConfig } from "@mailbrew/uikit";
import StyledTooltip from "components/StyledTooltip";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce/lib";

const NewsletterSyncIndicator = () => {
  const syncing = useSelector((state) => state.newsletters.syncing);
  const errors = useSelector((state) => state.app.errors);
  const syncError = errors["sync"];
  const [debouncedSyncing] = useDebounce(syncing, 500);

  const config = useConfig();

  const state = (() => {
    if (syncError) return "sync_error";
    if (debouncedSyncing) return "syncing";
    return "synced";
  })();

  return (
    <>
      {state === "sync_error" && (
        <>
          <StyledTooltip title={syncError}>
            <Icon name="warning" />
          </StyledTooltip>
        </>
      )}
      {state === "syncing" && (
        <>
          <StyledTooltip title={"Syncing your changes"}>
            <Spinner color={config.colors.c4} size={18} />
          </StyledTooltip>
        </>
      )}
    </>
  );
};

export default NewsletterSyncIndicator;
