import EditorSubpage from "components/editor/EditorSubpage";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";

const ProductHuntSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  return (
    <EditorSubpage type={source.type} showAddAnother={false} sourceIndex={sourceIndex}>
      {/* NUMBER OF POSTS */}
      <NumPostsSourceEditor
        source={source}
        noBorderTop
        sourceIndex={sourceIndex}
        min={1}
        max={10}
        fieldName="num_posts"
        explanationModalBody="In each issue of your digest, Mailbrew includes the top products from Product Hunt published since the previous issue of your digest."
        shortExplanationBuilder={(numPosts) =>
          numPosts > 1 ? `Receive the ${numPosts} most popular products.` : `Receive the most popular product.`
        }
      />
    </EditorSubpage>
  );
};

export default ProductHuntSourceEditor;
