import { Button, H3, HStack, Modal, Spacer } from "@mailbrew/uikit";
import ConnectTwitterAccountSection from "components/ConnectTwitterAccountSection";
import ImagesStyleSetting from "components/ImagesStyleSetting";
import MaxTweetsSection from "components/MaxTweetsSection";
import TitleDivider from "components/TitleDivider";
import { sourcesData } from "data/sourcesData";
import useEditorBackNavigationHandler from "hooks/useEditorBackNavigationHandler";
import debounce from "just-debounce-it";
import { useState } from "react";
import { Disable } from "react-disable";
import { useDispatch, useSelector } from "react-redux";
import TwitterAccountsSelector from "components/TwitterAccountsSelector";
import api from "dependencies/api";
import useRandomItem from "hooks/useRandomItem";
import { addSources, updateSourceField } from "reducers/newslettersReducer";
import kFormatter from "utils/kFormatter";
import { handleNetworkError } from "utils/networkErrors";
import ConnectedTwitterAccount from "components/editor/ConnectedTwitterAccount";
import EditorSubpage from "components/editor/EditorSubpage";
import SearchField from "components/editor/SearchField";
import SettingSwitchRow from "components/editor/SettingSwitchRow";
import SourceEditorSection from "components/editor/SourceEditorSection";
import LayoutOptions from "components/editor/LayoutOptions";
import PopularOrLatestSelector from "components/editor/PopularOrLatestSelector";

const minPosts = 1;
const maxPosts = 20;

const TwitterUserSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();
  const newsletter = useSelector((state) => state.newsletters.currentNewsletter);
  const user = useSelector((state) => state.auth.user);
  const twitterAccount = user?.connected_accounts?.find((acc) => acc.type === "twitter");
  const twitterAccountConnected = twitterAccount && !twitterAccount.error;

  const [showAddMultipleUsersModal, setShowAddMultipleUsersModal] = useState(false);
  const placeholder = useRandomItem(placeholders);
  const sourceIsValid = sourcesData[source.type].isValid(source);

  const goBack = useEditorBackNavigationHandler();

  const handleAddMultipleUsers = () => {
    setShowAddMultipleUsersModal(true);
  };

  return (
    <EditorSubpage
      title={source.screen_name ? `@${source.screen_name}` : "Twitter"}
      type={source.type}
      sourceIndex={sourceIndex}
    >
      {!twitterAccountConnected && (
        <SourceEditorSection icon="twitter" title="Connected Twitter Account" noBorderTop>
          <ConnectedTwitterAccount />
        </SourceEditorSection>
      )}
      <Disable disabled={!twitterAccount || twitterAccount?.error}>
        {/* USER */}
        <SourceEditorSection
          noBorderTop
          title="Twitter User"
          subtitle="Search for a Twitter user by name or username."
          icon="twitter"
          noPadding={!sourceIsValid}
          style={!sourceIsValid ? { borderBottom: "none", paddingBottom: 0 } : {}}
        >
          <SearchField
            initialValue={source.screen_name}
            placeholder={placeholder}
            autoFocus={source?.screen_name?.length === 0 && twitterAccountConnected}
            fetchSuggestions={fetchSuggestions}
            onSuggestionClick={(suggestion) => {
              dispatch(updateSourceField(sourceIndex, "screen_name", suggestion));
            }}
          />
        </SourceEditorSection>

        {!sourceIsValid && (
          <SourceEditorSection noBorderTop style={{ paddingTop: 0 }}>
            <TitleDivider title="or" small />

            <Button icon="people" width="100%" onClick={handleAddMultipleUsers}>
              Add Multiple Users
            </Button>
          </SourceEditorSection>
        )}

        {sourceIsValid && (
          <>
            {/* MODE */}
            <SourceEditorSection title="Tweets to include" icon="twitter">
              {/* MODE */}
              <PopularOrLatestSelector source={source} sourceIndex={sourceIndex} />
              {/* RETWEETS */}
              <SettingSwitchRow
                mt={3}
                icon="retweet"
                copy="Show Retweets"
                state={source.show_retweets}
                onChange={() => {
                  dispatch(updateSourceField(sourceIndex, "show_retweets", !source.show_retweets));
                }}
              />
            </SourceEditorSection>

            {/* MAX NUMBER OF TWEETS */}
            <MaxTweetsSection source={source} sourceIndex={sourceIndex} min={minPosts} max={maxPosts} />

            <SourceEditorSection>
              <ImagesStyleSetting
                style={source.images_style}
                onChange={(style) => dispatch(updateSourceField(sourceIndex, "images_style", style))}
                pb={0}
                pt={0}
              />
              <LayoutOptions
                selected={source.columns || 1}
                onChange={(option) => dispatch(updateSourceField(sourceIndex, "columns", option))}
              />
            </SourceEditorSection>
          </>
        )}
      </Disable>

      {twitterAccountConnected && <ConnectTwitterAccountSection noBorderBottom />}

      <Modal
        show={showAddMultipleUsersModal}
        setShow={setShowAddMultipleUsersModal}
        width="70em"
        fullScreenAt={960}
        style={{
          height: "50em",
          maxHeight: "80vh",
          minHeight: "28em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <H3 align="center">Add multiple Twitter users</H3>
        <Spacer />
        <TwitterAccountsSelector scrollable>
          {(sources) => (
            <>
              <Spacer size="m" />
              <HStack align="stretch" breakAt={400} width="100%" maxWidth="30em" noWrap>
                <Button width="100%" onClick={() => setShowAddMultipleUsersModal(false)} variant="secondary">
                  Cancel
                </Button>
                <Button
                  width="100%"
                  onClick={() => {
                    const dedupedSources = removeDuplicateTwitterUserSources(sources, newsletter.sources);
                    dispatch(addSources(dedupedSources));
                    goBack();
                  }}
                  disabled={sources.length === 0}
                >
                  Add to Brew
                </Button>
              </HStack>
            </>
          )}
        </TwitterAccountsSelector>
      </Modal>
    </EditorSubpage>
  );
};

function removeDuplicateTwitterUserSources(sources, existingSources) {
  const existingTwitterUsersScreenNames = new Set();

  for (const source of existingSources) {
    if (source.type === "twitter_user") {
      existingTwitterUsersScreenNames.add(source.screen_name);
    }
  }

  return sources.filter((s) => !existingTwitterUsersScreenNames.has(s.screen_name));
}

export const fetchSuggestions = debounce(
  async (
    query,
    {
      setSuggestions,
      setSuggestionsNames,
      setSuggestionsImages,
      setSuggestionsDetails,
      setSuggestionsPayloads,
      setLoading,
      setError,
    }
  ) => {
    if (!query || query.length === 0) {
      setSuggestions([]);
      setError(null);
      return;
    }

    setLoading(true);

    try {
      const res = await api.get("/twitter_user_search/", {
        params: { q: query },
      });
      setSuggestions(res.data.map((s) => s.screen_name));
      setSuggestionsNames(res.data.map((s) => s.name));
      setSuggestionsImages(res.data.map((s) => s.profile_image_url));
      setSuggestionsDetails(res.data.map((s) => `@${s.screen_name} - ${kFormatter(s.followers)} followers`));
      setSuggestionsPayloads(res.data);
    } catch (err) {
      const handled = handleNetworkError(err);
      if (handled) return;
      const errorMessage = err.response.data.detail || "Can't search Twitter right now.";
      setSuggestions([]);
      setSuggestionsDetails([]);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  },
  300
);

// spell-checker: disable
const placeholders = [
  "Jack Dorsey or @jack",
  "Francesco Di Lorenzo or @frankdilo",
  "Fabrizio Rinaldi or @linuz90",
  "Ferruccio Balestreri or @0xferruccio",
  "Anne-Laure Le Cunff or @anthilemoon",
  "DHH or @dhh",
  "Marques Brownlee or @MKBHD",
  "Kai Brach or @KaiBrach",
  "Jonathan Morrison or @tldtoday",
  "Dan Abramov or @dan_abramov",
  "Matthew Panzarino or @panzer",
  "Indie Hackers or @IndieHackers",
  "The Verge or @verge",
  "TNW or @thenextweb",
  "Owen Williams or @ow",
  "Tobias Van Schneider or @vanschneider",
  "M.G. Siegler or @mgsiegler",
  "Stu Maschwitz or @5tu",
  "Chris Coyier or @chriscoyier",
  "Ryan Jones or @rjonesy",
  "Federico Viticci or @viticci",
  "Ryan Hoover or @rrhoover",
  "Christian Selig or @ChristianSelig",
  "Nic Nguyen or @itsnicolenguyen",
  "Casey Neistat or @Casey",
  "Casey Newton or @CaseyNewton",
  "Figma or @figmadesign",
  "Framer or @framer",
];
// spell-checker: enable

export default TwitterUserSourceEditor;
