import { Spacer } from "@mailbrew/uikit";
import { colorPickerPalette } from "components/editor/ColorPicker";
import EditorSubpage from "components/editor/EditorSubpage";
import ImagesShow from "components/editor/ImagesShow";
import LayoutOptions from "components/editor/LayoutOptions";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import PostBody from "components/editor/PostBody";
import QuoteMode from "components/editor/QuoteMode";
import SearchField from "components/editor/SearchField";
import SourceEditorSection from "components/editor/SourceEditorSection";
import { SourceTitleEditorSection } from "components/editor/SourceTitleEditor";
import InfoButton from "components/InfoButton";
import { searchRssFeeds } from "components/onboarding/RssStep";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";

const RssFeedSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const sourceIsValid = source?.url;

  const dispatch = useDispatch();

  const handleFeedUrlSubmit = (_, __, feed) => {
    if (!feed) return;
    dispatch(updateSourceField(sourceIndex, "url", feed.feed_url));
    dispatch(updateSourceField(sourceIndex, "title", feed.title));
  };

  return (
    <EditorSubpage title={source.title || "RSS Feed"} type={source.type} sourceIndex={sourceIndex}>
      {/* FEED URL */}
      <SourceEditorSection title="Website" icon="link" noBorderTop>
        <SearchField
          key={source.url}
          initialValue={source.url}
          placeholder="daringfireball.net"
          fetchSuggestions={searchRssFeeds}
          onSuggestionClick={handleFeedUrlSubmit}
          autofillSuggestionClick="empty"
          autoFocus={!(source?.title?.length > 0)}
        />
        <Spacer size={2} />
        <InfoButton
          title="Let us find RSS for you"
          text="We'll find its RSS feed for you, but you can also paste an RSS URL directly."
        >
          Type a website name or paste a URL
        </InfoButton>
      </SourceEditorSection>

      {sourceIsValid && (
        <Fragment>
          {/* TITLE */}
          <SourceTitleEditorSection sourceIndex={sourceIndex} />

          {/* NUMBER OF POSTS */}
          <NumPostsSourceEditor
            source={source}
            sourceIndex={sourceIndex}
            min={1}
            max={30}
            fieldName="num_posts"
            explanationModalBody="In each issue of your digest, Mailbrew includes the most recent unread posts in the website published since your previous issue."
            shortExplanationBuilder={(numPosts) =>
              numPosts > 1
                ? `You'll receive the ${numPosts} most recent posts.`
                : `You'll receive the most recent post.`
            }
          />
          <SourceEditorSection>
            <ImagesShow
              state={source.show_images === undefined ? true : source.show_images}
              onChange={() => dispatch(updateSourceField(sourceIndex, "show_images", !source.show_images))}
              pt={0}
              pb={0}
            />
            <PostBody
              disabled={source.quote_mode}
              selected={source.post_body || "excerpt"}
              onChange={(option) => dispatch(updateSourceField(sourceIndex, "post_body", option))}
            />
            <LayoutOptions
              selected={source.columns || 1}
              onChange={(option) => dispatch(updateSourceField(sourceIndex, "columns", option))}
              pt={1}
              pb={0}
            />
          </SourceEditorSection>
          <QuoteMode
            enabled={source.quote_mode}
            onEnabledChange={() => {
              const enabled = !source.quote_mode;

              dispatch(updateSourceField(sourceIndex, "quote_mode", !source.quote_mode));

              // set default color  and text option when first enabling the quote option
              if (enabled && !source.quote_color)
                dispatch(updateSourceField(sourceIndex, "quote_color", colorPickerPalette.quote[0]));
              if (enabled && !source.quote_text_option)
                dispatch(updateSourceField(sourceIndex, "quote_text_option", "title"));
            }}
            color={source.quote_color}
            onColorChange={(color) => dispatch(updateSourceField(sourceIndex, "quote_color", color))}
            quoteTextOption={source.quote_text_option}
            onQuoteTextOptionChange={(opt) => dispatch(updateSourceField(sourceIndex, "quote_text_option", opt))}
          />
        </Fragment>
      )}
    </EditorSubpage>
  );
};

export default RssFeedSourceEditor;
