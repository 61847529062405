import BetaListGlyph from "../images/icons/sources/betaListGlyph.svg";
import BetaListThumb from "../images/icons/sources/betaListThumb.svg";
import CalendarGlyph from "../images/icons/sources/calendarGlyph.svg";
import CalendarThumb from "../images/icons/sources/calendarThumb.svg";
import CovidGlyph from "../images/icons/sources/covidGlyph.svg";
import CovidThumb from "../images/icons/sources/covidThumb.svg";
import CryptoGlyph from "../images/icons/sources/cryptoGlyph.svg";
import CryptoThumb from "../images/icons/sources/cryptoThumb.svg";
import DevToGlyph from "../images/icons/sources/DevToGlyph.svg";
import DevToThumb from "../images/icons/sources/DevToThumb.svg";
import ExchangeRateGlyph from "../images/icons/sources/exchangeRateGlyph.svg";
import ExchangeRateThumb from "../images/icons/sources/exchangeRateThumb.svg";
import GoogleNewsGlyph from "../images/icons/sources/googleNewsGlyph.svg";
import GoogleNewsThumb from "../images/icons/sources/googleNewsThumb.svg";
import HackerNewsGlyph from "../images/icons/sources/hackerNewsGlyph.svg";
import HackerNewsThumb from "../images/icons/sources/hackerNewsThumb.svg";
import InboxGlyph from "../images/icons/sources/inboxGlyph.svg";
import InboxThumb from "../images/icons/sources/inboxThumb.svg";
import ProductHuntGlyph from "../images/icons/sources/productHuntGlyph.svg";
import ProductHuntThumb from "../images/icons/sources/productHuntThumb.svg";
import ReadLaterGlyph from "../images/icons/sources/readLaterGlyph.svg";
import ReadLaterThumb from "../images/icons/sources/readLaterThumb.svg";
import ReadwiseGlyph from "../images/icons/sources/readwiseGlyph.svg";
import ReadwiseThumb from "../images/icons/sources/readwiseThumb.svg";
import RedditGlyph from "../images/icons/sources/redditGlyph.svg";
import RedditThumb from "../images/icons/sources/redditThumb.svg";
import RssGlyph from "../images/icons/sources/rssGlyph.svg";
import RssThumb from "../images/icons/sources/rssThumb.svg";
import SeparatorGlyph from "../images/icons/sources/separatorGlyph.svg";
import SeparatorThumb from "../images/icons/sources/separatorThumb.svg";
import StocksGlyph from "../images/icons/sources/stocksGlyph.svg";
import StocksThumb from "../images/icons/sources/stocksThumb.svg";
import TextGlyph from "../images/icons/sources/textGlyph.svg";
import TextThumb from "../images/icons/sources/textThumb.svg";
import TodayGlyph from "../images/icons/sources/todayGlyph.svg";
import TodayThumb from "../images/icons/sources/todayThumb.svg";
import TwitterGlyph from "../images/icons/sources/twitterGlyph.svg";
import TwitterLikesGlyph from "../images/icons/sources/twitterLikesGlyph.svg";
import TwitterLikesThumb from "../images/icons/sources/twitterLikesThumb.svg";
import TwitterListGlyph from "../images/icons/sources/twitterListGlyph.svg";
import TwitterListThumb from "../images/icons/sources/twitterListThumb.svg";
import TwitterSearchGlyph from "../images/icons/sources/twitterSearchGlyph.svg";
import TwitterSearchThumb from "../images/icons/sources/twitterSearchThumb.svg";
import TwitterThumb from "../images/icons/sources/twitterThumb.svg";
import TwitterTopLinksGlyph from "../images/icons/sources/twitterTopLinksGlyph.svg";
import TwitterTopLinksThumb from "../images/icons/sources/twitterTopLinksThumb.svg";
import YouTubeGlyph from "../images/icons/sources/youTubePlaylistGlyph.svg";
import YouTubeThumb from "../images/icons/sources/youTubePlaylistThumb.svg";

export const sourcesData = {
  covid: {
    name: "Covid-19",
    keywords: "coronavirus",
    thumb: CovidThumb,
    glyph: CovidGlyph,
    color: "#679379",
    isValid: (source) => true,
  },
  subreddit: {
    name: "Subreddit",
    thumb: RedditThumb,
    glyph: RedditGlyph,
    color: "#FF4500",
    isValid: (source) => source.subreddit && source.subreddit.length > 0,
  },
  rss_feed: {
    name: "Websites & RSS",
    keywords: "feeds",
    thumb: RssThumb,
    glyph: RssGlyph,
    color: "#A161F3",
    isValid: (source) => source.title?.length > 0 && source.url?.length > 0,
  },
  twitter_user: {
    name: "Twitter Users",
    shortName: "Users",
    thumb: TwitterThumb,
    glyph: TwitterGlyph,
    color: "#1DA1F2",
    isValid: (source) => source?.screen_name?.length > 0,
  },
  twitter_search: {
    name: "Twitter Search",
    shortName: "Search",
    additionalInfo: "Great to monitor social mentions, topics, accounts, and hashtags.",
    thumb: TwitterSearchThumb,
    glyph: TwitterSearchGlyph,
    color: "#1DA1F2",
    isValid: (source) => source?.query?.length > 0,
  },
  twitter_list: {
    name: "Twitter Lists",
    shortName: "Lists",
    thumb: TwitterListThumb,
    glyph: TwitterListGlyph,
    color: "#1DA1F2",
    isValid: (source) => !!source?.list_id,
  },
  twitter_timeline: {
    name: "Twitter Timeline",
    shortName: "Timeline",
    additionalInfo: "Get the most popular tweets from the accounts you follow.",
    thumb: TwitterListThumb,
    glyph: TwitterListGlyph,
    color: "#1DA1F2",
    isValid: (source) => true,
  },
  twitter_top_links: {
    name: "Twitter Top Links",
    shortName: "Top Links",
    additionalInfo: "See the most popular links shared by the people you follow on Twitter, or any list.",
    thumb: TwitterTopLinksThumb,
    glyph: TwitterTopLinksGlyph,
    color: "#1DA1F2",
    isValid: (source) => true,
  },
  twitter_likes: {
    name: "Twitter Likes By",
    shortName: "Liked By",
    additionalInfo:
      "Discover what tweets, articles, or images your favorite people liked. It works with any Twitter list.",
    thumb: TwitterLikesThumb,
    glyph: TwitterLikesGlyph,
    color: "#1DA1F2",
    isValid: (source) => !!source?.list_id,
  },
  hacker_news: {
    name: "Hacker News",
    keywords: "technology hacker news",
    thumb: HackerNewsThumb,
    glyph: HackerNewsGlyph,
    color: "#FF6600",
    isValid: (source) => true,
  },
  youtube_playlist: {
    name: "YouTube",
    keywords: "video",
    thumb: YouTubeThumb,
    glyph: YouTubeGlyph,
    color: "#FF0000",
    isValid: (source) => source.playlist_id?.length > 0,
  },
  youtube_channel: {
    name: "YouTube",
    thumb: YouTubeThumb,
    glyph: YouTubeGlyph,
    color: "#FF0000",
    isValid: (source) => source.title?.length > 0 && source?.channel_url.length > 0,
    notInModal: true,
  },
  google_news: {
    name: "Google News",
    thumb: GoogleNewsThumb,
    glyph: GoogleNewsGlyph,
    color: "#5383EC",
    isValid: (source) =>
      source.mode === "query" ? source.query?.length > 0 : source.mode === "topic" ? source.url?.length > 0 : false,
  },
  exchange_rate: {
    name: "Exchange Rate",
    additionalInfo: "Get advantage of exchange rate changes, or keep them monitored in your digests.",
    thumb: ExchangeRateThumb,
    glyph: ExchangeRateGlyph,
    color: "#5D7587",
    isValid: (source) => true,
  },
  stocks: {
    name: "Stocks",
    additionalInfo: "Monitor any stock by seeing their latest prices in your digests.",
    keywords: "stonks",
    thumb: StocksThumb,
    glyph: StocksGlyph,
    color: "#539384",
    isValid: (source) => source.targets?.length > 0,
  },
  crypto: {
    name: "Crypto",
    additionalInfo: "Bitcoin? Dogecoin? Ethereum? We got it all. Keep an eye on your crypto with this source.",
    keywords: "bitcoin ethereum hodl hold",
    thumb: CryptoThumb,
    glyph: CryptoGlyph,
    color: "#E0AF13",
    isValid: (source) => source.assets?.length > 0,
  },
  today: {
    name: "Today's Date",
    keywords: "calendar",
    thumb: TodayThumb,
    glyph: TodayGlyph,
    color: "#757C95",
    isValid: (source) => true,
  },
  text: {
    name: "Text",
    keywords: "intro paragraph custom markdown",
    thumb: TextThumb,
    glyph: TextGlyph,
    color: "#757C95",
    isValid: (source) => source.text.length > 0,
  },
  separator: {
    name: "Separator",
    thumb: SeparatorThumb,
    glyph: SeparatorGlyph,
    color: "#757C95",
    isValid: (source) => true,
  },
  inbox: {
    name: "Newsletters",
    additionalInfo: "Receive newsletters in your next Mailbrew digest, instead of cluttering your inbox.",
    keywords: "inbox",
    thumb: InboxThumb,
    glyph: InboxGlyph,
    color: "#605DF7",
    isValid: (source) => true,
    title: "Inbox",
  },
  devto: {
    name: "Dev.to",
    keywords: "programming development javascript react code",
    thumb: DevToThumb,
    glyph: DevToGlyph,
    color: "#08090A",
    isValid: (source) => true,
  },
  product_hunt: {
    name: "Product Hunt",
    keywords: "startups products makers hackers",
    thumb: ProductHuntThumb,
    glyph: ProductHuntGlyph,
    color: "#CC4D29",
    isValid: (source) => true,
  },
  betalist: {
    name: "BetaList",
    keywords: "startups products makers hackers",
    thumb: BetaListThumb,
    glyph: BetaListGlyph,
    color: "#000000",
    isValid: (source) => true,
  },
  read_later: {
    name: "Saved",
    additionalInfo: "Save links for later. We'll add them to your next digest, and you'll be able to read them online.",
    keywords: "read later pocket instapaper",
    thumb: ReadLaterThumb,
    glyph: ReadLaterGlyph,
    color: "#3A3A3A",
    isValid: (source) => true,
  },
  readwise: {
    name: "Readwise",
    additionalInfo: "Get your highlights in your digest. You'll need a Readwise account.",
    keywords: "bookmarks highlights",
    thumb: ReadwiseThumb,
    glyph: ReadwiseGlyph,
    color: "#000000",
    isValid: (source) => true,
  },
  calendar: {
    name: "Calendar",
    additionalInfo: "See today's events at a glance by connecting your Google Calendar account.",
    keywords: "google events meeting zoom",
    thumb: CalendarThumb,
    glyph: CalendarGlyph,
    color: "#5383EC",
    isValid: (source) => true,
  },
};
