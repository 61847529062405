import DropDownRow from "components/DropDownRow";
import { usePaywallState } from "components/PaywallStateProvider";

const PostBody = (props) => {
  const {
    label,
    selected,
    onChange,
    disabled,
    options: providedOptions,
    optionsNames: providedOptionsNames,
    ...rest
  } = props;
  const options = providedOptions ?? ["hidden", "excerpt", "full"];
  const optionsNames = providedOptionsNames ?? ["Hidden", "Excerpt", "Full"];

  const { monetization, config: userConfig, setPaywallModalShown, setHighlightedFeature } = usePaywallState();
  const lockFeature = monetization?.status === "free" && userConfig?.freemium;
  function handleChange(option) {
    if (lockFeature) {
      setHighlightedFeature("layout_options");
      setPaywallModalShown(true);
    } else {
      onChange(option);
    }
  }

  return (
    <DropDownRow
      label={label ?? "Post Body"}
      icon="text"
      selected={selected}
      onChange={handleChange}
      disabled={disabled}
      options={options}
      optionsNames={optionsNames}
      {...rest}
    />
  );
};

export default PostBody;
