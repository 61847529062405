import { Card, Icon, P1, P2, Stack, useBreakpoint, useConfig, useDarkMode, VStack } from "@mailbrew/uikit";
import ExternalLink from "components/ExternalLink";
import api from "dependencies/api";
import useHandleShareUrl from "hooks/useHandleShareUrl";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Tweet } from "react-static-tweets";
// import { Tweet } from "react-twitter-widgets";
import useSWR from "swr";
import domainFromURL from "utils/domainFromURL";
import fetchNewsletterShareLink from "utils/fetchNewsletterShareLink";
import { fromNow } from "utils/formatDate";
import { isTweetURL, isVideoURL } from "utils/readLaterUtils";
import trimText from "utils/trimText";
import InlineCardPreviewImage from "./InlineCardPreviewImage";
import ItemActionButtons from "./ItemActionButtons";
import { focusElementAtIndex } from "./KeyboardShortcutsManager";
import { NewsletterSender } from "./SenderCard";
import SmallSecondaryButton from "./SmallSecondaryButton";

const SavedItem = ({ item, index, isArchived, onArchiveClick, onDeleteClick, onReaderModeClick }) => {
  const config = useConfig();

  const breakpointHit = useBreakpoint(640);

  const domain = domainFromURL(item.url);

  const [expanded, setExpanded] = useState(false);

  const [isTweet, tweetID] = isTweetURL(item.url);
  const isVideo = isVideoURL(item.url);
  const isNewsletter = !!item?.url?.match(inboxMessageRegex);
  const imageUrl = item?.image_url?.match(/\.(jpeg|jpg|gif|png)$/) && item.image_url;

  const inboxMessage = useFetchInboxMessageFromPublicUrl(isNewsletter && item.url);

  const handleShare = useHandleShareUrl();

  const onShareClick = async () => {
    if (isNewsletter) {
      fetchNewsletterShareLink(inboxMessage.message_id, handleShare);
    } else {
      handleShare(item?.url);
    }
  };

  function handlePressEnter() {
    if (isVideo) {
      setExpanded(!expanded);
    } else {
      window.open(item.url, "_blank");
    }
    trackOpenLink();
  }

  function handlePressShiftEnter() {
    onReaderModeClick(item.url);
    trackOpenLink();
  }

  const trackOpenLink = useCallback(
    function trackOpenLink() {
      api.post(`read_later/${item.id}/open/`);
    },
    [item]
  );

  function handleReaderModeClick() {
    onReaderModeClick(isNewsletter, inboxMessage);
  }

  useEffect(() => {
    if (expanded) {
      trackOpenLink();
    }
  }, [expanded, trackOpenLink]);

  if (isNewsletter && inboxMessage)
    return (
      <Fragment>
        <Card
          inline
          tabIndex={index + 1}
          data-hotkey-index={index}
          onKeyUp={(e) => {
            if (!e.shiftKey && (e.key === "Enter" || e.key === "o")) {
              handlePressEnter();
            }
            if (e.shiftKey && (e.key === "Enter" || e.key === "o")) {
              handlePressShiftEnter();
            }
            if (e.key === "e") {
              onArchiveClick();
              focusElementAtIndex(index + 1);
            }
            if (e.key === "Backspace") {
              onDeleteClick();
              focusElementAtIndex(index + 1);
            }
          }}
          overflow="hidden"
          flex
          fd="column"
          ai="stretch"
          jc="space-between"
        >
          <InlineCardPreviewImage
            previewUrl={inboxMessage.content_url}
            onClick={() => onReaderModeClick(true, inboxMessage)}
            aspectRatio={0.5}
            previewSize={640}
          />
          <VStack mt={3} h="100%" gap={2} maxWidth="100%" overflow="hidden">
            <ExternalLink href={item.url} style={{ border: "none", width: "100%" }} onClick={trackOpenLink}>
              <P1
                fontSize="18px"
                lineHeight="1.3"
                bold
                color={config.colors.c1}
                w="100%"
                style={{ wordBreak: "break-word" }}
              >
                {trimText(inboxMessage.subject, breakpointHit ? 48 : 100)}
              </P1>
            </ExternalLink>
            <NewsletterSender
              name={inboxMessage.sender_name}
              email={inboxMessage.sender_email}
              date={inboxMessage.date}
              avatarUrl={inboxMessage.avatar_url}
            />
          </VStack>
          <Stack align="spaced" noWrap vAlign="bottom" mt={2}>
            <Stack align="left" noWrap>
              <SmallSecondaryButton noStretch icon="email" onClick={handleReaderModeClick}>
                Read
              </SmallSecondaryButton>
              <SmallSecondaryButton icon="shareAlt" onClick={onShareClick}>
                Share
              </SmallSecondaryButton>
            </Stack>
            <ItemActionButtons
              title={inboxMessage.subject}
              url={inboxMessage.read_url}
              isArchived={isArchived}
              withArchive
              onArchiveClick={onArchiveClick}
              onDeleteClick={onDeleteClick}
              withReadLater={false}
              withReaderMode={false}
              mt={2}
            />
          </Stack>
        </Card>
      </Fragment>
    );

  return (
    <Card
      inline
      tabIndex={index + 1}
      data-hotkey-index={index}
      onKeyUp={(e) => {
        if (!e.shiftKey && (e.key === "Enter" || e.key === "o")) {
          handlePressEnter();
        }
        if (e.shiftKey && (e.key === "Enter" || e.key === "o")) {
          handlePressShiftEnter();
        }
        if (e.key === "e") {
          onArchiveClick();
          focusElementAtIndex(index + 1);
        }
        if (e.key === "Backspace") {
          onDeleteClick();
          focusElementAtIndex(index + 1);
        }
      }}
      overflow="hidden"
      flex
      fd="column"
      ai="stretch"
      jc="space-between"
    >
      {isTweet && <ItemTweet id={tweetID} />}
      {!isTweet && (
        <Fragment>
          <ExternalLink href={item.url} style={{ border: "none", width: "100%" }} onClick={trackOpenLink}>
            <InlineCardPreviewImage imageUrl={imageUrl} previewUrl={item.url} aspectRatio={0.5} previewSize={640} />
          </ExternalLink>
          <VStack mt={3} h="100%" gap={1} maxWidth="100%" overflow="hidden">
            <ExternalLink href={item.url} style={{ border: "none", width: "100%" }} onClick={trackOpenLink}>
              <P1
                fontSize="18px"
                lineHeight="1.3"
                bold
                color={config.colors.c1}
                w="100%"
                style={{ wordBreak: "break-word" }}
              >
                {trimText(item.title, breakpointHit ? 48 : 100)}
              </P1>
            </ExternalLink>
            <P2 color={config.colors.c4} size="14px" noWrap maxWidth="100%" overflow="hidden">
              {isVideo && <Icon currentColor name="playBold" size="11px" offset="-1px" mr="4px" />}
              {domain}
              {" • "}
              {fromNow(item.date_created)}
            </P2>
            {/* {showDescription && !breakpointHit && (
              <P2 noWrap color={config.colors.c3}>
                {item.description}
              </P2>
            )} */}
          </VStack>
          {/* {showDescription && breakpointHit && (
            <P2 noWrap mt={1} color={config.colors.c3}>
              {item.description}
            </P2>
          )} */}
        </Fragment>
      )}
      <Stack align="spaced" noWrap vAlign="bottom" mt={2}>
        <Stack align="left" noWrap>
          {!isVideo && !isTweet && (
            <SmallSecondaryButton noStretch icon="text" onClick={handleReaderModeClick}>
              Read
            </SmallSecondaryButton>
          )}
          <SmallSecondaryButton icon="shareAlt" onClick={onShareClick}>
            Share
          </SmallSecondaryButton>
        </Stack>
        <ItemActionButtons
          title={item.title}
          url={item.url}
          isArchived={isArchived}
          withArchive
          onArchiveClick={onArchiveClick}
          onDeleteClick={onDeleteClick}
          withReadLater={false}
          withReaderMode={false}
        />
      </Stack>
    </Card>
  );
};

const inboxMessageRegex = /https?:\/\/(app|staging)\.mailbrew\.com\/inbox\/(.+)\/(.+)/;

function useFetchInboxMessageFromPublicUrl(url) {
  const match = url && typeof url === "string" && url.match(inboxMessageRegex);
  const messageId = match?.[2];
  const messageToken = match?.[3];

  const swrKey = `/inbox_source_messages/${messageId}/?token=${messageToken}`;
  const { data: inboxMessage } = useSWR(match && swrKey);

  return inboxMessage;
}

const ItemTweet = ({ id }) => {
  const [darkMode] = useDarkMode();

  return <Tweet id={id} options={{ align: "center", theme: darkMode ? "dark" : "light" }} />;
};

export default SavedItem;
