import { useBreakpoint, useConfig } from "@mailbrew/uikit";
import { usePaywallState } from "components/PaywallStateProvider";
import DropDownRow from "components/DropDownRow";
import SourceEditorCallout from "components/editor/SourceEditorCallout";

const LayoutOptions = (props) => {
  const { selected, onChange, disabled, ...rest } = props;
  const hits = useBreakpoint([723, 800, 1173]);

  const sidebarBreakpoints = hits[2];
  const mobileBreakpoints = hits[1];
  const layoutBreakpoint = hits[0];

  const config = useConfig();

  const desktopDisclaimerShown = !mobileBreakpoints && sidebarBreakpoints && selected !== 1;
  const mobileDisclaimerShown = layoutBreakpoint && sidebarBreakpoints && selected !== 1;

  const options = [1, 2, 3];
  const optionsNames = ["Full", "2 Columns", "3 Columns"];

  const { monetization, config: userConfig, setPaywallModalShown, setHighlightedFeature } = usePaywallState();
  const lockFeature = monetization?.status === "free" && userConfig?.freemium;
  function handleChange(option) {
    if (lockFeature) {
      setHighlightedFeature("layout_options");
      setPaywallModalShown(true);
    } else {
      onChange(option);
    }
  }

  return (
    <>
      <DropDownRow
        label="Layout"
        icon="layoutBold"
        selected={selected}
        onChange={handleChange}
        disabled={disabled}
        options={options}
        optionsNames={optionsNames}
        pb={desktopDisclaimerShown || mobileDisclaimerShown ? 0 : 1}
        {...rest}
      />
      {desktopDisclaimerShown && (
        <SourceEditorCallout
          color={config.colors.c1}
          icon="expand"
          title="About the preview"
          body="Make the window bigger to see a preview of the columns layout."
        />
      )}
      {mobileDisclaimerShown && (
        <SourceEditorCallout
          color={config.colors.c1}
          icon="expand"
          title="About the preview"
          body="You'll see the multiple columns layout on bigger screens."
        />
      )}
    </>
  );
};

export default LayoutOptions;
