import { useRouter } from "next/router";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import { didJustCompleteOnboardingSelector } from "reducers/onboardingReducer";
import useSWR from "swr";
import { isClient } from "../utils/env";
import PaywallDebugSection from "./PaywallDebugSection";

const PaywallStateContext = createContext(null);

export default function PaywallStateProvider({ children }) {
  const stateUser = useSelector(authUserSelector);
  const [user, setUser] = useState(stateUser);
  const [highlightedFeature, setHighlightedFeature] = useState(null);
  const [productId, setProductId] = useState(
    user?.monetization?.type === "stripe_sub" ? user?.monetization?.data?.product_id : null
  );
  const router = useRouter();
  const { coupon: queryParamCoupon } = router.query;

  const getCoupon = useCallback(() => queryParamCoupon ?? user?.profile?.coupon, [
    queryParamCoupon,
    user?.profile?.coupon,
  ]);
  const getTrialLength = () => 14;

  const [couponInUrlOrUser, setCouponInUrlOrUser] = useState(getCoupon);
  const [justUpgraded, setJustUpgraded] = useState(false);
  const [trialLength, setTrialLength] = useState(getTrialLength);
  const [inboxEmpty, setInboxEmpty] = useState();
  const [savedEmpty, setSavedEmpty] = useState();
  const [afterOnboarding, setAfterOnboarding] = useState(false);
  const [paywallModalShown, setPaywallModalShown] = useState(false);

  const didJustCompleteOnboarding = useSelector(didJustCompleteOnboardingSelector);
  useEffect(() => {
    setAfterOnboarding(didJustCompleteOnboarding);
  }, [didJustCompleteOnboarding]);

  const coupon = useCouponInfo(couponInUrlOrUser);
  const { data: products } = useSWR("/products/", { revalidateOnFocus: false });

  const showDebugUI = isClient && !!localStorage.getItem("mb:show_debug_ui");
  const debuggingUI = showDebugUI ? <PaywallDebugSection /> : null;

  useEffect(() => {
    setUser(stateUser);
  }, [stateUser]);

  useEffect(() => {
    setCouponInUrlOrUser(queryParamCoupon ?? user?.profile?.coupon);
  }, [queryParamCoupon, user?.profile?.coupon]);

  // reset a few things when navigating between pages
  useEffect(() => {
    setCouponInUrlOrUser(getCoupon);
    setTrialLength(getTrialLength);
  }, [getCoupon, router.asPath]);

  const contextValue = {
    monetization: user?.monetization,
    config: user?.config,
    products,
    user,
    setUser,
    highlightedFeature,
    setHighlightedFeature,
    productId,
    setProductId,
    debuggingUI,
    couponInUrlOrUser,
    setCouponInUrlOrUser,
    coupon,
    justUpgraded,
    setJustUpgraded,
    inboxEmpty,
    setInboxEmpty,
    savedEmpty,
    setSavedEmpty,
    afterOnboarding,
    setAfterOnboarding,
    paywallModalShown,
    setPaywallModalShown,
    trialLength,
    setTrialLength,
  };

  return <PaywallStateContext.Provider value={contextValue}>{children}</PaywallStateContext.Provider>;
}

export function useCouponInfo(couponId) {
  const { data: couponInfo } = useSWR(couponId && couponId.length > 0 && `/coupon_info/?id=${couponId}`);
  return couponInfo;
}

export function usePaywallState() {
  return useContext(PaywallStateContext) ?? {};
}
