import DropDownRow from "components/DropDownRow";

const ImagesStyleSetting = (props) => {
  const { label = "Images", style = "full", allowWideThumbnails, onChange, disabled, ...rest } = props;

  const layouts = allowWideThumbnails ? ["hidden", "thumb", "wide_thumb", "full"] : ["hidden", "thumb", "full"];
  const layoutsNames = allowWideThumbnails
    ? ["No images", "Thumbnails", "Wide Thumbnail", "Full Images"]
    : ["No images", "Thumbnails", "Full Images"];

  return (
    <DropDownRow
      label={label}
      icon="pictureBold"
      selected={style}
      onChange={onChange}
      disabled={disabled}
      options={layouts}
      optionsNames={layoutsNames}
      {...rest}
    />
  );
};

export default ImagesStyleSetting;
