import { Box, H2, Modal, P1, P2, Spinner, Stack, useBreakpoint, useConfig } from "@mailbrew/uikit";
import axios from "axios";
import StyledA from "components/StyledA";
import { AnimatePresence, motion } from "framer-motion";
import useIsAtTopOrBottomOfScroll from "hooks/useIsAtTopOrBottomOfScroll";
import useKeyPressed from "hooks/useKeyPressed";
import Head from "next/head";
import { useEffect, useMemo, useRef, useState } from "react";
import plausible from "utils/plausible";
import trimText from "utils/trimText";
import zIndexes from "zIndexes";
import ReaderModeIFrame from "./ReaderModeIFrame";
import {
  ModalToolbar,
  ToolbarArchiveButton,
  ToolbarCloseButton,
  ToolbarReadLaterButton,
  ToolbarShareButton,
  ToolbarTweetButton,
} from "./Toolbars";

export const ReaderViewModal = ({ url, show, setShow, hideReadLater, withArchive, onArchiveClick, source }) => {
  useKeyPressed("Escape", () => setShow(false));

  const {
    success,
    articleLoading,
    articleError,
    title,
    author,
    html,
    mainImage: mainImageUrl,
    axiosCancelSourceRef,
  } = useFetchArticle(url, () => {
    plausible.track("Open Reader Mode", { url, source });
  });

  const handleSetShow = (show) => {
    // reset stuff when closing
    if (!show) {
      // Toolbar need to be hidden before the entire modal is closed,
      // Otherwise its exit animation breaks
      setToolbarShown(false);
      if (axiosCancelSourceRef.current) {
        axiosCancelSourceRef.current.cancel();
        axiosCancelSourceRef.current = null;
      }
    }
    setTimeout(() => {
      setShow(show);
    });
  };

  const [toolbarShown, setToolbarShown] = useState(show);
  useEffect(() => {
    if (show) {
      setToolbarShown(show);
    }
  }, [show]);

  // Extract article parts

  // Process image if found
  const mainImage = makeUrlSafe(mainImageUrl);
  const { imageSize, imageProcessed } = useImageSize(mainImage);
  const showMainImage = useMemo(() => !!(mainImage && imageSize.width > 600), [mainImage, imageSize.width]);

  // Show article in Reader Mode after all fetching and processing is done
  // This way there are no glitches due to loading
  const loaded = !articleLoading && typeof html !== undefined && imageProcessed;
  const error = success === false || articleError || (loaded && !html);

  const modalRef = useRef(null);
  const [toolbarVisible, setToolbarVisible] = useIsAtTopOrBottomOfScroll({
    scrollElement: modalRef.current,
    offset: 50,
  });

  useEffect(() => {
    setToolbarVisible(show);
  }, [setToolbarVisible, show]);

  if (error) {
    return (
      <Modal
        fullHeight
        bottomSheetWidth="960px"
        bottomSheet
        show={show}
        setShow={handleSetShow}
        flex
        fd="column"
        zIndex={zIndexes.readerViewModal}
      >
        <Box style={{ flex: 1 }} flex fd="column" ai="center" jc="center">
          <P1 mb={3}>Sorry, we couldn't fetch the article 😔</P1>
          <StyledA icon="open" variant="white" href={url} targetBlank>
            Open Link
          </StyledA>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      fullHeight
      bottomSheetWidth="960px"
      bottomSheet
      show={show}
      setShow={handleSetShow}
      style={{ flex: "1 0 auto" }}
      zIndex={zIndexes.readerViewModal}
      ref={modalRef}
    >
      {title && (
        <Head>
          <title>{title}</title>
        </Head>
      )}
      <LoadingElement show={!loaded} />
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          opacity: loaded ? 1 : 0,
          transition: loaded ? "0.2s 0.2s" : "none",
        }}
      >
        {showMainImage && (
          <CoverImage url={mainImage}>
            <H2
              as="a"
              href={url}
              target="_blank"
              display="block"
              rel="noopener noreferrer"
              color="white"
              weight="500"
              w="100%"
              overflow="hidden"
              style={{
                textOverflow: "ellipsis",
              }}
            >
              {trimText(title, 80)}
            </H2>
            {author && (
              <P1 color="rgba(255,255,255.8)" mt={2} noWrap maxW="100%">
                {author}
              </P1>
            )}
          </CoverImage>
        )}
        <Stack vertical w="660px" maxW="100%" mx="auto" style={{ flex: 1 }}>
          {!showMainImage && (
            <Box mt={4} mb={4} maxW="100%" overflow="hidden">
              <H2 as="a" href={url} target="_blank" rel="noopener noreferrer" weight="500">
                {trimText(title, 100)}
              </H2>
              {author && (
                <P2 mt={2} noWrap>
                  By {author}
                </P2>
              )}
            </Box>
          )}
          <ReaderModeIFrame baseURL={url} contentHTML={html} />
          <ModalToolbar mt={8} show={toolbarVisible && toolbarShown && loaded}>
            <ToolbarShareButton url={url} />
            <ToolbarTweetButton url={url} title={`${title} (via @mailbrew)`} />
            {!hideReadLater && <ToolbarReadLaterButton url={url} />}
            {withArchive && onArchiveClick && (
              <ToolbarArchiveButton
                onClick={() => {
                  onArchiveClick();
                  setShow(false);
                }}
              />
            )}
            <ToolbarCloseButton onClick={() => handleSetShow(false)} />
          </ModalToolbar>
        </Stack>
      </div>
    </Modal>
  );
};

function makeUrlSafe(url) {
  if (url && typeof url === "string") {
    return url.replace("http://", "https://");
  } else {
    return url;
  }
}

const LoadingElement = ({ show }) => {
  const config = useConfig();
  return (
    <AnimatePresence>
      {show && (
        <Box
          as={motion.div}
          initial={false}
          animate={show ? { scale: 1, opacity: 1 } : { scale: 0.85, opacity: 0 }}
          transition={{ type: "spring", duration: 0.3, bounce: 0.3 }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: "100vh",
            flex: 1,
          }}
          flex
          fd="column"
          ai="center"
          jc="center"
        >
          <Spinner />
          <P2 mt={3} color={config.colors.c4}>
            Loading article...
          </P2>
        </Box>
      )}
    </AnimatePresence>
  );
};

const CoverImage = ({ url, children }) => {
  const config = useConfig();
  const isMobile = useBreakpoint();
  return (
    <Box
      style={{
        background: `black url(${url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginLeft: "-1.4rem",
        marginRight: "-1.4rem",
        marginTop: "-1.4rem",
        borderTopLeftRadius: config.Card.bigRadius,
        borderTopRightRadius: config.Card.bigRadius,
        flex: "0 0 auto",
        overflow: "hidden",
      }}
      h={isMobile ? "240px" : "360px"}
      mb={6}
      position="relative"
      flex
      fd="column"
      ai="flex-start"
      jc="flex-end"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        bottom={0}
        right={0}
        style={{
          borderTopLeftRadius: config.Card.bigRadius,
          borderTopRightRadius: config.Card.bigRadius,
        }}
        bg="linear-gradient(rgba(0,0,0,.2) 0%, rgba(0,0,0,.3) 40%, rgba(0,0,0,.7) 90%)"
      />
      <Box padding={config.layout.padding} w="690px" maxWidth="100%" mx="auto" zIndex="1" overflow="hidden">
        {children}
      </Box>
    </Box>
  );
};

const useImageSize = (url) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [imageProcessed, setImageProcessed] = useState(false);
  useEffect(() => {
    setImageProcessed(false);
    if (url) {
      if (!url.includes("https://")) {
        setImageProcessed(true);
        return;
      }
      const urlFromString = new URL(url);
      const cleanUrl = urlFromString?.origin + urlFromString?.pathname;
      if (cleanUrl && cleanUrl.match(/\.(jpeg|jpg|gif|png)$/)) {
        let img = new Image();
        img.src = cleanUrl;
        img.onload = () => {
          setImageSize({ width: img.width, height: img.height });
          setImageProcessed(true);
        };
        let timeout = setTimeout(() => {
          setImageProcessed(true);
          return;
        }, 4000);
        return () => clearTimeout(timeout);
      } else {
        setImageProcessed(true);
      }
    } else {
      setImageProcessed(true);
    }
  }, [url]);
  return { imageSize, imageProcessed };
};

function useFetchArticle(url, onStart, onSuccess) {
  const [articleLoading, setArticleLoading] = useState(true);
  const [articleError, setArticleError] = useState(false);
  const [articleRes, setArticleData] = useState({});
  const axiosCancelSourceRef = useRef(null);

  // Fetch Article
  useEffect(() => {
    if (url) {
      onStart && onStart();
      setArticleLoading(true);
      setArticleError(false);
      setArticleData({});

      if (axiosCancelSourceRef.current) {
        // cancel request before making another one
        axiosCancelSourceRef.current.cancel();
      } else {
        axiosCancelSourceRef.current = axios.CancelToken.source();
      }

      axios
        .get(`https://mailbrew-functions.vercel.app/article-extraction/article`, {
          params: { url },
          cancelToken: axiosCancelSourceRef.current.token,
        })
        .then((res) => {
          setArticleData(res.data);
          setArticleLoading(false);
          onSuccess && onSuccess();
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            // eslint-disable-next-line no-console
            console.log("ignoring axios cancellation error");
            return;
          }

          setArticleLoading(false);
          setArticleError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  // Extract article parts
  const { success, data: articleData } = articleRes;
  const { title, author, html, mainImage } = articleData || {};

  return { success, articleLoading, articleError, title, author, html, mainImage, axiosCancelSourceRef };
}

// const Summary = ({ summary }) => {
//   const config = useConfig();
//   const [expanded, setExpanded] = useState(false);
//   return (
//     <Box w="100%" mb={4}>
//       <Button
//         variant="secondary"
//         color={config.colors.c2}
//         w="100%"
//         icon="lightning"
//         onClick={() => setExpanded(!expanded)}
//       >
//         {expanded ? "Hide Summary" : "Show Summary"}
//       </Button>
//       <Expandable expanded={expanded}>
//         <Spacer size={5} />
//         <P1 color={config.colors.c3}>{summary}</P1>
//         <Divider mb={0} mt={6} width="full" />
//       </Expandable>
//     </Box>
//   );
// };
