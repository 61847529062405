/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { HStack, Icon, P1, Stack, useConfig, useDarkMode } from "@mailbrew/uikit";
import { ProBadge } from "components/Paywall";
import { sourcesData } from "data/sourcesData";
import useIsSourceLocked from "hooks/useIsSourceLocked";
import useShowSourceProBadge from "hooks/useShowSourceProBadge";
import tinycolor from "tinycolor2";
import { getSourceTitle } from "utils/sources";

const SourceCard = (props) => {
  const {
    sourceType,
    sourceNameOverride,
    suggestionMode,
    source,
    onClick,
    onRemoveClick,
    provided,
    isBeyondLimit,
  } = props;

  const { style: draggableStyle, ...otherDraggableProps } = provided?.draggableProps ?? {};

  const config = useConfig();
  const [darkMode] = useDarkMode();
  const showProBadge = useShowSourceProBadge(sourceType);
  const isLocked = useIsSourceLocked(sourceType);

  const sourceData = sourcesData[sourceType];

  if (!sourceData) return null;

  let { glyph: SourceGlyph } = sourceData;

  const sourceName = getSourceTitle(source);

  let background = darkMode ? tinycolor(sourceData.color).setAlpha(0.8).toString() : sourceData.color;
  background = isBeyondLimit ? tinycolor(background).darken(5).setAlpha(0.65).toString() : background;

  const shadow = darkMode ? "none" : `0 0.2em 0.25em 0.05em ${tinycolor(sourceData.color).setAlpha(0.15).toString()}`;

  return (
    <Stack
      gap={0}
      noWrap
      ref={provided?.innerRef}
      onClick={() => onClick(isLocked)}
      {...otherDraggableProps}
      style={{
        ...cardWrapperStyle(background, shadow, config),
        ...draggableStyle,
      }}
    >
      <HStack gap={0} noWrap w="100%" overflow="hidden">
        <SourceGlyph
          css={css`
            width: 1.8em;
            margin-right: 0.4em;
            flex: 0 0 1.8em;
          `}
        />
        <P1
          style={{ fontSize: "16.5px", letterSpacing: "0.3px", fontWeight: 500 }}
          color="white"
          noWrap
          overflow="hidden"
        >
          {sourceNameOverride ?? sourceName}
        </P1>
      </HStack>

      <Stack style={{ marginLeft: "0.8em" }} gap={0} noWrap>
        {showProBadge && <ProBadge invert mr={2.5} zoom={0.9} />}
        {!suggestionMode && (
          <Stack gap={0} noWrap>
            <LittleIcon
              name="trashBold"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveClick();
              }}
            />
            <DragHandle {...provided?.dragHandleProps} />
          </Stack>
        )}
      </Stack>
      {suggestionMode && (
        <Stack gap={0} noWrap>
          <LittleIcon
            mr={1.5}
            name="plusBold"
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

const LittleIcon = (props) => {
  return <Icon mr={2.5} size="17.5px" color="rgba(255,255,255,0.85)" hoverColor="white" {...props} />;
};

export const DragHandle = ({ color = "white", ...rest }) => {
  return (
    <div style={dragHandleWrapperStyle} {...rest}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.8" x="2" y="5" width="16" height="2" rx="1" fill={color} />
        <rect opacity="0.8" x="2" y="9" width="16" height="2" rx="1" fill={color} />
        <rect opacity="0.8" x="2" y="13" width="16" height="2" rx="1" fill={color} />
      </svg>
    </div>
  );
};

const dragHandleWrapperStyle = {
  marginRight: "4px",
  height: "100%",
  display: "grid",
  alignItems: "center",
  opacity: "0.8",
  cursor: "move",
};

const cardWrapperStyle = (background, shadow, config) => ({
  background: background,
  boxShadow: shadow,
  borderRadius: config.Button.radius,
  marginBottom: "4px",
  userSelect: "none",
  padding: "0px 7px",
  alignItems: "center",
  position: "relative",
  zIndex: "1",
  cursor: "pointer",
});

export default SourceCard;
