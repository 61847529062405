import { Input } from "@mailbrew/uikit";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import * as Yup from "yup";
import api from "dependencies/api";
import useInputValidator from "hooks/useInputValidator";
import useRandomItem from "hooks/useRandomItem";
import { updateSourceField } from "reducers/newslettersReducer";
import urlRegex from "utils/urlRegex";
import EditorSubpage from "components/editor/EditorSubpage";
import SourceEditorSection from "components/editor/SourceEditorSection";
import LayoutOptions from "components/editor/LayoutOptions";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";

const YouTubeChannelSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();

  const [channelURL, setChannelURL, channelURLError] = useInputValidator(
    source.channel_url,
    Yup.string().matches(urlRegex, "Please enter a valid URL"),
    (url) => updateChannelURL(sourceIndex, url)
  );

  const [updateChannelURL, channelURLLoading, channelURLApiError] = useUpdateChannelURL();

  const feedURLPlaceholder = useRandomItem(placeholders);

  const handleChannelURLFieldSubmit = () => {
    channelURL?.length && updateChannelURL(sourceIndex, channelURL, dispatch);
  };

  return (
    <EditorSubpage title={source.title || "YouTube Channel"} type={source.type} sourceIndex={sourceIndex}>
      {/* CHANNEL URL */}
      <SourceEditorSection
        title="YOUTUBE CHANNEL URL"
        subtitle="Enter the URL of the YouTube Channel, we will figure out the rest."
        icon="link"
        noBorderTop
      >
        <Input
          value={channelURL}
          onChange={(e) => setChannelURL(e.target.value)}
          onSubmit={handleChannelURLFieldSubmit}
          loading={channelURLLoading}
          type="text"
          name="url"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          placeholder={feedURLPlaceholder}
          error={channelURLApiError || channelURLError}
          autoFocus={!(source?.title?.length > 0)}
        />
      </SourceEditorSection>

      {/* TITLE */}
      <SourceEditorSection
        title="Title"
        subtitle="Automatically updated when you update the url, editable afterwards."
        icon="writeBold"
      >
        <Input
          name="title"
          placeholder="Title"
          type="text"
          autoComplete="off"
          value={source.title}
          onChange={(e) => dispatch(updateSourceField(sourceIndex, "title", e.target.value))}
        />
      </SourceEditorSection>

      {/* NUMBER OF POSTS */}
      <NumPostsSourceEditor
        title="Included Videos"
        source={source}
        sourceIndex={sourceIndex}
        fieldName="max_videos"
        min={1}
        max={20}
        shortExplanationBuilder={(num) =>
          num === 1
            ? "We'll send you the latest video from the channel."
            : `We'll send you the ${num} latest videos from the channel.`
        }
      />
      <SourceEditorSection>
        <LayoutOptions
          selected={source.columns || 1}
          onChange={(option) => dispatch(updateSourceField(sourceIndex, "columns", option))}
        />
      </SourceEditorSection>
    </EditorSubpage>
  );
};

const placeholders = [
  "https://www.youtube.com/user/marquesbrownlee",
  "https://www.youtube.com/user/TheVerge",
  "https://www.youtube.com/user/Nerdwriter1",
  "https://www.youtube.com/user/CGPGrey",
];

function useUpdateChannelURL() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [debouncedCallback] = useDebouncedCallback((sourceIndex, url) => {
    setLoading(true);
    setError(null);

    api
      .get("youtube_channel_info_from_url/", { params: { url } })
      .then((res) => {
        dispatch(updateSourceField(sourceIndex, "title", res.data.title));
        // prettier-ignore
        dispatch(updateSourceField(sourceIndex, "channel_id", res.data.channel_id));
        dispatch(updateSourceField(sourceIndex, "channel_url", url));
      })
      .catch((err) => setError(err.response?.data?.detail || "Can't get channel"))
      .finally(() => setLoading(false));
  }, 500);

  return [debouncedCallback, loading, error];
}

export default YouTubeChannelSourceEditor;
