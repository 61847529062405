import { P2, Select } from "@mailbrew/uikit";
import { useDispatch } from "react-redux";
import useSWR from "swr";
import ExternalLink from "components/ExternalLink";
import { updateSourceField } from "reducers/newslettersReducer";
import EditorSubpage from "components/editor/EditorSubpage";
import SourceEditorCallout from "components/editor/SourceEditorCallout";
import SourceEditorSection from "components/editor/SourceEditorSection";

const CovidSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();
  const { data: countries, error: countriesApiError } = useSWR("/covid_countries/");

  return (
    <EditorSubpage type={source.type} sourceIndex={sourceIndex}>
      <SourceEditorSection title="Country" icon="worldAltBold" noBorderTop>
        {countries ? (
          <Select
            selectedOption={source.country}
            onSelect={(c) => {
              dispatch(updateSourceField(sourceIndex, "country", c));
              dispatch(updateSourceField(sourceIndex, "country_name", countries[c]));
            }}
            options={Object.keys(countries)}
            optionNames={Object.values(countries)}
            error={countriesApiError}
          />
        ) : (
          <Select placeholder="Loading Countries..." options={[]} error={countriesApiError} />
        )}
        <SourceEditorCallout
          icon="growth"
          title="About the diffs"
          body="The diffs are based on the previous issue of your brew, or the latest data available as of yesterday."
        />
        <P2>
          Data from <ExternalLink href="https://coronavirus.jhu.edu/map.html">Johns Hopkins University</ExternalLink>,
          via this <ExternalLink href="https://github.com/mathdroid/covid-19-api">public API</ExternalLink>.
        </P2>
      </SourceEditorSection>
    </EditorSubpage>
  );
};

export default CovidSourceEditor;
