import {
  Box,
  Button,
  Card,
  Grid,
  Icon,
  IconBadge,
  P2,
  SmallText,
  Stack,
  Tooltip,
  useConfig,
  VStack,
} from "@mailbrew/uikit";
import api from "dependencies/api";
import Link from "next/link";
import { Fragment, useState } from "react";
import ReactTags from "react-tag-autocomplete";
import useSWR, { mutate } from "swr";
import apiErrMsg from "utils/apiErrMsg";
import { remove } from "utils/array";
import { fromNow } from "utils/formatDate";
import notifApiError from "utils/notifApiError";
import { keySenderOptionById, keyUserInboxTaBs, keyUserInboxTaGs } from "utils/swrKeys";
import InboxMessageModal from "./InboxMessageModal";
import InlineCardPreviewImage from "./InlineCardPreviewImage";
import StyledLink from "./StyledLink";
import UnstyledA from "./UnstyledA";

const SenderCard = ({
  senderOption,
  screenOutMode,
  onSetFav,
  onScreenOutUpdate,
  justChildren,
  showPreview = true,
  emailInsteadOfTime = true,
}) => {
  const [showModal, setShowModal] = useState(false);

  const lastMessage = senderOption?.last_message;

  const { sender } = senderOption;

  return (
    <Fragment>
      <Card inline key={senderOption.id} flex fd="column" ai="stretch" jc="space-between" justChildren={justChildren}>
        <VStack>
          {showPreview && (
            <InlineNewsletterPreview sender={sender} message={lastMessage} onClick={() => setShowModal(true)} />
          )}
          <Stack w="100%" align="spaced" noWrap overflow="hidden" mb={1}>
            {/* PLaceholder when you have no image */}
            {senderOption.sender && (
              <NewsletterSender
                name={sender?.display_name}
                email={sender.email}
                date={lastMessage?.date}
                avatarUrl={sender.avatar_url}
                medianReadProgress={senderOption.median_read_progress}
                emailInsteadOfTime={emailInsteadOfTime}
              />
            )}
            {!screenOutMode && (
              <FavoriteButton
                favorited={senderOption.favorited}
                toggleFav={() => onSetFav && onSetFav({ ...senderOption, favorited: !senderOption.favorited })}
              />
            )}
          </Stack>
          {!screenOutMode && senderOption.tags && <TagEditor senderOption={senderOption} />}
        </VStack>
        <CardActions senderOption={senderOption} onScreenOutUpdate={onScreenOutUpdate} />
      </Card>
      {lastMessage && (
        <InboxMessageModal
          key={lastMessage.message_id}
          id={lastMessage.message_id}
          show={showModal}
          setShow={setShowModal}
        />
      )}
    </Fragment>
  );
};

const InlineNewsletterPreview = ({ sender, message, onClick }) => {
  const config = useConfig();
  return (
    <InlineCardPreviewImage
      previewUrl={message?.content_url}
      previewSize={720}
      mb={1}
      onClick={onClick}
      style={{
        borderRadius: `${config.Card.smallRadius} ${config.Card.smallRadius} 0 0`,
        backgroundPosition: "top center",
      }}
    >
      {!message && (
        <img
          src={sender?.avatar_url}
          alt={sender?.display_name}
          style={{
            width: "200px",
            height: "200px",
            borderRadius: "200px",
            filter: "saturate(0)",
            opacity: 0.15,
          }}
        />
      )}
    </InlineCardPreviewImage>
  );
};

const CardActions = ({ senderOption, onScreenOutUpdate }) => {
  const config = useConfig();
  return (
    <Grid
      borderTop={`1px solid ${config.colors.uiBorderColor}`}
      w={`calc( 100% + ${config.Card.smallPadding} + ${config.Card.smallPadding} )`}
      ml={"-" + config.Card.smallPadding}
      mb={"-" + config.Card.smallPadding}
      mt={3}
      vAlign="center"
      columns="1fr 1fr"
      gap={0}
    >
      <Button
        variant="link"
        color={config.colors.c3}
        hoverColor={config.colors.c2}
        stretch
        w="100%"
        height="44px"
        style={{ zoom: 0.9, borderRight: `1px solid ${config.colors.uiBorderColor}` }}
        icon={senderOption.screen_out ? "likeBold" : "dislikeBold"}
        onClick={() =>
          onScreenOutUpdate && onScreenOutUpdate({ ...senderOption, screen_out: !senderOption.screen_out })
        }
      >
        {senderOption.screen_out ? "Unblock" : "Block"}
      </Button>
      <StyledLink
        color={config.colors.c3}
        hoverColor={config.colors.c2}
        stretch
        w="100%"
        height="44px"
        style={{ zoom: 0.9 }}
        variant="link"
        icon="text"
        to={`/inbox/?q=from:${senderOption.sender.email}`}
      >
        Read
      </StyledLink>
    </Grid>
  );
};

const TagEditor = ({ senderOption: providedSenderOption }) => {
  const { data: userTags, mutate: mutateTags } = useSWR(keyUserInboxTaGs);
  const [senderOption, setSenderOption] = useState(providedSenderOption);

  const tagToReactTag = (tag) => ({ ...tag, name: tag.title });

  const appliedTags = userTags
    ? senderOption.tags
        .map((tagId) => userTags.find((tag) => tag.id === tagId))
        .filter((t) => t)
        .map(tagToReactTag)
    : [];

  // const appliedTags = userTags?.filter((tag) => senderOption.tags.includes(tag.id)).map(tagToReactTag);
  const suggestions = userTags
    ? userTags
        .filter((tag) => !senderOption.tags.includes(tag.id))
        .filter((t) => t)
        .map(tagToReactTag)
    : [];

  async function handleAddTag(tag) {
    const tagTitle = tag.name;
    const existingTag = userTags.find((tag) => tag.title.toLowerCase() === tagTitle.toLowerCase());

    async function createTag(title) {
      try {
        const res = await api.post(keyUserInboxTaGs, { title });
        const tag = res.data;
        mutateTags((cache) => [...cache, tag], false);
        return tag;
      } catch (err) {
        apiErrMsg(err, `Can't create tag`);
        return null;
      }
    }

    async function attachTag(tag, senderOption) {
      const updatedSenderOption = {
        ...senderOption,
        tags: [...senderOption.tags, tag.id],
      };

      setSenderOption(updatedSenderOption);
      mutate(keySenderOptionById(senderOption.id), updatedSenderOption, false);

      try {
        await api.patch(keySenderOptionById(senderOption.id), { tags: updatedSenderOption.tags });
      } catch (err) {
        notifApiError(err, "Can't add this tag");
        return;
      }
    }

    if (existingTag) {
      attachTag(existingTag, senderOption);
    } else {
      const tag = await createTag(tagTitle);
      if (tag) {
        await attachTag(tag, senderOption);
      }
      mutate(keyUserInboxTaGs);
      mutate(keyUserInboxTaBs);
    }
  }

  async function handleRemoveTag(tagIndex) {
    const tag = appliedTags[tagIndex];
    const existingTag = userTags.find((t) => t.id === tag?.id);

    // could happen when quickly adding and removing a tag before we get a chance ot push it to the backend
    if (!existingTag) return;

    const updatedSenderOption = {
      ...senderOption,
      tags: remove(senderOption.tags, (tagId) => tagId === existingTag.id),
    };

    try {
      await api.patch(keySenderOptionById(senderOption.id), { tags: updatedSenderOption.tags });
    } catch (err) {
      notifApiError(err, "Error removing the tag.");
      return;
    }

    setSenderOption(updatedSenderOption);
    mutate(keySenderOptionById(updatedSenderOption.id), updatedSenderOption, false);
  }

  const hasTags = appliedTags.length > 0;

  return (
    <Box w="100%">
      <ReactTags
        placeholderText={hasTags ? "Add tag..." : "Tag this sender..."}
        tags={appliedTags}
        suggestions={suggestions}
        onAddition={handleAddTag}
        onDelete={handleRemoveTag}
        allowNew
        newTagText="Add tag"
      />
    </Box>
  );
};

export const NewsletterSender = ({
  name,
  email,
  date,
  avatarUrl: providedAvatarUrl,
  medianReadProgress,
  style,
  emailInsteadOfTime,
}) => {
  const config = useConfig();

  const lastMessageDate = date && fromNow(date);

  const avatarUrl = providedAvatarUrl.includes("mailbrew.com") ? "/icon/apple-icon-152x152.png" : providedAvatarUrl;

  return (
    <Stack gap={2} noWrap maxW="auto" style={{ flex: "1 1 auto", minWidth: "0px", flex: "0 1 auto", ...style }}>
      <Link href={`/inbox/?q=from:${email}`}>
        <UnstyledA>
          <Box
            alt={name}
            style={{
              flex: "0 0 42px",
              width: "42px",
              height: "42px",
              borderRadius: "42px",
              boxShadow: "inset 0px 0px 1px rgba(0,0,0,0.3)",
              background: avatarUrl ? `white url(${avatarUrl})` : config.colors.c5,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </UnstyledA>
      </Link>
      <Stack vertical gap={0} overflow="hidden">
        {emailInsteadOfTime ? (
          <P2 lineHeight="1.4" weight="600" color={config.colors.c2} noWrap overflow="hidden">
            {name || email.split("@")[0]}
          </P2>
        ) : (
          <Tooltip description={email} style={{ overflow: "hidden", maxWidth: "100%" }}>
            <P2 lineHeight="1.4" weight="600" color={config.colors.c2} noWrap overflow="hidden">
              {name || email.split("@")[0]}
            </P2>
          </Tooltip>
        )}
        {/* <SmallText fontSize="13px" lineHeight="1.4" color={config.colors.c4} noWrap overflow="hidden">
              {email}
            </SmallText> */}
        <SmallText
          lineHeight="1.4"
          color={config.colors.c4}
          noWrap
          overflow="hidden"
          style={{ userSelect: "none", display: "flex", alignItems: "center" }}
        >
          <span
            style={{
              display: "inline-block",
              flex: "0 3 auto",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {emailInsteadOfTime ? email : lastMessageDate}
          </span>
          <span>
            <NewsetterRank progress={medianReadProgress} />
          </span>
        </SmallText>
      </Stack>
    </Stack>
  );
};

const FavoriteButton = ({ favorited, toggleFav }) => {
  const config = useConfig();
  return (
    <Tooltip
      title={favorited ? "Remove from Favorites" : "Add to Favorites"}
      description={!favorited && "You can filter by favorite senders in your brews."}
      delay={500}
      maxWidth="220px"
    >
      <Icon
        color={favorited ? "#ffa730" : config.colors.c5}
        hoverColor={favorited ? "#ffa730" : config.colors.c4}
        size="20px"
        strokeWidth="2px"
        align="right"
        cursor="pointer"
        name={favorited ? "starBold" : "star"}
        onClick={() => toggleFav()}
      />
    </Tooltip>
  );
};

const NewsetterRank = ({ progress }) => {
  const config = useConfig();

  if (!progress) return null;

  const icon = progress > 0.9 ? "lightningAlt" : progress > 0.3 ? "likeBold" : "dislikeBold";
  const color = progress > 0.3 ? config.colors.c4 : "hsl(0deg 100% 69% / 70%)";

  return (
    <Tooltip maxWidth="148px" title="Sender rank" description={`1 = you never read it\n10 = you always read it`}>
      <span style={{ paddingLeft: "3px" }}>
        ∙{" "}
        <span style={{ color }}>
          <Icon name={icon} currentColor size="12px" offset="-1px" /> {Math.ceil(progress * 10)}
        </span>
      </span>
    </Tooltip>
  );
};

const ReadProgressIndicator = ({ progress }) => {
  const config = useConfig();

  const ProgressIcon = ({ title, description, children, ...props }) => {
    return (
      <Tooltip maxWidth="285px" title={title} description={description} interactive={!!children} content={children}>
        <IconBadge size="30px" iconSize="16px" {...props} />
      </Tooltip>
    );
  };

  if (progress > 0.9) {
    return (
      <ProgressIcon
        name="lightningAlt"
        color="orange"
        title="Best of the best"
        description="You read every issue of this newsletters."
      >
        <Button mt={2} icon="starBold" variant={["white", "small"]}>
          Make it favorite
        </Button>
      </ProgressIcon>
    );
  }

  if (progress > 0.8) {
    return (
      <ProgressIcon
        name="likeBold"
        color={config.colors.success}
        title="You love this"
        description="You read almost every issue of this newsletters."
      />
    );
  }

  if (progress > 0.5) {
    return (
      <ProgressIcon
        name="questionAlt"
        color={config.colors.c3}
        title="Mixed feelings"
        description="You read some issues of this newsletter."
      />
    );
  }

  if (progress > 0.2) {
    return (
      <ProgressIcon
        name="arrowDown"
        color={config.colors.c3}
        title="Not good"
        description="You read very few issues of this newsletter."
      />
    );
  }

  return (
    <ProgressIcon
      name="flagBold"
      color={config.colors.error}
      title="Not reading this"
      description="Consider screening this newsletter out."
    >
      <Button mt={2} icon="dislikeBold" variant={["white", "small"]}>
        Screen out
      </Button>
    </ProgressIcon>
  );
};

export default SenderCard;
