import { CheckboxGroup, Input } from "@mailbrew/uikit";
import { connect } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import EditorSubpage from "components/editor/EditorSubpage";
import SourceEditorSection from "components/editor/SourceEditorSection";
import LayoutOptions from "components/editor/LayoutOptions";
import PostBody from "components/editor/PostBody";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";

const minPosts = 1;
const maxPosts = 30;

const HackerNewsSourceEditor = (props) => {
  const { source, sourceIndex, dispatch } = props;

  return (
    <EditorSubpage title={source.title || "Hacker News"} type={source.type} sourceIndex={sourceIndex}>
      {/* TITLE */}
      <SourceEditorSection title="Title" icon="writeBold" noBorderTop>
        <Input
          name="title"
          placeholder="Title"
          type="text"
          autoComplete="off"
          value={source.title}
          onChange={(e) => dispatch(updateSourceField(sourceIndex, "title", e.target.value))}
        />
      </SourceEditorSection>

      {/* INCLUDE */}
      <SourceEditorSection title="Include" icon="settingsAlt">
        <CheckboxGroup
          options={["story", "show_hn", "ask_hn"]}
          optionsNames={["Stories", "Show HN", "Ask HN"]}
          selectedOptions={source.include}
          onSelect={(selectedOptions) => {
            dispatch(updateSourceField(sourceIndex, "include", selectedOptions));
          }}
        />
      </SourceEditorSection>

      {/* NUMBER OF POSTS */}

      <NumPostsSourceEditor
        source={source}
        sourceIndex={sourceIndex}
        fieldName="num_posts"
        min={minPosts}
        max={maxPosts}
        shortExplanationBuilder={(num) =>
          num === 1 ? "We'll send you the most popular HN post." : `We'll send you the ${num} most popular HN posts.`
        }
      />

      <SourceEditorSection>
        <PostBody
          selected={source.post_body || "excerpt"}
          onChange={(option) => dispatch(updateSourceField(sourceIndex, "post_body", option))}
        />

        <LayoutOptions
          selected={source.columns || 1}
          onChange={(option) => dispatch(updateSourceField(sourceIndex, "columns", option))}
        />
      </SourceEditorSection>
    </EditorSubpage>
  );
};

export default connect((state) => ({
  newsletter: state.newsletters.currentNewsletter,
  loading: state.app.loading,
  errors: state.app.errors,
}))(HackerNewsSourceEditor);
