import { Input } from "@mailbrew/uikit";
import SourceEditorSection from "components/editor/SourceEditorSection";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentNewsletterSelector, updateSourceField } from "reducers/newslettersReducer";
import { useDebouncedCallback } from "use-debounce/lib";

const SourceTitleEditor = (props) => {
  const { title: providedTitle, onTitleChange } = props;
  const [title, setTitle] = useState(providedTitle);
  const [debouncedCallback] = useDebouncedCallback(onTitleChange, 500);

  // update title when providedTitle changes
  useEffect(() => setTitle(providedTitle), [providedTitle]);

  return (
    <Input
      name="title"
      placeholder="Title"
      type="text"
      autoComplete="off"
      value={title}
      onChange={(e) => {
        const newTitle = e.target.value;
        setTitle(newTitle);
        debouncedCallback(newTitle);
      }}
    />
  );
};

export function SourceTitleEditorSection({ sourceIndex, ...sectionProps }) {
  const dispatch = useDispatch();
  const newsletter = useSelector(currentNewsletterSelector);
  const source = newsletter?.sources[sourceIndex];

  if (!source) return null;

  return (
    <SourceEditorSection title="Title" icon="writeBold" {...sectionProps}>
      <SourceTitleEditor
        title={source.title}
        onTitleChange={(title) => {
          dispatch(updateSourceField(sourceIndex, "title", title));
        }}
      />
    </SourceEditorSection>
  );
}

export default SourceTitleEditor;
