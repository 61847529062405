/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button, H5, Icon, P1, P2, useConfig, useDetectBrowser, VStack } from "@mailbrew/uikit";
import CopyButton from "components/CopyButton";
import FadeInImage from "components/FadeInImage";
import StyledA from "components/StyledA";
import TitleDivider from "components/TitleDivider";
import chromeExtensionImage from "images/read-later/chrome-badge.png";
import readLaterPwaImage from "images/read-later/pwa.png";
import shortcutsImage from "images/read-later/shortcuts.png";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import { apiEnv } from "utils/env";
import ExternalLink from "./ExternalLink";

const DEV_BOOKMARKLET =
  // eslint-disable-next-line no-script-url
  "javascript:(function()%7B%22use%20strict%22%3Bvar%20BACKEND_URL%3D%22http%3A%2F%2Flocalhost%3A8000%22%2CUSER_TOKEN%3D%22__USER_TOKEN__%22%3Bfunction%20mbrl()%7Bvar%20t%3Ddocument%2Ce%3Dt.createElement(%22script%22)%2Ca%3Dt.body%2Cr%3Dt.location%3Btry%7Bif(!a)throw%200%3Be.setAttribute(%22src%22%2CBACKEND_URL%2B%22%2Fbookmarklet%2F%3Faction%3Dread-later%26url%3D%22%2BencodeURIComponent(r.href)%2B%22%26token%3D%22%2BUSER_TOKEN)%2Ca.appendChild(e)%7Dcatch(t)%7Balert(%22Please%20wait%20until%20the%20page%20has%20loaded.%22)%7D%7Dmbrl()%3B%7D)()";
const STAGING_BOOKMARKLET =
  // eslint-disable-next-line no-script-url
  "javascript:(function()%7B%22use%20strict%22%3Bvar%20BACKEND_URL%3D%22https%3A%2F%2Fstaging.api.mailbrew.com%22%2CUSER_TOKEN%3D%22__USER_TOKEN__%22%3Bfunction%20mbrl()%7Bvar%20t%3Ddocument%2Ce%3Dt.createElement(%22script%22)%2Ca%3Dt.body%2Cr%3Dt.location%3Btry%7Bif(!a)throw%200%3Be.setAttribute(%22src%22%2CBACKEND_URL%2B%22%2Fbookmarklet%2F%3Faction%3Dread-later%26url%3D%22%2BencodeURIComponent(r.href)%2B%22%26token%3D%22%2BUSER_TOKEN)%2Ca.appendChild(e)%7Dcatch(t)%7Balert(%22Please%20wait%20until%20the%20page%20has%20loaded.%22)%7D%7Dmbrl()%3B%7D)()";
const PROD_BOOKMARKLET =
  // eslint-disable-next-line no-script-url
  "javascript:(function()%7B%22use%20strict%22%3Bvar%20BACKEND_URL%3D%22https%3A%2F%2Fapi.mailbrew.com%22%2CUSER_TOKEN%3D%22__USER_TOKEN__%22%3Bfunction%20mbrl()%7Bvar%20t%3Ddocument%2Ce%3Dt.createElement(%22script%22)%2Ca%3Dt.body%2Cr%3Dt.location%3Btry%7Bif(!a)throw%200%3Be.setAttribute(%22src%22%2CBACKEND_URL%2B%22%2Fbookmarklet%2F%3Faction%3Dread-later%26url%3D%22%2BencodeURIComponent(r.href)%2B%22%26token%3D%22%2BUSER_TOKEN)%2Ca.appendChild(e)%7Dcatch(t)%7Balert(%22Please%20wait%20until%20the%20page%20has%20loaded.%22)%7D%7Dmbrl()%3B%7D)()";

export const ReadLaterInstallInstructions = ({ header = true }) => {
  const user = useSelector(authUserSelector);
  const config = useConfig();

  let { browser, isAndroid, isIos } = useDetectBrowser();
  // Uncomment for testing:
  // isIos = true;
  // browser = false;
  // isAndroid = false;

  /* --------------------------- Progressive Web App -------------------------- */

  const canInstallPwa = window.showPwaInstallButton;
  const pwaInstallPrompt = window.pwaInstallPrompt;

  const handleInstallApp = () => {
    if (!pwaInstallPrompt) {
      return;
    }
    // Show the install prompt.
    pwaInstallPrompt.prompt();
    // Log the result
    pwaInstallPrompt.userChoice.then((result) => {
      window.showPwaInstallButton = false;
    });
  };

  /* ----------------------- Select correct instructions ---------------------- */

  const [selectedInstructions, setSelectedInstructions] = useState("bookmarklet");
  useEffect(() => {
    if (browser === "chrome") setSelectedInstructions("extension");
    if (canInstallPwa && browser !== "chrome") setSelectedInstructions("pwa");
    if (!canInstallPwa && isAndroid) setSelectedInstructions("android");
    if (isIos) setSelectedInstructions("shortcut");
  }, [browser, canInstallPwa, isAndroid, isIos]);

  /* --------------------------- Render Instructions -------------------------- */

  return (
    <Fragment>
      {header && <TitleDivider mt={0} title="How to save" />}
      {/* <P2>canInstallPwa: {canInstallPwa ? "YES" : "NO"}</P2>
      <P2>pwaInstallPrompt: {pwaInstallPrompt ? "YES" : "NO"}</P2>
      <P2>browser: {browser}</P2>
      <P2>isAndroid: {isAndroid ? "YES" : "NO"}</P2>
      <P2>isIos: {isIos ? "YES" : "NO"}</P2>
      <P2>isPwa: {isPwa ? "YES" : "NO"}</P2> */}

      {(() => {
        if (selectedInstructions === "pwa") {
          return (
            <Instructions
              title="Install Mailbrew and share links from any app"
              subtitle="You'll be able to use the native Share menu"
            >
              <FadeInImage w="320px" mb={2} src={readLaterPwaImage} />
              <Button w="320px" maxW="100%" icon="arrowDownBold" onClick={handleInstallApp}>
                Install
              </Button>
            </Instructions>
          );
        }
        if (selectedInstructions === "android") {
          return (
            <Instructions title="Send links here from any app" subtitle="Just use the native Share menu">
              <FadeInImage mb={2} src={readLaterPwaImage} />
              <P2 align="center" mt={5}>
                Open this page on your computer for additional&nbsp;options.
              </P2>
            </Instructions>
          );
        }
        if (selectedInstructions === "shortcut") {
          return (
            <Instructions>
              <H5 size="1.1rem" mb={3}>
                Copy your personal token
              </H5>
              <CopyButton
                copyText={user.api_token}
                color={config.colors.shortcuts}
                variant={["secondary", "small"]}
                maxW="100%"
                icon="copy"
              >
                Click to Copy Token
              </CopyButton>
              <H5 size="1.1rem" mt={6} mb={1.2}>
                Install the official shortcut
              </H5>
              <P1 align="center" mb={4}>
                You'll be prompted to insert your personal token.
              </P1>
              <FadeInImage radius="18px" w="80px" shadow="0px 10px 40px rgba(0,0,0,.2)" mb={4} src={shortcutsImage} />
              <StyledA
                color={config.colors.shortcuts}
                variant={["secondary", "small"]}
                maxW="100%"
                icon="arrowDownBold"
                href="https://www.icloud.com/shortcuts/dc4f59c2fb3a405e9cee0eb4b7ca55c1"
              >
                Install Shortcut
              </StyledA>
              <P2 align="center" mt={5}>
                Open this page on your computer for additional&nbsp;options.
              </P2>
            </Instructions>
          );
        }
        if (selectedInstructions === "extension") {
          return (
            <Instructions
              title="Install our Chrome extension"
              subtitle="You'll be able to send links to Mailbrew with one click."
            >
              <ExternalLink href="https://chrome.google.com/webstore/detail/mailbrew-read-later/fgcpnflfkoclnkgkkimfpbehjopdfdem">
                <FadeInImage w="240px" mb={2} src={chromeExtensionImage} />
              </ExternalLink>
              <P2 mt={3} align="center">
                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a style={{ cursor: "pointer" }} onClick={() => setSelectedInstructions("bookmarklet")}>
                  Click here
                </a>{" "}
                to get a bookmarklet for other browsers.
              </P2>
              <P2 mt={2}>
                Open this page on your phone to <strong>save from mobile</strong>.
              </P2>
            </Instructions>
          );
        }
        if (selectedInstructions === "bookmarklet") {
          return (
            <Instructions
              title="Drag this bookmarklet in your address bar"
              subtitle="You'll be able to send links to Mailbrew with one click."
            >
              <Icon name="arrowDown" color={config.colors.c4} mb={3} />
              <StyledA variant="secondary" href={makeBookmarkletHref(user)} mb={3}>
                Save to Mailbrew
              </StyledA>
              <P2 mt={3} align="center">
                Or use our{" "}
                <a href="#!" onClick={() => setSelectedInstructions("extension")}>
                  Chrome Extension
                </a>
                .
              </P2>
              <P2 mt={2}>
                Open this page on your phone to <strong>save from mobile</strong>.
              </P2>
            </Instructions>
          );
        }
      })()}
    </Fragment>
  );
};

const Instructions = ({ title, subtitle, children }) => {
  return (
    <VStack align="center" gap={0}>
      {title && (
        <P1 weight="700" align="center" mb={1}>
          {title}
        </P1>
      )}
      {subtitle && (
        <P2 align="center" mb={6}>
          {subtitle}
        </P2>
      )}
      {children}
    </VStack>
  );
};

function makeBookmarkletHref(user) {
  let res = apiEnv === "dev" ? DEV_BOOKMARKLET : apiEnv === "staging" ? STAGING_BOOKMARKLET : PROD_BOOKMARKLET;
  res = res.replace("__USER_TOKEN__", user.api_token);
  return res;
}
