import { Grid, Select } from "@mailbrew/uikit";
import { Fragment } from "react";
import { Disable } from "react-disable";
import StyledA from "./StyledA";

export default function TwitterListEditorSection({ source, lists, error, onSelectTwitterList }) {
  return (
    <Fragment>
      {lists ? (
        <Select
          placeholder="Select a list to get started..."
          selectedOption={source.list_id}
          onSelect={onSelectTwitterList}
          options={lists.map((l) => l.id)}
          optionNames={lists.map((l) => l.name)}
          error={error}
        />
      ) : (
        <Select placeholder="Loading Lists..." options={[]} error={error} />
      )}
      <Grid mt={2} gap={2}>
        <Disable disabled={!source.list_id}>
          <StyledA
            href={`https://twitter.com/i/lists/${source.list_id}/`}
            targetBlank
            noStretch
            variant={["white", "pill"]}
            w="100%"
            icon="list"
            stretch
          >
            Edit this list
          </StyledA>
        </Disable>
        <StyledA
          aStyle={{ width: "100%" }}
          href={`https://twitter.com/i/lists/create`}
          targetBlank
          variant={["white", "pill"]}
          w="100%"
          stretch
          icon="plus"
        >
          Create new
        </StyledA>
      </Grid>
    </Fragment>
  );
}
