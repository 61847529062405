import { AutoGrid, Card, HStack, Icon, Select, SmallText, Stack, VStack } from "@mailbrew/uikit";
import { motion } from "framer-motion";
import { useState } from "react";
import ReactDOM from "react-dom";
import CheckboxRow from "./CheckboxRow";
import { highlightableFeatures } from "./Paywall";
import { usePaywallState } from "./PaywallStateProvider";

export default function PaywallDebugSection() {
  const {
    user,
    setUser,
    highlightedFeature,
    setHighlightedFeature,
    productId,
    setProductId,
    couponInUrlOrUser,
    setCouponInUrlOrUser,
    justUpgraded,
    setJustUpgraded,
    inboxEmpty,
    setInboxEmpty,
    savedEmpty,
    setSavedEmpty,
    afterOnboarding,
    setAfterOnboarding,
    setPaywallModalShown,
    paywallModalShown,
  } = usePaywallState();

  const [open, setOpen] = useState(true);

  const updateUserConfig = (key, value) => setUser((user) => ({ ...user, config: { ...user?.config, [key]: value } }));

  if (!user) return null;

  const debugHighlightableFeatures = [null, ...highlightableFeatures];

  return ReactDOM.createPortal(
    <Card
      as={motion.div}
      initial={false}
      animate={open ? { right: 10, bottom: 10 } : { right: "-300px", bottom: "-210px" }}
      transition={{ type: "spring", duration: 0.3, bounce: 0.1 }}
      inline
      style={{ position: "fixed", zIndex: 9999, zoom: 0.85 }}
      w="320px"
      position="relative"
    >
      <Icon
        name={open ? "arrowRightBold" : "arrowLeftBold"}
        style={{ position: "absolute" }}
        top={-1}
        left={-1}
        onClick={() => setOpen(!open)}
      />
      <VStack>
        <HStack align="spaced">
          <HStack>
            <CheckboxRow
              initiallyChecked={user.config.freemium}
              onChange={(newValue) => updateUserConfig("freemium", newValue)}
            >
              freemium
            </CheckboxRow>
            <CheckboxRow
              initiallyChecked={user.config.cc_trial_available}
              onChange={(newValue) => updateUserConfig("cc_trial_available", newValue)}
            >
              cc_trial_available
            </CheckboxRow>
            <CheckboxRow initiallyChecked={paywallModalShown} onChange={() => setPaywallModalShown(!paywallModalShown)}>
              show paywall
            </CheckboxRow>
            <CheckboxRow initiallyChecked={justUpgraded} onChange={() => setJustUpgraded(!justUpgraded)}>
              just upgraded
            </CheckboxRow>
            <CheckboxRow initiallyChecked={inboxEmpty} onChange={() => setInboxEmpty(!inboxEmpty)}>
              inbox empty
            </CheckboxRow>
            <CheckboxRow initiallyChecked={savedEmpty} onChange={() => setSavedEmpty(!savedEmpty)}>
              saved empty
            </CheckboxRow>
            <CheckboxRow initiallyChecked={afterOnboarding} onChange={() => setAfterOnboarding(!afterOnboarding)}>
              after onboarding
            </CheckboxRow>
          </HStack>
        </HStack>
        <AutoGrid>
          <Stack vertical gap={1}>
            <SmallText>Status</SmallText>
            <Select
              selectedOption={user.monetization.status}
              onSelect={(key) => {
                setUser((user) => ({ ...user, monetization: monetizations[key] }));
              }}
              options={Object.keys(monetizations)}
            />
          </Stack>
          <Stack vertical gap={1}>
            <SmallText>Highlighted Feature</SmallText>
            <Select
              selectedOption={highlightedFeature}
              onSelect={(feat) => {
                setHighlightedFeature(feat);
              }}
              options={debugHighlightableFeatures}
            />
          </Stack>
          <Stack vertical gap={1}>
            <SmallText>Plan ID</SmallText>
            <Select
              selectedOption={productId}
              onSelect={(plan) => {
                setProductId(plan);
              }}
              options={availablePlans}
            />
          </Stack>
          <Stack vertical gap={1}>
            <SmallText>Coupon</SmallText>
            <Select
              selectedOption={
                Object.entries(couponCodes)?.find((coupon) => coupon?.[1] === couponInUrlOrUser)?.[0] || "None"
              }
              onSelect={(name) => {
                setCouponInUrlOrUser(couponCodes[name]);
              }}
              options={Object.keys(couponCodes)}
            />
          </Stack>
        </AutoGrid>
      </VStack>
    </Card>,
    document.querySelector("body")
  );
}

const availablePlans = [null, "brewer", "patron"];

const couponCodes = {
  None: null,
  Educational: "7tchbvTQ",
  "Ness Labs": "ness_labs",
  "3 Months Free": "hN3qr3G1",
  "50% OFF": "XaD6lS4Z",
  "Podcast OS": "49aYEFr7",
};

// prettier-ignore
const all_sources = [
        "rss_feed", "subreddit", "twitter_user", "twitter_search", "twitter_list", "youtube_playlist",
        "youtube_channel", "hacker_news", "today", "google_news", "covid", "exchange_rate", "stocks",
        "crypto", "calendar", "text", "separator", "inbox", "devto", "product_hunt", "betalist",
        "read_later", "readwise", "twitter_timeline"
]
const paid_sources = ["exchange_rate", "stocks", "crypto", "calendar", "readwise"];
const free_sources = all_sources.filter((s) => !paid_sources.includes(s));

const freeFeatures = {
  brews: 2,
  sources_per_brew: 10,
  sources: free_sources,
  others: [],
};

const paidFeatures = {
  brews: 100,
  sources_per_brew: 120,
  sources: all_sources,
  others: ["advanced_inbox"],
};

const monetizations = {
  free: {
    status: "free",
    data: {
      valid: true,
      features: freeFeatures,
    },
  },
  subscribed: {
    status: "subscribed",
    data: {
      valid: true,
      features: paidFeatures,
    },
  },
  trialing: {
    status: "trialing",
    data: {
      valid: true,
      days_left: 10,
      duration: 14,
      features: paidFeatures,
    },
  },
  trialing_almost_over: {
    status: "trialing",
    data: {
      valid: true,
      days_left: 2,
      duration: 14,
      features: paidFeatures,
    },
  },
  trialing_cc: {
    status: "trialing_cc",
    data: {
      valid: true,
      days_left: 10,
      duration: 14,
      features: paidFeatures,
    },
  },
  trial_ended: {
    status: "trial_ended",
    data: {
      valid: false,
      features: paidFeatures,
    },
  },
  canceled: {
    status: "canceled",
    data: {
      valid: false,
      features: paidFeatures,
    },
  },
  canceled_with_access: {
    status: "canceled_with_access",
    data: {
      valid: true,
      features: paidFeatures,
    },
  },
  past_due: {
    status: "past_due",
    data: {
      valid: false,
      features: paidFeatures,
    },
  },
  free_access: {
    status: "free_access",
    data: {
      valid: true,
      features: paidFeatures,
    },
  },
};
