import { Button, Header, P2, Stack, useBreakpoint, useConfig } from "@mailbrew/uikit";
import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import { envSelect } from "utils/env";
import { usePaywallState } from "./PaywallStateProvider";

const TimedOfferBanner = ({ inEditor, style }) => {
  const config = useConfig();
  const { setPaywallModalShown, setCouponInUrlOrUser, setTrialLength } = usePaywallState();
  const hits = useBreakpoint([375, 480]);
  const user = useSelector(authUserSelector);

  const handleClaimOffer = () => {
    setCouponInUrlOrUser(envSelect("LoDPS9WL", "2JMmpTol"));
    setPaywallModalShown(true);
    setTrialLength(0);
  };

  // don't show if no user
  if (!user) return null;
  // don't show after first day of signup
  if (user.days_since_signup > 0) return null;
  // don't show it for users that have their own coupon
  if (user.profile?.coupon) return null;
  // only show it for free users
  if (user.monetization.status !== "free") return null;

  return (
    <Header
      height="34px"
      breakPoint={0}
      my={inEditor ? 4 : 0}
      style={{
        background: "hsla(130, 90%, 52%, 0.1)",
        zoom: hits[0] ? 0.9 : 1,
        ...(inEditor ? { borderRadius: 10 } : { borderBottom: `1px solid ${config.colors.uiBorderColor}` }),
      }}
    >
      <Stack w="100%" align="center" gap={2}>
        <P2 fontSize="14px" color={config.colors.c2}>
          <strong>50% off</strong> Pro just for today
        </P2>

        <Button
          color="hsla(140, 75%, 38%, 1)"
          hoverColor="hsla(140, 75%, 38%, 1)"
          height="24px"
          variant={["pill"]}
          onClick={handleClaimOffer}
          style={{ zoom: 0.9 }}
        >
          Claim offer
        </Button>
      </Stack>
    </Header>
  );
};

export default TimedOfferBanner;
