export const keyNewsletterById = (id) => `/newsletters/${id}/`;
export const keyNewsletterByShareID = (shareID) => `/newsletters_by_share_id/${shareID}/`;
export const keyUserPublicByUsername = (username) => `/user_public/?username=${username}`;

export const keyInboxSourceMessageByID = (messageId, src = null) =>
  `/inbox_source_messages/${messageId}/${src ? `?src=${src}` : ""}`;
export const keyInboxSourceMessages = `/inbox_source_messages/?size=8`;
export const keyInboxSenders = (screen_out) =>
  `/inbox_source_messages/senders/` + (typeof screen_out !== "undefined" ? `?screen_out=${screen_out}` : "");

export const keyUserInboxTagById = (id) => `/inbox_source_messages/tags/${id}/`;
export const keyUserInboxTaGs = "/inbox_source_messages/tags/";
export const keyUserInboxTaBs = "/inbox_source_messages/tabs/";
export const keyUserInboxMessagesCount = "/inbox_source_messages/count/";

export const keySenderOptionById = (id) => `/inbox_source_messages/senders/${id}/`;
export const keySenderOptionByMessageId = (id) => `/inbox_source_messages/${id}/sender_options/`;
export const keySenderOptionsFavCount = `inbox_source_messages/senders/fav_count/`;
