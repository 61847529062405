import { Button, Center, HStack, Icon, P1, Stack, useBreakpoint, useConfig } from "@mailbrew/uikit";
import { usePaywallState } from "components/PaywallStateProvider";
import { sourcesData } from "data/sourcesData";
import useEditorBackNavigationHandler from "hooks/useEditorBackNavigationHandler";
import { useRouter } from "next/router";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useRecoilState } from "recoil";
import { editorSidebarShownState } from "recoilState";
import { currentNewsletterSelector, sourceBeingEditedIndexSelector } from "reducers/newslettersReducer";
import NewsletterSyncIndicator from "./NewsletterSyncIndicator";

const EditorHeader = ({ children }) => {
  const config = useConfig();
  const breakpointHit = useBreakpoint(800);

  return (
    <Stack
      width="100%"
      height="38px"
      px={config.sidebar.padding.lateral}
      style={{
        background: config.colors.bg0,
        borderBottom: `1px solid ${config.colors.uiBorderColor}`,
        position: "sticky",
        top: 0,
        zIndex: 3,
        flex: "0 0 auto",
      }}
    >
      <HStack noWrap w="100%" align="stretch" vAlign="center" overflow="hidden">
        <BackButton w="110px" />
        <Center style={{ flex: 1, overflow: "hidden" }}>
          <Title />
        </Center>
        <HStack align="right" pl={3} w="110px">
          {!breakpointHit && <NewsletterSyncIndicator />}
        </HStack>
      </HStack>
    </Stack>
  );
};

const backButtonTitle = "";
const rootEditScreenTitle = "Edit brew";

const Title = () => {
  const { isSourceEditor, isManageSubscribers, source } = useEditorNavigationHelper();

  if (isSourceEditor) {
    if (!source) return null;
    const sourceData = sourcesData[source.type];
    return <TitleHeader color={sourceData.color}>{sourceData.name}</TitleHeader>;
  } else if (isManageSubscribers) {
    return null;
  } else {
    return <TitleHeader>{rootEditScreenTitle}</TitleHeader>;
  }
};

const TitleHeader = (props) => {
  return <P1 noWrap weight="500" {...props} />;
};

const BackButton = ({ w }) => {
  const config = useConfig();
  const handleEditorBackNavigation = useEditorBackNavigationHandler();
  const { isSourceEditor, isManageSubscribers } = useEditorNavigationHelper();

  const title = (() => {
    if (isSourceEditor) return rootEditScreenTitle;
    if (isManageSubscribers) return backButtonTitle;
    else return backButtonTitle;
  })();

  return (
    <HStack gap={0} w={w}>
      <Icon
        marginLeft="-4px"
        name="chevronLeft"
        color={config.colors.c4}
        size="24px"
        strokeWidth="1.5px"
        onClick={handleEditorBackNavigation}
      />{" "}
      <P1 weight="500" style={{ cursor: "pointer" }} color={config.colors.c3} onClick={handleEditorBackNavigation}>
        {title}
      </P1>
    </HStack>
  );
};

export const ShowPreviewButtonMobile = ({ style }) => {
  const config = useConfig();
  const breakpointHit = useBreakpoint(800);

  const currentNewsletter = useSelector(currentNewsletterSelector);

  const { afterOnboarding } = usePaywallState();
  const [clicked, setClicked] = useState(false);

  const [sidebarShown, setSidebarShown] = useRecoilState(editorSidebarShownState);

  const handleClick = () => {
    setClicked(true);
    setSidebarShown(!sidebarShown);
  };

  const isEmptyBrew = !currentNewsletter?.sources || currentNewsletter?.sources?.length === 0;
  const hasValidSource = isEmptyBrew
    ? false
    : currentNewsletter.sources.map((s) => sourcesData[s.type].isValid(s)).find((valid) => valid === true);

  if (!breakpointHit || !hasValidSource) {
    return null;
  }

  return (
    <Stack align="right" noWrap gap={2} style={{ position: "fixed", top: 6, right: 10, zIndex: 4 }}>
      <NewsletterSyncIndicator />
      <Button
        variant={["pill"]}
        color={config.colors.c3}
        noStretch
        icon={sidebarShown ? "eye" : "writeBold"}
        onClick={handleClick}
        glow={afterOnboarding && !clicked}
      >
        {sidebarShown ? "Preview" : "Edit"}
      </Button>
    </Stack>
  );
};

function useEditorNavigationHelper() {
  const { pathname, asPath } = useRouter();
  const newsletter = useSelector(currentNewsletterSelector);
  const newsletterAvailable = !!newsletter;

  const sourceIndex = useSelector(sourceBeingEditedIndexSelector);
  const source = sourceIndex !== undefined && newsletterAvailable ? newsletter.sources?.[sourceIndex] : null;

  return {
    isSourceEditor: typeof sourceIndex !== "undefined" && asPath.match(/\/edit\/\d+\/.+/),
    isManageSubscribers: pathname === "/edit/[brewId]/subscribers",
    source,
  };
}

export default EditorHeader;
