/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Box, Card, P1, SmallText, Stack, useConfig } from "@mailbrew/uikit";
import { sourcesData } from "data/sourcesData";
import { motion } from "framer-motion";
import useDarkModeListener from "hooks/useDarkModeListener";
import { useEffect, useState } from "react";
import tinycolor from "tinycolor2";

const SourceShowcaseCard = (props) => {
  const config = useConfig();

  const { source: id, highlighted, onClick, width = 320, ...otherProps } = props;

  const source = sourcesData[id];

  const { name, shortName, additionalInfo, color } = source;

  const [sourceColorOverride, setSourceColorOverride] = useState(color);

  const { isDark } = useDarkModeListener({
    onChange: (isDark) => {
      tweakColorForDarkMode(color, isDark, setSourceColorOverride);
    },
  });

  useEffect(() => {
    tweakColorForDarkMode(color, isDark, setSourceColorOverride);
  }, [isDark, color]);

  return (
    <Box
      style={{
        flex: `0 0 ${width}px`,
        display: "flex",
        order: highlighted ? 0 : 1,
      }}
      {...otherProps}
    >
      <Card
        as={motion.div}
        initial={highlighted ? { scale: 0.7, opacity: 0 } : false}
        animate={highlighted ? { scale: 1, opacity: 1 } : null}
        transition={{ type: "spring", duration: 1.2, delay: 0.6, bounce: 0.45 }}
        maxWidth="100%"
        inline
        background={config.colors.bg1}
        onClick={onClick}
        flex
        ai="center"
        jc="center"
        style={{
          overflow: "visible",
          cursor: onClick ? "pointer" : "auto",
        }}
        shadow={highlighted ? `0px 0px 24px -10px ${sourceColorOverride}` : isDark ? "none" : undefined}
      >
        <Stack vertical align="center" gap={0.5}>
          <SourceIconBadge sourceId={id} size="40px" iconSize="30px" />
          <P1 mt={1} fontSize="16px" align="center" noWrap color={sourceColorOverride} weight="500">
            {shortName ?? name}
          </P1>
          {additionalInfo && <SmallText align="center">{additionalInfo}</SmallText>}
        </Stack>
      </Card>
    </Box>
  );
};

export const SourceIconBadge = ({ sourceId, size = "44px", iconSize = "32px" }) => {
  const source = sourcesData[sourceId];
  const { color, glyph: SourceIcon } = source;
  return (
    <Box
      style={{
        background: color,
        width: size,
        height: size,
        borderRadius: size,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SourceIcon style={{ width: iconSize, height: iconSize }} color="white" />
    </Box>
  );
};

function tweakColorForDarkMode(color, isDark, setSourceColorOverride) {
  if (isDark && tinycolor(color).getLuminance() < 0.1) {
    setSourceColorOverride("white");
  } else if (isDark && tinycolor(color).getLuminance() < 0.3) {
    setSourceColorOverride(tinycolor(color).brighten(30).toString());
  } else {
    setSourceColorOverride(color);
  }
}

export default SourceShowcaseCard;
