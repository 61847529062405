import { useEventListener } from "@mailbrew/uikit";
import { forwardRef, useCallback, useEffect, useRef } from "react";
import computeIFrameHeight from "utils/computeIFrameHeight";
import makeLinksTargetBlank from "utils/makeLinksTargetBlank";

// resize

const ExternalContentIFrame = forwardRef((props, providedRef) => {
  const {
    html,
    cssString,
    baseURL,
    targetBlankLinks,
    style,
    height: providedHeight,
    transparent = true,
    onLoad,
    editHTMLElementBeforeInjection,
    customComputeIFrameHeight,
  } = props;

  const _ref = useRef();
  const ref = providedRef ?? _ref;

  // use external ref when provided, otherwise rely on internal one
  let iFrameRef = useRef(null);
  if (ref) iFrameRef = ref;

  const resizeIFrame = useCallback(() => {
    if (!iFrameRef.current) return;
    const calcHeightFunction = customComputeIFrameHeight ?? computeIFrameHeight;
    const height = providedHeight ? providedHeight : calcHeightFunction(iFrameRef.current, 20);
    iFrameRef.current.style.height = height;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providedHeight]);

  // resize the iframe when the provided height changes
  useEffect(() => resizeIFrame(), [resizeIFrame]);

  useEffect(() => {
    if (html && iFrameRef.current) {
      const doc = iFrameRef.current.contentWindow.document;
      const htmlElement = document.createElement("html");
      htmlElement.innerHTML = html;
      appendToHead(
        htmlElement,
        `
        <style>
          body {
            color: black;
          }
          p {
            word-break: break-word;
          }
          ${cssString}
        </style>
        ${baseURL ? `<base href="${baseURL}" />` : ""}
      `
      );
      editHTMLElementBeforeInjection?.(htmlElement);
      doc.open();
      doc.appendChild(htmlElement);
      doc.close();

      // Resize iFrame after injecting content
      resizeIFrame();
      // Do it again after most elements have been rendered
      setTimeout(() => {
        resizeIFrame();
      }, 500);
    }

    // We don't want the html to be inject again on resize
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html, cssString, iFrameRef, baseURL]);

  function handleIFrameLoaded() {
    if (!iFrameRef.current) return;
    onLoad && onLoad(iFrameRef.current);
    iFrameRef.current.style.visibility = "visible";
    targetBlankLinks && makeLinksTargetBlank(iFrameRef.current);
    // Resize iFrame one last time when loading is finished
    resizeIFrame();
  }

  useEventListener(
    "resize",
    () => {
      if (!iFrameRef.current) return;
      resizeIFrame();
    },
    null,
    {
      debounceDelay: 250,
    }
  );

  useEventListener(
    "scroll",
    () => {
      if (!iFrameRef.current) return;
      resizeIFrame();
    },
    null,
    {
      debounceDelay: 1000,
    }
  );

  return (
    <>
      <iframe
        title="Content"
        scrolling="no"
        src="about:blank"
        ref={ref}
        onLoad={handleIFrameLoaded}
        allowtransparency="true"
        loading="lazy"
        data-hj-allow-iframe
        style={{
          background: transparent ? "transparent" : "white",
          width: "100%",
          border: "none",
          borderRadius: "4px",
          overflow: "hidden",
          boxSizing: "border-box",
          ...style,
        }}
      />
    </>
  );
});

function appendToHead(html, element) {
  const head = html.getElementsByTagName("head")[0];
  head.insertAdjacentHTML("beforeend", element);
}

export default ExternalContentIFrame;
