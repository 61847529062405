import { Box, useBreakpoint, useConfig } from "@mailbrew/uikit";
import { forwardRef, Fragment } from "react";
import ExternalContentIFrame from "./ExternalContentIFrame";
import { FullWidthCard, FullWidthCardFooter } from "./FullWidthCard";

export const ExpandableIFrame = forwardRef(
  (
    {
      html,
      cssString,
      transparent,
      headerComponent,
      showCard,
      withHeaderSeparator,
      fullWidthCard,
      isNewsletter,
      isTextOnly,
      showFooter = false,
      expanded = true,
      nonExpandedHeight = "260px",
      footerComponent,
      style: providedStyle,
    },
    ref
  ) => {
    const config = useConfig();
    const isMobile = useBreakpoint(640);

    const style =
      isNewsletter && isMobile
        ? {
            ...providedStyle,
            marginLeft: fullWidthCard ? "-" + config.Card.bigPadding : 0,
            width: "100vw",
            padding: "0 4px",
            borderRadius: 0,
          }
        : providedStyle;

    const handleEditHTMLElementBeforeInjection = (htmlElement) => {
      if (isNewsletter) {
        specificNewsletterFixes(htmlElement, isTextOnly);
        setTimeout(() => {
          zoomOutOverflowingRootElement(htmlElement, isTextOnly);
        });
      }
    };

    return (
      <Fragment>
        <MessageCard showCard={showCard}>
          <Box w="100%" pt={showCard ? 5 : 0} borderBottom={withHeaderSeparator ? `1px solid rgba(0,0,0,.07)` : ""}>
            {headerComponent}
          </Box>
          <ExternalContentIFrame
            html={html}
            cssString={cssString}
            targetBlankLinks
            height={!expanded && nonExpandedHeight}
            style={style}
            transparent={transparent}
            editHTMLElementBeforeInjection={handleEditHTMLElementBeforeInjection}
            ref={ref}
          />
          {showFooter && (
            <FullWidthCardFooter fullWidth={fullWidthCard} expanded={expanded}>
              {footerComponent}
            </FullWidthCardFooter>
          )}
        </MessageCard>
      </Fragment>
    );
  }
);

const MessageCard = ({ children, showCard, loading }) => {
  if (showCard) {
    return (
      <FullWidthCard inline loading={loading} background="white" p={0} overflow="hidden">
        {children}
      </FullWidthCard>
    );
  } else {
    return children;
  }
};

function specificNewsletterFixes(htmlElement, isTextOnly) {
  styleElements(
    "html",
    (html) => {
      html.style.background = "white";
    },
    htmlElement
  );

  if (isTextOnly) {
    styleElements(
      "body",
      (body) => {
        body.style.fontFamily =
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
        body.style.whiteSpace = "pre-line";
        body.style.lineHeight = "1.4";
        body.style.fontSize = "15px";
      },
      htmlElement
    );
  }

  // Fixed tables
  styleElements(
    `table`,
    (div) => {
      div.style.minWidth = "auto";
    },
    htmlElement
  );

  // Ruby Weekly and full height tables
  styleElements(
    `table[style*="height: 100% !important;"]`,
    (div) => {
      div.style.height = "auto";
    },
    htmlElement
  );

  // Margins for plan text emails
  styleElements(
    "body",
    (body) => {
      body.style.margin = "0px";
      // body.style.padding = "12px";
    },
    htmlElement,
    (body) => !body.querySelector("table")
  );

  styleElements("img", (img) => prependCss(img, "max-width: 100%;"), htmlElement);

  // Substack
  // The Spotify widget made the layout overflow horizontally
  styleElements(
    `.email-body .spotify-wrap`,
    (div) => {
      div.style.maxWidth = "100%";
      div.style.overflow = "hidden";
    },
    htmlElement
  );
  styleElements(
    `.email-body .spotify-wrap span`,
    (span) => {
      span.style.whiteSpace = "unset";
    },
    htmlElement
  );

  // Convertkit fixed divs
  styleElements(
    `div[style*="margin:0 auto;width:480px"]`,
    (div) => {
      div.style.maxWidth = "100%";
    },
    htmlElement
  );

  // Maker Mind
  styleElements(
    `table[role="presentation"]>tbody>tr>td>div.email-container[style*="padding-left:20px;padding-right:20px;max-width:600px"]`,
    (div) => {
      div.style.paddingTop = "0px";
      div.style.paddingLeft = "2px";
      div.style.paddingRight = "2px";
      div.style.width = "94vw";
    },
    htmlElement
  );

  // Shaan
  styleElements(
    `table[role="presentation"]>tbody>tr>td>div.email-container>div.email-body>div.email-content[style*="padding:20px 30px"]`,
    (div) => {
      div.style.paddingLeft = "2px";
      div.style.paddingRight = "2px";
    },
    htmlElement
  );

  // Canny
  styleElements(
    `.main-td > table.content[width="480"]`,
    (table) => {
      table.width = "fixed";
      table.style.width = "100%";
      table.style.maxWidth = "480px";
    },
    htmlElement
  );

  // Trends.vc Fix
  styleElements(
    ".mcnTextContentContainer",
    (container) => {
      container.style.tableLayout = "fixed";
    },
    htmlElement
  );

  // Matt D'Avella Fix, and in general centering fixed containers
  styleElements(
    `[style*="max-width: 600px !important;"]`,
    (container) => {
      container.style.marginLeft = "auto";
      container.style.marginRight = "auto";
    },
    htmlElement
  );

  // Dave Perell
  styleElements(
    `.message-content[style*="max-width:600px"]`,
    (content) => {
      content.style.marginLeft = "auto";
      content.style.marginRight = "auto";
    },
    htmlElement
  );

  // Regular Reveries
  styleElements(
    `.newsletter-container`,
    (container) => {
      container.style.marginLeft = "auto";
      container.style.marginRight = "auto";
    },
    htmlElement
  );
}

function zoomOutOverflowingRootElement(htmlElement) {
  const windowWidth = window.innerWidth;

  function applyFix(el) {
    const elWidth = el?.offsetWidth;

    if (elWidth > 0) {
      const zoomRatio = (window.innerWidth - 20) / elWidth;

      if (zoomRatio < 0.95) {
        styleElements(
          "body",
          (body) => {
            body.style.zoom = Math.max(zoomRatio, 0.5);
          },
          htmlElement
        );
      }
    }
  }

  const allTables = Array.from(htmlElement.querySelectorAll("table"));
  let tableWidths = allTables.map((table) => table.offsetWidth);
  tableWidths = tableWidths.filter((w) => w > windowWidth && w < 1400);

  if (tableWidths.length > 0) {
    const indexOfMax = getIndexOfMax(tableWidths);
    const foundTable = allTables[indexOfMax];
    applyFix(foundTable);
    return;
  }

  const otherRootElements = [
    ...Array.from(htmlElement.querySelectorAll("body > div")),
    ...Array.from(htmlElement.querySelectorAll("body > center")),
  ];

  for (const div of otherRootElements) {
    if (Array.from(div.children).length > 0) {
      applyFix(div);
      return;
    }
  }
}

function getIndexOfMax(arr) {
  if (arr.length === 0) {
    return -1;
  }

  var max = arr[0];
  var maxIndex = 0;

  for (var i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      maxIndex = i;
      max = arr[i];
    }
  }

  return maxIndex;
}

function prependCss(el, css) {
  el.style.cssText = `${css} ${el.style.cssText}`;
}

function styleElements(selector, handler, htmlElement, condition) {
  if (!htmlElement) return;
  const elements = htmlElement.querySelectorAll(selector);
  if (!elements) return;

  elements.forEach((el) => {
    if (el && (condition ? condition(el) : true)) {
      handler(el);
    }
  });
}

export default ExpandableIFrame;
