import { AutoGrid, Box, Button, HStack, notif, P2, Spacer } from "@mailbrew/uikit";
import { sourcesData } from "data/sourcesData";
import useEditorBackNavigationHandler, { useIsEditorRootPage } from "hooks/useEditorBackNavigationHandler";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appErrorSelector } from "reducers/appReducer";
import { addAnotherSource, currentNewsletterSelector, removeSource } from "reducers/newslettersReducer";
import tinycolor from "tinycolor2";
import EditorHeader from "./EditorHeader";
import SidebarFooter from "./SidebarFooter";

const EditorSubpage = (props) => {
  const {
    children,
    type,
    showDone = true,
    forceDoneEnabled = false,
    showAddAnother = true,
    sourceIsValid: providedSourceIsValid,
    sourceIndex,
    doneLabel = "Done",
    footerExtraText,
  } = props;

  const dispatch = useDispatch();

  const newsletter = useSelector(currentNewsletterSelector);
  const syncError = useSelector(appErrorSelector("sync"));
  const source = newsletter.sources?.[sourceIndex];
  const sourceIsValid = providedSourceIsValid ?? sourcesData[source?.type]?.isValid(source);
  const hasFooterButtons = showDone || showAddAnother || sourceIsValid;

  // notify about sync errors
  useEffect(() => {
    if (syncError) {
      notif.error(syncError);
    }
  }, [syncError]);

  const handleGoBack = useEditorBackNavigationHandler();

  const isRootPage = useIsEditorRootPage();
  const canManipulateSource = !isRootPage && newsletter && typeof sourceIndex !== "undefined";

  const handleDeleteSource = () => {
    if (!canManipulateSource) return;
    dispatch(removeSource(sourceIndex));
    handleGoBack();
  };

  const handleAddAnother = () => {
    if (!canManipulateSource) return;
    const source = newsletter.sources[sourceIndex];
    dispatch(addAnotherSource(source.type));
  };

  return (
    <>
      <EditorHeader />
      {/* <SourceGradient color={sourceColor} /> */}
      {hasFooterButtons ? <Box style={{ flex: 1 }}>{children}</Box> : children}
      {hasFooterButtons && (
        <SidebarFooter>
          {footerExtraText && (
            <P2 mt={-1} mb={2} align="center">
              {footerExtraText}
            </P2>
          )}
          <AutoGrid fill={false} w="100%" align="stretch" gap="0.5em">
            {showAddAnother && (
              <Button variant="secondary" icon="plus" onClick={handleAddAnother}>
                Add another
              </Button>
            )}
            {showDone && (
              <Button disabled={!sourceIsValid && !forceDoneEnabled} icon="checkmark" onClick={handleGoBack}>
                {doneLabel}
              </Button>
            )}
          </AutoGrid>
          {sourceIsValid && (
            <>
              <Spacer size="s" />
              <HStack align="center">
                <Button variant="link" icon="trash" onClick={handleDeleteSource}>
                  Delete source
                </Button>
              </HStack>
            </>
          )}
        </SidebarFooter>
      )}
    </>
  );
};

const SourceGradient = ({ color }) => {
  if (!color) return null;
  if (tinycolor(color).getLuminance() < 0.1) return null;
  const start = tinycolor(color).saturate(100).setAlpha(0.08).toString();
  const end = tinycolor(color).saturate(100).setAlpha(0).toString();
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "600px",
        zIndex: 0,
        background: `linear-gradient(130deg, ${start} 0%, ${end} 35%)`,
      }}
    />
  );
};

export default EditorSubpage;
