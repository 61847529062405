import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  Divider,
  Expandable,
  HStack,
  Icon,
  P1,
  P2,
  RadioGroup,
  SmallText,
  Stack,
  useConfig,
} from "@mailbrew/uikit";
import { usePaywallState } from "components/PaywallStateProvider";
import SourcesBadges from "components/SourcesBadges";
import { Fragment, useEffect, useRef, useState } from "react";

const defaultCtaOptions = {
  freePlanCopy: "Miss 1-month free trial",
  standardCopy: "Get 1-month free trial",
  hideFreePlanCta: false,
};

export default function SubstackPaywall({
  loading,
  onContinueFree,
  onSubscribeToPlan,
  currentPlan,
  ctaOptions: providedCtaOptions = defaultCtaOptions,
}) {
  const [selectedPlanId, setSelectedPlanId] = useState("yearly");
  const selectedPlan = plans.find((p) => p.id === selectedPlanId);
  const ctaVariant = selectedPlanId === "free" && "white";
  const ctaOptions = { defaultCtaOptions, ...providedCtaOptions };

  const handleCTAClick = () => {
    if (selectedPlanId === "free") {
      onContinueFree();
    } else {
      onSubscribeToPlan(selectedPlan.productId, selectedPlan.periodicity);
    }
  };

  const ctaState = (() => {
    if (selectedPlanId === "free" && !ctaOptions.hideFreePlanCta) return "free_cta";
    if (selectedPlanId !== "free") return "start_ft";
    else return "none";
  })();

  return (
    <Card inline width="440px" maxW="100%">
      <RadioGroup
        options={plans.map((e) => e["id"])}
        optionsNames={plans.map((e) => e["title"])}
        selectedOption={selectedPlanId}
        optionsExtras={plans}
        optionComponent={({ option, ...props }) => (
          <PlanOption {...props} plan={plans.find((p) => p.id === option)} isCurrent={option === currentPlan} />
        )}
        onSelect={(plan) => setSelectedPlanId(plan)}
        optionStackProps={{
          width: "100%",
        }}
      />
      <Divider />
      <Box mt={4}>
        {features.map((feature) => (
          <Feature
            key={feature.id}
            feature={feature}
            sourceTypes={feature.sourceTypes}
            active={selectedPlan.features.find((f) => f === feature.id)}
          />
        ))}
      </Box>
      {ctaState === "free_cta" && (
        <Center mt={4}>
          <Button variant={ctaVariant} onClick={handleCTAClick}>
            {ctaOptions.freePlanCopy}
          </Button>
        </Center>
      )}
      {ctaState === "start_ft" && (
        <Center mt={4}>
          <Button variant={ctaVariant} onClick={handleCTAClick} loading={loading}>
            {ctaOptions.standardCopy}
          </Button>
        </Center>
      )}
      <Expandable expanded={ctaState === "free_cta"}>
        <SmallText mt={1} align="center">
          Last chance to try for 1 full month.
        </SmallText>
      </Expandable>
    </Card>
  );
}

const PlanOption = ({ plan, isCurrent, isSelected }) => {
  const config = useConfig();
  const { coupon } = usePaywallState();

  return (
    <Fragment>
      <HStack w="100%" align="spaced">
        <Stack gap={1}>
          <P1>{plan.title}</P1>
          {plan.saving && <P2 color={config.colors.c3}>(save {plan.saving})</P2>}
          {isCurrent && (
            <Badge ml={1} color={config.colors.c3} variant="secondary" style={{ padding: "3px 8px", fontSize: "13px" }}>
              Current
            </Badge>
          )}
        </Stack>
        {plan.editable ? (
          <PlanOptionPrice plan={plan} isSelected={isSelected} />
        ) : (
          <PlanOptionPrice plan={plan} coupon={coupon} isSelected={isSelected} />
        )}
      </HStack>
    </Fragment>
  );
};

const PlanOptionPrice = ({ plan, coupon }) => {
  const config = useConfig();
  const percentOff = (price, discount) => price * (1 - discount / 100);
  const formatPrice = (price, periodicity) => "$" + price + "/" + periodicity;

  // free plan
  if (!plan.price) return null;

  if (coupon) {
    const price = formatPrice(plan.price, plan.periodicity);

    let discountedPrice = !coupon ? plan.price : parseInt(percentOff(plan.price, coupon.percent_off));
    discountedPrice = formatPrice(discountedPrice, plan.periodicity);

    return (
      <P2 ml={2} color={config.colors.c2}>
        <span style={{ textDecoration: "line-through", color: config.colors.c4 }}>{price}</span> {discountedPrice}
      </P2>
    );
  } else {
    const price = formatPrice(plan.price, plan.periodicity);
    return (
      <P2 ml={2} color={config.colors.c2}>
        {price}
      </P2>
    );
  }
};

const PlanOptionEditablePrice = ({ plan, isSelected }) => {
  const [currentPrice, setCurrentPrice] = useState("$" + plan?.price);

  const inputRef = useRef();

  function selectText() {
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.setSelectionRange(0, currentPrice.length);
      });
    }
  }

  useEffect(() => {
    if (isSelected && inputRef.current) {
      selectText();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  // free plan
  if (!plan.price) return null;

  return (
    <P2 align="right">
      <input
        ref={inputRef}
        min={plan?.price}
        value={currentPrice}
        onFocus={() => {
          setCurrentPrice(currentPrice.replace("$", ""));
          selectText();
        }}
        onChange={(e) => {
          if (!isNaN(e.target.value)) {
            setCurrentPrice(e.target.value);
          }
        }}
        onBlur={(e) => {
          const num = isNaN(parseInt(e.target.value)) ? null : parseInt(e.target.value);
          if (num < plan.price) {
            setCurrentPrice("$" + plan.price);
          } else {
            setCurrentPrice("$" + num);
          }
        }}
        style={{
          color: "currentColor",
          fontSize: "inherit",
          border: "none",
          background: "transparent",
          appearance: "none",
          textAlign: "right",
          width: 70,
          padding: 0,
        }}
      />
      /{plan.periodicity}
    </P2>
  );
};

const Feature = ({ feature, active, sourceTypes }) => {
  const config = useConfig();

  return (
    <HStack mb={1} noWrap>
      <Icon size={16} name={active ? feature.icon : "close"} color={active ? config.colors.c3 : config.colors.c5} />
      <P1 color={active ? config.colors.c2 : config.colors.c5}>{feature.copy}</P1>
      <SourcesBadges size={20} sourceTypes={sourceTypes} style={{ opacity: active ? 1 : 0.4 }} />
    </HStack>
  );
};

const plans = [
  { id: "free", title: "Free", features: [1, 2] },
  {
    id: "monthly",
    productId: "brewer",
    periodicity: "month",
    title: "Pro Monthly",
    price: 10,
    features: [1, 2, 3, 4, 5, 6],
  },
  {
    id: "yearly",
    productId: "brewer",
    periodicity: "year",
    title: "Pro Yearly",
    saving: "20%",
    price: 96,
    features: [1, 2, 3, 4, 5, 6],
  },
  {
    id: "patron",
    productId: "patron",
    periodicity: "year",
    title: "Patron",
    price: 168,
    features: [1, 2, 3, 4, 5, 6, 7, 8],
    editable: true,
  },
];

const features = [
  // free
  { id: 1, copy: "Basic sources", icon: "coffee" },
  { id: 2, copy: "Receive & read newsletters in Mailbrew", icon: "emailBold" },
  // pro
  { id: 5, copy: "Pro sources: Twitter top links, Calendar, Crypto...", icon: "twitter" },
  { id: 4, copy: "Newsletters tagging and filtering", icon: "bookmark" },
  { id: 3, copy: "Multiple digests", icon: "content" },
  { id: 6, copy: "More layout and styling options", icon: "layoutBold" },
  // patron
  { id: 7, copy: "Get featured on our patrons page", icon: "heartBold" },
  { id: 8, copy: "Early access to new features", icon: "key" },
];
