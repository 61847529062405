import { P2, Spacer } from "@mailbrew/uikit";
import { ReadLaterInstallInstructions } from "components/ReadLaterInstallInstructions";
import StyledLink from "components/StyledLink";
import urls from "urls";
import EditorSubpage from "components/editor/EditorSubpage";
import SourceEditorSection from "components/editor/SourceEditorSection";
import { SourceTitleEditorSection } from "components/editor/SourceTitleEditor";

const ReadLaterSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  return (
    <EditorSubpage type={source.type} showAddAnother={false} sourceIndex={sourceIndex}>
      <SourceEditorSection noBorderTop noBorderBottom>
        <P2>Send links to Mailbrew to read them later, and see the latest saves in your digest with this source.</P2>
        <Spacer size={4} />
        <StyledLink icon="bookmark" linkStyle={{ width: "100%" }} w="100%" variant="white" to={urls.saved()}>
          See all saved links
        </StyledLink>
      </SourceEditorSection>

      <SourceEditorSection noBorderTop>
        <ReadLaterInstallInstructions header={true} />
      </SourceEditorSection>

      <SourceEditorSection title="Options" icon="settingsAlt" collapsable>
        <SourceTitleEditorSection sourceIndex={sourceIndex} noPadding noLateralPadding noBorderTop />
      </SourceEditorSection>
    </EditorSubpage>
  );
};

export default ReadLaterSourceEditor;
