import { Card, Section, SegmentedControl, Spacer } from "@mailbrew/uikit";
import Page from "components/Page";
import { useRouter } from "next/router";
import { ReactElement } from "react";
import urls from "urls";

interface SettingsPageWrapperProps {
  children?: ReactElement;
}

export default function SettingsPageWrapper(props: SettingsPageWrapperProps) {
  const router = useRouter();

  return (
    <Page title="Settings" showMonetizationBanner>
      <Section width="52em">
        <Card inline px={6} pb={6}>
          <SegmentedControl
            w="320px"
            maxW="100%"
            mx="auto"
            active={router.asPath}
            options={tabs.map((t) => t.url)}
            optionsNames={tabs.map((t) => t.name)}
            onOptionChange={(path) => router.push(path)}
            animationDelay={50}
          />
          <Spacer size="l" />
          {props.children}
        </Card>
      </Section>
    </Page>
  );
}

const tabs: { name: string; url: string }[] = [
  {
    name: "Profile",
    url: urls.settings(),
  },
  {
    name: "Accounts",
    url: urls.settings_accounts(),
  },
  {
    name: "Billing",
    url: urls.settings_billing(),
  },
];
