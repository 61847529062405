/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import {
  DropDownMenu,
  HStack,
  Icon,
  P1,
  P2,
  SegmentedControl,
  Spinner,
  Switch,
  TopLoader,
  useConfig,
} from "@mailbrew/uikit";
import ConnectGoogleAccountSection from "components/editor/ConnectGoogleAccountSection";
import EditorSubpage from "components/editor/EditorSubpage";
import SourceEditorSection from "components/editor/SourceEditorSection";
import { SourceTitleEditorSection } from "components/editor/SourceTitleEditor";
import StyledTooltip from "components/StyledTooltip";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAccountSelectedCalendars, updateSourceField } from "reducers/newslettersReducer";
import useSWR from "swr";
import calendarSchedulesOptions from "utils/calendarEventsTimeframesOptions";

const CalendarSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();

  const { data: accounts, isValidating: accountsLoading } = useSWR("/user_accounts/");
  const calendarAccounts = accounts?.filter((acc) => acc.type === "google");
  const calendarAccountConnected = calendarAccounts && calendarAccounts.length > 0;

  function handleSelectTimeframe(timeframe) {
    dispatch(updateSourceField(sourceIndex, "timeframe", timeframe));
  }

  function handleChangeHourFormat(format) {
    dispatch(updateSourceField(sourceIndex, "hour_format", format));
  }

  const isSourceValid = calendarAccountConnected;

  return (
    <EditorSubpage type={source.type} sourceIndex={sourceIndex} sourceIsValid={isSourceValid}>
      <TopLoader loading={accountsLoading} />
      {!calendarAccountConnected && !accountsLoading && (
        <SourceEditorSection icon="google" title="Google Calendar account" noBorderTop>
          <ConnectGoogleAccountSection />
        </SourceEditorSection>
      )}
      {calendarAccountConnected && (
        <Fragment>
          <SourceEditorSection subtitle="Customize your Calendar source." noBorderTop>
            <DropDownMenu
              options={Object.keys(calendarSchedulesOptions)}
              optionsNames={Object.values(calendarSchedulesOptions)}
              selectedOption={source.timeframe}
              label={calendarSchedulesOptions[source.timeframe]}
              buttonWidth="100%"
              fullWidth
              onSelect={handleSelectTimeframe}
              selectStyle
            />
          </SourceEditorSection>
          {calendarAccounts &&
            calendarAccounts.map((a, i) => (
              <CalendarsList account={a} key={i} sourceIndex={sourceIndex} source={source} />
            ))}
          <SourceEditorSection title="Accounts" icon="userCircleBold">
            <ConnectGoogleAccountSection />
          </SourceEditorSection>
        </Fragment>
      )}
      {calendarAccountConnected && (
        <SourceEditorSection title="Options" icon="settingsAlt" collapsable>
          <SourceTitleEditorSection sourceIndex={sourceIndex} noPadding noLateralPadding noBorderTop />
          <SourceEditorSection title="Hour Format" icon="time" noLateralPadding noBorderTop>
            <SegmentedControl
              style={{ marginTop: 10 }}
              options={["am", "h24"]}
              optionsNames={["AM/PM", "24H"]}
              active={source.hour_format}
              onOptionChange={handleChangeHourFormat}
            />
          </SourceEditorSection>
        </SourceEditorSection>
      )}
    </EditorSubpage>
  );
};

const CalendarsList = ({ account, sourceIndex, source }) => {
  const config = useConfig();
  const dispatch = useDispatch();

  const { data: calendars, isValidating: calendarsLoading } = useSWR(`/google_calendars_list/${account.google_id}/`);

  const [didPreselectCalendars, setDidPreselectCalendars] = useState(false);
  const noCalendarsSelected = !source.selected_calendars[account.google_id];

  useEffect(() => {
    if (!didPreselectCalendars && noCalendarsSelected && calendars) {
      dispatch(
        setAccountSelectedCalendars({
          sourceIndex,
          accountID: account.google_id,
          calendarIDs: calendars.filter((c) => c.selected).map((c) => c.id),
        })
      );
      setDidPreselectCalendars(true);
    }
  }, [
    calendars,
    didPreselectCalendars,
    source.selected_calendars,
    account.google_id,
    dispatch,
    sourceIndex,
    noCalendarsSelected,
    account,
  ]);

  const toggleCalendarSelection = useCallback(
    (calendarId) => {
      const selectedCalendarsForCurrentAccount = source.selected_calendars[account.google_id] ?? [];

      if (selectedCalendarsForCurrentAccount.includes(calendarId)) {
        dispatch(
          setAccountSelectedCalendars({
            sourceIndex,
            accountID: account.google_id,
            calendarIDs: selectedCalendarsForCurrentAccount.filter((cId) => cId !== calendarId),
          })
        );
      } else {
        dispatch(
          setAccountSelectedCalendars({
            sourceIndex,
            accountID: account.google_id,
            calendarIDs: [...selectedCalendarsForCurrentAccount, calendarId],
          })
        );
      }
    },
    [account.google_id, dispatch, source.selected_calendars, sourceIndex]
  );

  return (
    <SourceEditorSection noBorderTop>
      <HStack align="spaced" mb={4}>
        <HStack>
          <Icon name="google" color={config.colors.c2} />
          <P1 weight="600">{account.email}</P1>
        </HStack>
        {calendarsLoading && <Spinner size={16} />}
      </HStack>
      {calendars?.map((c, i) => {
        const selected = (source.selected_calendars?.[account.google_id] ?? []).includes(c.id);

        return (
          <HStack w="100%" mt={1} mb={2.5} align="spaced" noWrap overflow="hidden">
            <HStack gap={2} noWrap overflow="hidden">
              <Switch small on={selected} onClick={() => toggleCalendarSelection(c.id)} />
              <P2 color={selected ? config.colors.c1 : config.colors.c3} noWrap>
                {c.summary}
              </P2>
            </HStack>
            <ColorCircle color={c.backgroundColor} />
          </HStack>
        );
      })}
    </SourceEditorSection>
  );
};

const ColorCircle = ({ color }) => {
  const config = useConfig();

  return (
    <StyledTooltip
      interactive
      html={
        <span>
          You can edit colors on{" "}
          <a
            href="https://calendar.google.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: config.colors.accent1, fontWeight: 500 }}
          >
            Google Calendar
          </a>
        </span>
      }
    >
      <div
        css={css`
          width: 9px;
          height: 9px;
          border-radius: 9px;
          background: ${color};
        `}
      />
    </StyledTooltip>
  );
};

export default CalendarSourceEditor;
