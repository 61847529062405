/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import {
  Box,
  Container,
  Header,
  HStack,
  Icon,
  P1,
  PWARoundedCorners,
  SafeAreaInsetTop,
  Stack,
  TabBar,
  TabBarTab,
  TopLoader,
  useBreakpoint,
  useConfig,
  useDarkMode,
  useResetConfig,
  useUpdateConfig,
} from "@mailbrew/uikit";
import useKeyPressed from "hooks/useKeyPressed";
import useUpdateUser from "hooks/useUpdateUser";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { SaveLinkModal } from "pages/saved";
import { Fragment, useEffect, useState } from "react";
import signUpLink from "utils/signUpLink";
import zIndexes from "zIndexes";
import { darkConfig } from "../config";
import BrewsGlyph from "../images/glyphs/Brews.svg";
import InboxGlyph from "../images/glyphs/Newsletters.svg";
import SavedGlyph from "../images/glyphs/Saved.svg";
import urls from "../urls";
import { MailbrewLogo } from "./brand/MailbrewLogo";
import MonetizationStatusBanner from "./MonetizationStatusBanner";
import PaywallModal from "./PaywallModal";
import { usePaywallState } from "./PaywallStateProvider";
import StyledA from "./StyledA";
import StyledLink from "./StyledLink";
import TimedOfferBanner from "./TimedOfferBanner";
import UserMenu from "./UserMenu";

const Page = (props) => {
  const {
    title,
    fullTitle,
    background,
    leftHeaderComponents,
    rightHeaderComponents,
    branded,
    showMonetizationBanner,
    showTimedOffer,
    noNavigation = false,
    loading,
    noLogin,
    fixed,
    signupButtonRef,
    style,
    children,
  } = props;

  const { debuggingUI } = usePaywallState();

  useUpdateUser();

  return (
    <Container background={background} fixed={fixed} tabIndex="-1" style={style}>
      {debuggingUI}
      <PageUi
        title={title}
        fullTitle={fullTitle}
        leftHeaderComponents={leftHeaderComponents}
        rightHeaderComponents={rightHeaderComponents}
        branded={branded}
        showMonetizationBanner={showMonetizationBanner}
        showTimedOffer={showTimedOffer}
        noNavigation={noNavigation}
        loading={loading}
        noLogin={noLogin}
        signupButtonRef={signupButtonRef}
      >
        {children}
      </PageUi>
    </Container>
  );
};

const PageUi = (props) => {
  const config = useConfig();

  const {
    title,
    fullTitle,
    leftHeaderComponents,
    rightHeaderComponents: providedRightHeaderComponents,
    branded,
    showMonetizationBanner,
    showTimedOffer,
    noNavigation = false,
    loading,
    noLogin,
    children,
    signupButtonRef,
  } = props;

  /* -------------------------------- Dark Mode ------------------------------- */

  const updateConfig = useUpdateConfig();
  const resetConfig = useResetConfig();
  const [darkMode] = useDarkMode();

  useEffect(() => {
    if (darkMode) {
      updateConfig(darkConfig);
    } else {
      resetConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode]);

  /* --------------------------- User & Monetization -------------------------- */

  const { paywallModalShown, setPaywallModalShown, user } = usePaywallState();

  /* ---------------------------------- Crisp --------------------------------- */

  const loggedOutMailbrewLogo = () => {
    if (user) {
      return null;
    } else {
      return (
        <a style={{ lineHeight: 0 }} href="https://mailbrew.com/?utm_source=brew-profile-page">
          <MailbrewLogo responsive />
        </a>
      );
    }
  };

  /* -------------------------------- Elements -------------------------------- */

  const loggedInNavigation = user && (
    <Fragment>
      <HeaderButton to={urls.brews()} exact icon="brews" label="Brews" />
      <HeaderButton to={urls.inbox()} icon="inbox" label="Newsletters" />
      <HeaderButton to={urls.saved()} icon="later" label="Saved" />
    </Fragment>
  );

  const rightHeaderComponents = (
    <Fragment>
      {providedRightHeaderComponents && providedRightHeaderComponents}
      {user ? (
        <UserMenu zIndex={zIndexes.userMenu} />
      ) : noLogin ? null : (
        <Stack noWrap>
          <StyledLink to={urls.login()} variant={["line", "small"]}>
            Login
          </StyledLink>
          <StyledA to={signUpLink(signupButtonRef)} variant={"small"}>
            Sign Up
          </StyledA>
        </Stack>
      )}
    </Fragment>
  );

  /* ---------------------------------- State --------------------------------- */

  const [savedLinkModalShown, setSavedLinkModalShown] = useState(false);

  useKeyPressed(
    "S",
    () => {
      setSavedLinkModalShown(true);
    },
    {
      withShift: true,
    }
  );

  const hits = useBreakpoint([TAB_BAR_BREAKPOINT, 800]);
  const smallBreakpoint = hits[0];
  const bigBreakpoint = hits[1];

  const showHeader = (!smallBreakpoint || !user) && !noNavigation;
  const showTabBar = smallBreakpoint && user && !noNavigation;

  return (
    <Fragment>
      {user && <SaveLinkModal show={savedLinkModalShown} setShow={setSavedLinkModalShown} />}
      <PaywallModal show={paywallModalShown} setShow={setPaywallModalShown} />
      {branded && <MailbrewBrand urlRef={signupButtonRef} />}
      <TopLoader loading={loading} />
      <PWARoundedCorners color="black" radius={18} />
      <Head>
        <title>{fullTitle ?? title ?? "Mailbrew"}</title>
      </Head>
      {!showHeader && (
        <Box w="100%">
          <SafeAreaInsetTop />
        </Box>
      )}
      {showTimedOffer && <TimedOfferBanner />}
      {showMonetizationBanner && <MonetizationStatusBanner />}
      {showHeader && (
        <Header
          leftComponents={
            <HStack align="left" vAlign="center" gap={1.5} w={leftHeaderComponents && "180px"} zIndex="2" noWrap>
              {leftHeaderComponents && leftHeaderComponents}
              {loggedOutMailbrewLogo()}
            </HStack>
          }
          permanentComponents={rightHeaderComponents}
          breakPoint={0}
        >
          <HStack
            position="absolute"
            top="0"
            left="0"
            right="0"
            height={config.Header.height}
            align="center"
            gap={bigBreakpoint ? 4 : 5}
            noWrap
            zIndex="1"
          >
            {loggedInNavigation}
          </HStack>
        </Header>
      )}
      {showTabBar && (leftHeaderComponents || providedRightHeaderComponents) && (
        <Header mt="9px" height="30px" breakPoint={0}>
          <HStack pb="2px" w="100%" align="right" vAlign="center" gap={1.5}>
            {leftHeaderComponents && leftHeaderComponents}
            {providedRightHeaderComponents && providedRightHeaderComponents}
            {loggedOutMailbrewLogo()}
          </HStack>
        </Header>
      )}
      {children}
      {showTabBar && loggedInNavigation && (
        <TabBar zIndex={zIndexes.tabBar}>
          {loggedInNavigation}
          <TabBarTab label="Me">
            <UserMenu size="24px" />
          </TabBarTab>
        </TabBar>
      )}
    </Fragment>
  );
};

export default Page;

const MailbrewBrand = ({ urlRef = "brew-public-page" }) => {
  const config = useConfig();

  const lineCSS = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: ${config.colors.accent1};
  `;

  const wrapperCSS = css`
    display: block;
    text-decoration: none;
    position: absolute;
    top: 4px;
    right: 0;
    background: ${config.colors.accent1};
    padding: 0 4px;
    border-radius: 0 0 0 6px;
    z-index: 6;
    transform: scale(1.001);
    transform-origin: 100% 0;
    transition: transform 0.2s cubic-bezier(0.2, 0, 0.3, 1);
    :hover {
      transform: scale(1.15);
    }
    :before {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      background: radial-gradient(circle at 0 100%, rgba(0, 0, 0, 0) 7px, ${config.colors.accent1} 7px);
      position: absolute;
      left: -7px;
      top: 0px;
    }
  `;

  return (
    <Fragment>
      <div css={lineCSS} />
      <a href={signUpLink(urlRef)} css={wrapperCSS}>
        <MailbrewLogo color="white" label={false} size="17px" />
      </a>
    </Fragment>
  );
};

const StyledNavLink = ({ href, style, activeStyle, type = "link", onClick, children, ...rest }) => {
  const router = useRouter();
  const isActive = href === router.pathname;

  return (
    <Link href={href} onClick={onClick} {...rest} passHref>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a style={{ textDecoration: "none", borderBottom: "none", ...(isActive ? activeStyle : style) }}>{children}</a>
    </Link>
  );
};

const HeaderButton = ({ onClick, to, exact, icon, label, style, forceActive, ...rest }) => {
  const config = useConfig();
  const activeColor = config.colors.accent1;

  const hits = useBreakpoint(TAB_BAR_BREAKPOINT);

  function handleTabClick() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    onClick && onClick();
  }

  return (
    <StyledNavLink
      key={label}
      style={{ color: !forceActive ? config.colors.c2 : activeColor }}
      activeStyle={{ color: activeColor }}
      href={to}
      exact={exact}
    >
      {hits ? (
        <TabBarTab color={"currentColor"} label={label} onClick={handleTabClick}>
          <TabIcon icon={icon} strokeWidth="3px" width="26px" height="26px" color="currentColor" fill="currentColor" />
        </TabBarTab>
      ) : (
        <HStack gap="4px" noWrap onClick={onClick} style={{ cursor: "pointer", ...style }} {...rest}>
          <TabIcon icon={icon} strokeWidth="3px" color="currentColor" fill="currentColor" />
          <P1 weight="500" lineHeight={0} color="currentColor">
            {label}
          </P1>
        </HStack>
      )}
    </StyledNavLink>
  );
};

export const TAB_BAR_BREAKPOINT = 680;

const TabIcon = ({ icon, ...otherProps }) => {
  if (icon === "brews") {
    return <BrewsGlyph {...otherProps} />;
  }
  if (icon === "inbox") {
    return <InboxGlyph {...otherProps} />;
  }
  if (icon === "later") {
    return <SavedGlyph {...otherProps} />;
  }

  return <Icon name={icon} {...otherProps} />;
};
