import MonetizationAwareSliderWithInput from "components/MonetizationAwareSliderWithInput";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import InfoText from "./InfoText";
import SourceEditorSection from "./SourceEditorSection";

export default function NumPostsSourceEditor({
  title,
  source,
  sourceIndex,
  fieldName = "num_posts",
  min,
  max,
  defaultValue,
  shortExplanationBuilder,
  explanationModalBody,
  explanationModalIcon,
  noBorderTop,
  noBorderBottom,
  noWrappingSection,
}) {
  const dispatch = useDispatch();

  return (
    <Wrapper
      noWrappingSection={noWrappingSection}
      noBorderTop={noBorderTop}
      noBorderBottom={noBorderBottom}
      title={title}
    >
      <MonetizationAwareSliderWithInput
        min={min}
        max={max}
        value={source[fieldName] ?? defaultValue}
        onChange={(value) => dispatch(updateSourceField(sourceIndex, fieldName, parseInt(value)))}
      />
      <InfoText
        text={shortExplanationBuilder(source[fieldName])}
        label="Learn more."
        modalTitle="Which posts are included?"
        modalBody={explanationModalBody}
        modalIcon={explanationModalIcon}
      />
    </Wrapper>
  );
}

const Wrapper = ({ children, noWrappingSection, noBorderTop, noBorderBottom, title }) =>
  noWrappingSection ? (
    <>{children}</>
  ) : (
    <SourceEditorSection
      noBorderTop={noBorderTop}
      noBorderBottom={noBorderBottom}
      title={title ?? "Included Posts"}
      icon="list"
      noPadding
    >
      {children}
    </SourceEditorSection>
  );
