import { P2 } from "@mailbrew/uikit";
import EditorSubpage from "components/editor/EditorSubpage";
import SourceEditorSection from "components/editor/SourceEditorSection";

const SeparatorSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  return (
    <EditorSubpage type={source.type} sourceIndex={sourceIndex}>
      <SourceEditorSection noBorderTop>
        <P2>Use a separator to better divide different sections of your brew.</P2>
      </SourceEditorSection>
    </EditorSubpage>
  );
};

export default SeparatorSourceEditor;
