import { Button, Input, P2, Spacer } from "@mailbrew/uikit";
import ExternalLink from "components/ExternalLink";
import api from "dependencies/api";
import useInputValidator from "hooks/useInputValidator";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector, setUser } from "reducers/authReducer";
import { updateSourceField } from "reducers/newslettersReducer";
import { useDebouncedCallback } from "use-debounce/lib";
import * as Yup from "yup";
import EditorSubpage from "components/editor/EditorSubpage";
import SourceEditorSection from "components/editor/SourceEditorSection";
import ColorPicker from "components/editor/ColorPicker";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import { notifySourcePreviewForReload } from "./InboxSourceEditor";

const ReadwiseSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  const sourceIsValid = !!user.readwise_token;

  const [debounceUpdateReadwiseToken] = useDebouncedCallback((readwise_token) => {
    api.post("/update_user/", { readwise_token }).then((res) => {
      dispatch(setUser(res.data));
      notifySourcePreviewForReload(sourceIndex);
    });
  }, 500);

  const [readwiseToken, setReadwiseToken, readwiseTokenError] = useInputValidator(
    user.readwise_token ?? "",
    Yup.string().max(256, "Type a shorter token."),
    (value) => {
      debounceUpdateReadwiseToken(value);
    }
  );

  return (
    <EditorSubpage type={source.type} showAddAnother={false} sourceIndex={sourceIndex} sourceIsValid={sourceIsValid}>
      <SourceEditorSection noBorderTop noBorderBottom>
        <P2>Get the highlights from your Readwise daily review.</P2>
        <Spacer size={4} />
        <Input
          placeholder="Readwise Token"
          value={readwiseToken}
          onChange={(e) => setReadwiseToken(e.target.value)}
          type="password"
          error={readwiseTokenError}
        />
        <Button
          variant="white"
          w="100%"
          icon="key"
          iconSize="14px"
          mt={2}
          onClick={() => window.open("https://readwise.io/access_token", "_blank")}
        >
          Get Your Token
        </Button>
      </SourceEditorSection>

      <NumPostsSourceEditor
        title="Included Quotes"
        source={source}
        sourceIndex={sourceIndex}
        fieldName="num_posts"
        min={1}
        max={3}
        shortExplanationBuilder={(num) =>
          num === 1
            ? "We'll send you the first quote from your daily review."
            : `We'll send you up to ${num} quotes from your daily review.`
        }
      />

      <SourceEditorSection title="Color" icon="palette">
        <ColorPicker
          palette="quote"
          selectedColor={source.quote_color}
          onChange={(newColor) => dispatch(updateSourceField(sourceIndex, "quote_color", newColor))}
        />
      </SourceEditorSection>

      <SourceEditorSection title="About Readwise" icon="info">
        <P2>
          You can learn more about Readwise and create an account over at{" "}
          <ExternalLink href="https://readwise.io/?ref=mailbrew">readwise.io</ExternalLink>.
        </P2>
      </SourceEditorSection>
    </EditorSubpage>
  );
};

export default ReadwiseSourceEditor;
