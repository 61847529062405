import { Box, Button, Grid, H5, HStack, P1, Section, Stack } from "@mailbrew/uikit";
import { motion } from "framer-motion";
import useInboxAddress from "hooks/useInboxAddress";
import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import supportLinks from "supportLinks";
import useSWR from "swr";
import urls from "urls";
import CopyButton from "./CopyButton";
import { FeatureTitleAndIcon } from "./EmptyStateCard";
import InboxMessageView from "./InboxMessageView";
import { usePaywallState } from "./PaywallStateProvider";
import StyledA from "./StyledA";
import StyledUserInboxAddress from "./StyledUserInboxAddress";
import TabsPicker from "./TabsPicker";
import TitleDivider from "./TitleDivider";

const NewslettersEmptyState = ({ mt, withPreviewSection = true }) => {
  const userInboxEmail = useInboxAddress();
  const [sampleMessagesLoaded, setSampleMessagesLoaded] = useState(false);

  const router = useRouter();

  const { config: userConfig, monetization, setPaywallModalShown, setHighlightedFeature } = usePaywallState();

  const freemiumAvailable = userConfig.freemium;
  const ccTrialAvailable = userConfig.cc_trial_available;
  const inboxLocked = !monetization.data.valid || !monetization.data.features.sources.includes("inbox");

  function handleLockedClick() {
    if (inboxLocked) {
      setHighlightedFeature("inbox");
      setPaywallModalShown(true);
    }
  }

  return (
    <Fragment>
      <Section noPadding first width="100%" mt={mt}>
        <FeatureTitleAndIcon title="Receive newsletters in Mailbrew" glyph="inbox" />
        <Stack vertical align="center" w="500px" mx="auto" gap={0} maxW="100%">
          {inboxLocked ? (
            <P1 mb={4} align="center">
              <Button variant="link" onClick={handleLockedClick} fontSize="100%">
                Upgrade now
              </Button>{" "}
              to get your custom Mailbrew address to receive newsletters here, and in your next digest.
            </P1>
          ) : (
            <P1 mb={4} align="center">
              Subscribe to newsletters with this address, and you'll receive new issues in this page and in your next
              brew.
            </P1>
          )}
          {inboxLocked && (
            <Stack mb={4} align="center">
              <Button icon="starBold" onClick={handleLockedClick}>
                {ccTrialAvailable
                  ? "Try this for free"
                  : freemiumAvailable
                  ? "Unlock this feature"
                  : "Upgrade to use this feature"}
              </Button>
            </Stack>
          )}
          <Stack vertical gap={0} align="stretch" w="440px" maxW="100%">
            <UserInboxAddressCopyBoxWithButton
              email={inboxLocked ? "your@inbox.mailbrew.com" : userInboxEmail}
              stretch
              locked={inboxLocked}
              mt={2}
            />
            <TitleDivider small title="or" />
            <InboxAdditionalInstructions />
          </Stack>
        </Stack>
      </Section>
      {withPreviewSection && (
        <Box
          as={motion.div}
          animate={sampleMessagesLoaded ? { y: 0, opacity: 1 } : { y: -20, opacity: 0 }}
          transition={{ type: "spring", duration: 0.7, bounce: 0.1, delay: 0.7 }}
          initial={false}
          w="100%"
        >
          <Stack vertical align="center" gap={0} my={10}>
            <H5 align="center" mb={1}>
              Try it below
            </H5>
            <P1 align="center" maxWidth="80vw" mx="auto">
              Read&nbsp;newsletters&nbsp;here and in your&nbsp;next&nbsp;digest ☕️
            </P1>
          </Stack>
          <Box
            mb={5}
            onClick={(e) => {
              e.stopPropagation();
              router.push(urls.manageInbox());
            }}
          >
            <TabsPicker
              items={[
                {
                  id: "fav",
                  name: "Favorites",
                  icon: "starBold",
                },
                { name: "All" },
                { name: "Substacks" },
                { name: "VC" },
                { name: "Tech" },
                { name: "Interesting" },
              ]}
              active={"fav"}
              tabKey="id"
              tabNameKey="name"
            />
          </Box>
          <Box w="900px" maxW="100%" mx="auto">
            {sampleMessages.map((msg) => (
              <SampleMessage
                key={msg.id}
                id={msg.id}
                token={msg.token}
                onMessageReady={() => setSampleMessagesLoaded(true)}
              />
            ))}
          </Box>
        </Box>
      )}
    </Fragment>
  );
};

export const UserInboxAddressCopyBoxWithButton = ({ email, stretch, locked, onClick, ...otherProps }) => {
  return (
    <HStack
      maxW="100%"
      overflow="hidden"
      align="center"
      vAlign="center"
      noWrap
      breakAt={640}
      breakAlign="stretch"
      onClick={onClick}
      {...otherProps}
    >
      <StyledUserInboxAddress email={email} stretch={stretch} locked={locked} />
      <CopyButton
        variant={locked ? "white" : null}
        icon="copy"
        copyText={email}
        style={locked ? { pointerEvents: "none" } : {}}
      >
        Copy
      </CopyButton>
    </HStack>
  );
};

export const InboxAdditionalInstructions = ({ style }) => {
  return (
    <Grid style={style} breakAt={420}>
      <StyledA icon="substack" variant={["white"]} href={supportLinks.substack} targetBlank>
        Forward from Substack
      </StyledA>
      <StyledA icon="google" variant={["white"]} href={supportLinks.forwardGmail} targetBlank>
        Forward from Gmail
      </StyledA>
    </Grid>
  );
};

const sampleMessages = [
  {
    id: "67i70545orsol0g8d5vc4icji9pjhgrc9a1h2bo1",
    token:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY3Rpb24iOiJyZWFkX2luYm94X21lc3NhZ2UiLCJtZXNzYWdlX2lkIjoiNjdpNzA1NDVvcnNvbDBnOGQ1dmM0aWNqaTlwamhncmM5YTFoMmJvMSIsImlhdCI6MTYxMjc3ODY4NX0.r_BlR7H1Wrz7YpyilgR3M5bHHaT3fMw4olJMxXErL-E",
  },
  {
    id: "puv9v6n4t5k67t1e2khgjrh12bprghiltebgaeo1",
    token:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY3Rpb24iOiJyZWFkX2luYm94X21lc3NhZ2UiLCJtZXNzYWdlX2lkIjoicHV2OXY2bjR0NWs2N3QxZTJraGdqcmgxMmJwcmdoaWx0ZWJnYWVvMSIsImlhdCI6MTYxMjc3ODczM30.mKZTIQLNfEOaigCnX7c5JlQJmvcqEywYe5_94Q2tZOo",
  },
  {
    id: "dl3ggbei3igi7uafqbo8uqrgj1sj6r7vbi8g6f81",
    token:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY3Rpb24iOiJyZWFkX2luYm94X21lc3NhZ2UiLCJtZXNzYWdlX2lkIjoiZGwzZ2diZWkzaWdpN3VhZnFibzh1cXJnajFzajZyN3ZiaThnNmY4MSIsImlhdCI6MTYwOTg2NDg0N30.1eAB40oN9ipbQi1hqRM1oqCwOOc5hE-pQWSL35SWXNQ",
  },
  {
    id: "ni78fgnnu5llbp7gl0sods6ade5c17eto0jrb2o1",
    token:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY3Rpb24iOiJyZWFkX2luYm94X21lc3NhZ2UiLCJtZXNzYWdlX2lkIjoibmk3OGZnbm51NWxsYnA3Z2wwc29kczZhZGU1YzE3ZXRvMGpyYjJvMSIsImlhdCI6MTYwOTg2NDg0N30.KS4pnLIL2Ig7UoB_k8OhUW6IkaAtOFniXCeSln2PBrU",
  },
  {
    id: "l939cotkrqfqq9bs8os9pm6p17ka5vuv2rfkqso1",
    token:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY3Rpb24iOiJyZWFkX2luYm94X21lc3NhZ2UiLCJtZXNzYWdlX2lkIjoibDkzOWNvdGtycWZxcTliczhvczlwbTZwMTdrYTV2dXYycmZrcXNvMSIsImlhdCI6MTYwOTg2NDgzNX0.erU4DIZYFC2t76KBp_vxLJD_ONtVl0tZOs_CD3o-WH4",
  },
];

// Staging sample message
// [
//   {
//     id: "l4kfc6fdthldfhjupn7kq94c5f58s87thb75oog1",
//     token:
//       "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY3Rpb24iOiJyZWFkX2luYm94X21lc3NhZ2UiLCJtZXNzYWdlX2lkIjoibDRrZmM2ZmR0aGxkZmhqdXBuN2txOTRjNWY1OHM4N3RoYjc1b29nMSIsImlhdCI6MTYxNTQ3NjQ3Mn0.cL6agRYsao3Jd4YX3fR-wJrdCCWbS9o1vqFILS_sORA",
//   },
// ];

const SampleMessage = ({ id, token, onMessageReady }) => {
  const { data: message } = useSWR(`/inbox_source_messages/${id}/?token=${token}`);
  return (
    <InboxMessageView
      message={message}
      showCard
      expandable
      onHtmlReady={onMessageReady}
      fullTitle
      fullWidthCard
      keepReadingEnabled={false}
      actionsEnabled={false}
    />
  );
};

export default NewslettersEmptyState;
