import { H4, HStack, Modal, P1, SubtleCard, useBreakpoint, useConfig } from "@mailbrew/uikit";
import useKeyPressed from "hooks/useKeyPressed";
import TitleDivider from "./TitleDivider";

const KeyboardShortcutsModal = (props) => {
  const { show, setShow } = props;
  const isMobile = useBreakpoint(640);

  useKeyPressed("?", () => {
    setShow(true);
  });

  useKeyPressed("Escape", () => {
    setShow(false);
  });

  return (
    <Modal bottomSheet={isMobile} width="28em" show={show} setShow={setShow}>
      <H4 mb={2}>Keyboard Shortcuts</H4>
      <TitleDivider title="Navigation" my={3} />
      <SubtleCard py={1} px={0}>
        <HotKey label="Brews" hotKey="1" />
        <HotKey label="Newsletters" hotKey="2" />
        <HotKey label="Saved" hotKey="3" />
        <HotKey label="Manage Brews" hotKey="4" last />
      </SubtleCard>
      <TitleDivider title="Newsletters" my={3} />
      <SubtleCard py={1} px={0}>
        <HotKey label="Next / Previous Issue" hotKey="j / k" />
        <HotKey label="Expand Issue" hotKey="Enter" />
        <HotKey label="Archive Issue" hotKey="e" last />
        <HotKey label="Delete Issue" hotKey="Delete / Backspace" last />
      </SubtleCard>
      <TitleDivider title="Saved" my={3} />
      <SubtleCard py={1} px={0}>
        <HotKey label="Next / Previous Item" hotKey="j / k" />
        <HotKey label="Open Item" hotKey="Enter" />
        <HotKey label="Open with Reader Mode" hotKey="Shift + Enter" />
        <HotKey label="Close Reader Mode" hotKey="Esc" />
        <HotKey label="Archive Item" hotKey="e" />
        <HotKey label="Delete Item" hotKey="Delete / Backspace" last />
      </SubtleCard>
      <TitleDivider title="Everywhere" my={3} />
      <SubtleCard py={1} px={0}>
        <HotKey label="Save URL for later" hotKey="Shift + S" last />
      </SubtleCard>
    </Modal>
  );
};

const HotKey = ({ label, hotKey, last }) => {
  const config = useConfig();
  return (
    <HStack py={2} px={4} align="spaced" borderBottom={last ? "" : `1px solid ${config.colors.bg0}`}>
      <P1 weight="600">{label}</P1>
      <P1 weight="600" color={config.colors.c4}>
        {hotKey}
      </P1>
    </HStack>
  );
};

export default KeyboardShortcutsModal;
