import { getColorFromCssVariable, useConfig } from "@mailbrew/uikit";
import { forwardRef, Fragment } from "react";
import ExternalContentIFrame from "./ExternalContentIFrame";

const ReaderModeIFrame = forwardRef(({ baseURL, contentHTML }, ref) => {
  const config = useConfig();
  const cssString = articleBodyCss(config);

  return (
    <Fragment>
      <ExternalContentIFrame baseURL={baseURL} html={contentHTML} cssString={cssString} transparent targetBlankLinks />
    </Fragment>
  );
});

const articleBodyCss = (config) => `
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Merriweather-Regular.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Merriweather';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Merriweather-Italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/Merriweather-Bold.ttf) format('truetype');
  }
  body {
    margin: 0;
    padding: 0;
    color: ${getColorFromCssVariable(config.colors.c1)};
    width: 660px;
    max-width: 100%;
    word-break: break-word;
    font-size: 17px;
    line-height: 1.75;
    font-family: "Merriweather", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
  }
  @media (prefers-color-scheme: dark) {
    body {
      -webkit-font-smoothing: antialiased;
    }
  }
  a {
    color: ${getColorFromCssVariable(config.colors.c1)};
    text-decoration: none;
    border-bottom: 2px solid ${getColorFromCssVariable(config.colors.c4)};
    transition: 0.1s border-bottom;
  }
  a:hover {
    border-bottom: 2px solid ${getColorFromCssVariable(config.colors.c3)};
  }
  a b {
    font-weight: inherit;
  }
  b {
    font-weight: 700;
  }
  @media (max-width: 480px) {
    body {
      font-size: 16px;
    }
  }
  p {
    margin-top: 5px;
    margin-bottom: 20px;
  }
  p, span {
    font-weight: 400;
  }
  blockquote {
    border-left: 3px solid ${getColorFromCssVariable(config.colors.c5)};
    padding-left: 18px;
    margin-left: 0px;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    margin-top: 0;
    margin-bottom: 12px;
  }
  h1 {
    font-size: 26px;
    line-height: 1.3;
  }
  h2 {
    font-size: 22px;
    line-height: 1.3;
  }
  h3 {
    font-size: 21px;
    line-height: 1.3;
  }
  h4 {
    font-size: 20px;
    line-height: 1.3;
  }
  h5 {
    font-size: 20px;
    line-height: 1.3;
  }
  li {
    margin-bottom: 0.3em;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  hr {
    border: none;
    border-bottom: 1px solid var(--uiBorderColor);
    margin-top: 25px;
    margin-bottom: 25px;
  }
  p + figure, h2 + figure, h3 + figure, h4 + figure, h5 + figure, span + figure {
    padding: 0;
    margin: 36px 0;
  }
  figure:first-child {
    margin-top: 0;
  }
  figcaption, div > small {
    color: ${getColorFromCssVariable(config.colors.c3)};
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
    font-size: 14px;
  }
  /* Fix for Dev.to */
  pre + div > p > svg {
    display: none;
  }
  p code,
  li code {
    background: rgba(135, 135, 135, 0.25);
    padding: 2px 7px;
    border-radius: 6px;
    font-size: 15px;
  }
  div > pre {
    font-size: 15px;
  }
  pre {
    white-space: pre-line;
  }
  pre code {
    display: block;
    width: 100%;
    color: rgba(255, 255, 255, 0.95);
    background: black;
    border: 1px solid var(--uiBorderColor);
    padding: 16px 20px;
    border-radius: 7px;
    line-height: 1.5;
    white-space: pre-line;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    font-size: 15px;
  }
  table {
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
  }
  table td {
    vertical-align: top;
    font-size: 14px;
  }
  div,
  iframe {
    max-width: 100%;
  }
  iframe {
    border: none;
    width: 100%;
    border-radius: 6px;
  }
  center {
    text-align: left;
  }
  }
`;

export default ReaderModeIFrame;
