import { P2 } from "@mailbrew/uikit";
import api from "dependencies/api";
import useSWR, { mutate } from "swr";
import ServiceCard from "components/ServiceCard";
import { connectGoogleAccount } from "pages/settings/accounts";

const ConnectGoogleAccountSection = ({ align = "left" }) => {
  const { data: accounts } = useSWR("/user_accounts/");
  const calendarAccounts = accounts?.filter((acc) => acc.type === "google");
  const calendarAccountConnected = calendarAccounts && calendarAccounts.length > 0 && !calendarAccounts.error;

  const onClickRemoveGoogleAccount = async (google_id) => {
    await api.post(`/connect/google/delete/${google_id}/`);
    mutate("/user_accounts/");
  };

  const onClickReconnectGoogleAccount = async () => {
    connectGoogleAccount();
  };

  return (
    <>
      {calendarAccounts &&
        calendarAccounts.length > 0 &&
        calendarAccounts.map((c, i) => (
          <ServiceCard
            key={i}
            type="google"
            connected
            user={c.email}
            onClickRemove={() => onClickRemoveGoogleAccount(c.google_id)}
            onClickReconnect={onClickReconnectGoogleAccount}
          />
        ))}
      <ServiceCard
        type="google"
        onClickRemove={onClickRemoveGoogleAccount}
        onClickReconnect={onClickReconnectGoogleAccount}
      />
      {!calendarAccountConnected && (
        <P2 align={align}>
          No Google Calendar account?{" "}
          <a
            href="https://accounts.google.com/signup/v2/webcreateaccount?service=cl&continue=https%3A%2F%2Fcalendar.google.com%2Fcalendar%2Frender&gmb=exp&biz=false&flowName=GlifWebSignIn&flowEntry=SignUp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign up here.
          </a>
        </P2>
      )}
    </>
  );
};

export default ConnectGoogleAccountSection;
