import { P2 } from "@mailbrew/uikit";
import ExternalLink from "components/ExternalLink";
import EditorSubpage from "components/editor/EditorSubpage";
import SourceEditorSection from "components/editor/SourceEditorSection";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";

const BetaListSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  return (
    <EditorSubpage type={source.type} showAddAnother={false} sourceIndex={sourceIndex}>
      {/* NUMBER OF POSTS */}
      <NumPostsSourceEditor
        source={source}
        noBorderTop
        noBorderBottom
        sourceIndex={sourceIndex}
        min={1}
        max={10}
        fieldName="num_posts"
        explanationModalBody="In each issue of your digest, Mailbrew includes the featured products from BetaList published since the previous issue of your digest."
        shortExplanationBuilder={(numPosts) =>
          numPosts > 1 ? `Receive the ${numPosts} most popular products.` : `Receive the most popular product.`
        }
      />

      <SourceEditorSection noBorderTop>
        <P2>
          Powered by <ExternalLink href="https://betalist.com/?ref=mailbrew">BetaList</ExternalLink>.
        </P2>
      </SourceEditorSection>
    </EditorSubpage>
  );
};

export default BetaListSourceEditor;
