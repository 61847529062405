import { Box, Divider, Expandable, HStack, Icon, P1, P2, SegmentedControl, Stack, useConfig } from "@mailbrew/uikit";
import EditorSubpage from "components/editor/EditorSubpage";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import PostBody from "components/editor/PostBody";
import SourceEditorSection from "components/editor/SourceEditorSection";
import ExternalLink from "components/ExternalLink";
import { InboxAdditionalInstructions, UserInboxAddressCopyBoxWithButton } from "components/NewslettersEmptyState";
import StyledLink from "components/StyledLink";
import StyledUserInboxAddress from "components/StyledUserInboxAddress";
import TitleDivider from "components/TitleDivider";
import { useSWRWithState } from "hooks/swrState";
import useInboxAddress from "hooks/useInboxAddress";
import { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import ReactTags from "react-tag-autocomplete";
import { updateSourceField } from "reducers/newslettersReducer";
import useSWR from "swr";
import urls from "urls";
import { groupBy } from "utils/array";
import plausible from "utils/plausible";
import { keySenderOptionsFavCount, keyUserInboxMessagesCount, keyUserInboxTaGs } from "utils/swrKeys";
import SourceEditorCallout from "../SourceEditorCallout";
import SourceTitleEditor from "../SourceTitleEditor";

const InboxSourceEditor = (props) => {
  const { source, sourceIndex } = props;
  const userInboxEmail = useInboxAddress();
  const { data: countRes } = useSWR(keyUserInboxMessagesCount);

  const showMessagesEmptyState = (() => {
    if (!countRes) return false;
    return countRes.count === 0;
  })();

  return (
    <EditorSubpage type={source.type} showAddAnother={false} sourceIndex={sourceIndex}>
      <SourceEditorSection
        icon="emailBold"
        title="Get newsletters in Mailbrew"
        subtitle={
          <>
            Subscribe to newsletters using your address below and receive them in this digest or{" "}
            <ExternalLink href={urls.inbox()} targetBlank>
              read them online
            </ExternalLink>
            .
          </>
        }
        noBorderTop
      >
        <UserInboxAddressCopyBoxWithButton width="100%" mt={2} stretch email={userInboxEmail} />
        <TitleDivider small title="or" my={2} />
        <InboxAdditionalInstructions style={{ zoom: 0.9 }} />
      </SourceEditorSection>

      {!showMessagesEmptyState && (
        <>
          {/* FILTERING */}
          <FilteringSection source={source} sourceIndex={sourceIndex} />
          {/* DISPLAY OPTIONS */}
          <DisplayOptionsSection source={source} sourceIndex={sourceIndex} />
        </>
      )}
    </EditorSubpage>
  );
};

const [ALL, FAVS, TAGS] = [1, 2, 3];
const sourceModes = [ALL, FAVS, TAGS];

const FilteringSection = ({ source, sourceIndex }) => {
  const dispatch = useDispatch();
  const { data: userTags = [], state: tagsSwrState } = useSWRWithState(keyUserInboxTaGs, { revalidateOnFocus: true });
  const { data: favCountResponse, state: favCountState } = useSWRWithState(keySenderOptionsFavCount, {
    revalidateOnFocus: true,
  });

  const [selectedFilter, setSelectedFilter] = useState(
    (() => {
      if (source.only_favorites) {
        return FAVS;
      }
      if (!!source.included_tags?.length || !!source.excluded_tags?.length) {
        return TAGS;
      }
      return ALL;
    })()
  );

  useEffect(() => {
    dispatch(updateSourceField(sourceIndex, "only_favorites", selectedFilter === FAVS));
    if (selectedFilter === ALL) {
      dispatch(updateSourceField(sourceIndex, "included_tags", []));
      dispatch(updateSourceField(sourceIndex, "excluded_tags", []));
    }
  }, [dispatch, selectedFilter, sourceIndex]);

  const userTagsById = groupBy("id", userTags);

  const appliedTags = [...(source.included_tags || []), ...(source.excluded_tags || [])];
  const suggestedTags = userTags.filter((tag) => !appliedTags.includes(tag.id));

  const includedTags = source.included_tags?.map((id) => userTagsById[id]).filter((t) => !!t) ?? [];
  const excludedTags = source.excluded_tags?.map((id) => userTagsById[id]).filter((t) => !!t) ?? [];

  const handleAddTag = (tagId, property) => {
    const currentTagIds = source[property] || [];
    const newTagIds = [...currentTagIds, Number(tagId)];
    dispatch(updateSourceField(sourceIndex, property, newTagIds));
  };

  const handleRemoveTag = (tagId, property) => {
    const newTags = source[property].filter((tag) => tag !== tagId);
    dispatch(updateSourceField(sourceIndex, property, newTags));
  };

  return (
    <SourceEditorSection
      title="Messages to include"
      subtitle="Filter the messages to include with favs and tags."
      icon="filterBold"
    >
      {(() => {
        if (tagsSwrState === "loading" || favCountState === "loading") {
          return null;
        }

        if (tagsSwrState === "error" || favCountState === "error") {
          return (
            <SourceEditorCallout
              icon="warning"
              title="There was an error loading your tags."
              body="Please reload the page to retry."
              mt={0}
              mb={0}
              color={"hsl(35, 100%, 30%)"}
            />
          );
        }

        if (tagsSwrState === "empty" && favCountResponse.count === 0) {
          return (
            <>
              <StyledLink icon="bookmark" w="100%" variant="white" to={urls.manageInbox()} mt={1}>
                Tag Newsletters
              </StyledLink>
            </>
          );
        }

        return (
          <>
            <SegmentedControl
              options={sourceModes}
              optionsNames={["All", "Favorites", "Tags"]}
              optionsIcons={["", "starBold", "search"]}
              active={selectedFilter}
              onOptionChange={setSelectedFilter}
            />
            <Expandable expanded={selectedFilter === FAVS} mt={4}>
              <Stack vertical w="100%" align="stretch" gap={4}>
                <StyledLink icon="bookmark" w="100%" variant="white" to={urls.manageInbox()} mt={1}>
                  Manage favorites
                </StyledLink>
              </Stack>
            </Expandable>

            <Expandable expanded={selectedFilter === TAGS} mt={4}>
              <Stack vertical w="100%" align="stretch" gap={4}>
                <TagsField icon="checkmark" title="Include Messages Tagged">
                  <TagEditor
                    placeholder="Include tags..."
                    className="react-tags include"
                    tags={includedTags}
                    suggestedTags={suggestedTags}
                    onAddTag={(tagID) => {
                      handleAddTag(tagID, "included_tags");
                    }}
                    onRemoveTag={(tagID) => {
                      handleRemoveTag(tagID, "included_tags");
                    }}
                  />
                </TagsField>
                <TagsField icon="delete" title="Exclude Messages Tagged">
                  <TagEditor
                    placeholder="Exclude tags..."
                    className="react-tags exclude"
                    tags={excludedTags}
                    suggestedTags={suggestedTags}
                    onAddTag={(tagID) => {
                      handleAddTag(tagID, "excluded_tags");
                    }}
                    onRemoveTag={(tagID) => {
                      handleRemoveTag(tagID, "excluded_tags");
                    }}
                  />
                </TagsField>
                <StyledLink icon="bookmark" w="100%" variant="white" to={urls.manageInbox()} mt={1}>
                  Manage tags
                </StyledLink>
              </Stack>
            </Expandable>
          </>
        );
      })()}
    </SourceEditorSection>
  );
};

const TagsField = ({ title, icon, children }) => {
  return (
    <Box>
      <HStack mb={2}>
        <Icon name={icon} />
        <P2>{title}</P2>
      </HStack>
      {children}
    </Box>
  );
};

const DisplayOptionsSection = ({ source, sourceIndex }) => {
  const config = useConfig();
  const dispatch = useDispatch();

  return (
    <SourceEditorSection icon="eye" title="Display options" collapsable>
      <PostBody
        label="Email Preview"
        selected={source.post_body || "excerpt"}
        options={["hidden", "excerpt"]}
        optionsNames={["Hidden", "Excerpt"]}
        onChange={(option) => dispatch(updateSourceField(sourceIndex, "post_body", option))}
      />
      <Divider my={4} />

      <HStack mb={2}>
        <Icon name="emailBold" />
        <P2 color={config.colors.c2}>Included Newsletters</P2>
      </HStack>
      <NumPostsSourceEditor
        source={source}
        sourceIndex={sourceIndex}
        min={1}
        max={30}
        defaultValue={10}
        fieldName="max_messages"
        noWrappingSection
        shortExplanationBuilder={(max_messages) =>
          max_messages > 1
            ? `You'll receive the ${max_messages} most recent newsletters in this digest.`
            : `You'll receive the most recent newsletter.`
        }
      />
      <Divider my={4} />

      <HStack mb={2}>
        <Icon name="writeBold" />
        <P1>Title</P1>
      </HStack>
      <SourceTitleEditor
        title={source.title}
        onTitleChange={(title) => dispatch(updateSourceField(sourceIndex, "title", title))}
      />
    </SourceEditorSection>
  );
};

const TagEditor = ({ tags, onAddTag, onRemoveTag, suggestedTags, placeholder, className }) => {
  const config = useConfig();

  const tagToReactTag = (tag) => ({ ...tag, name: tag.title });

  const tagsReactTags = tags.map(tagToReactTag);
  const suggestedReactTags = suggestedTags.map(tagToReactTag);

  async function handleAddTag(tag) {
    onAddTag(tag.id);
  }

  async function handleRemoveTag(tagIndex) {
    const tag = tags[tagIndex];
    if (!tag) return;
    onRemoveTag(tag.id);
  }

  return (
    <div
      style={{
        width: "100%",
        minHeight: config.Input.height,
        padding: "6px 9px 0px",
        color: config.Input.color,
        border: config.Input.borderWidth + " solid " + config.colors.uiBorderColor,
        background: config.Input.background,
        borderRadius: config.Input.radius,
        boxSizing: "border-box",
      }}
      className={className}
    >
      <ReactTags
        placeholderText={placeholder}
        tags={tagsReactTags}
        suggestions={suggestedReactTags}
        onAddition={handleAddTag}
        onDelete={handleRemoveTag}
      />
    </div>
  );
};

export const CopyWrapper = ({ copyText, children, track, ...rest }) => {
  const config = useConfig();

  const [copied, setCopied] = useState(false);
  const timeout = useRef(null);

  useEffect(() => {
    // cleanup
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const handleCopy = () => {
    plausible.track(track);
    setCopied(true);
    timeout.current = setTimeout(() => setCopied(false), 2000);
  };

  return (
    <CopyToClipboard text={copyText}>
      <Box onClick={handleCopy} style={{ cursor: "pointer" }} {...rest}>
        <HStack align="center">
          {copied && <Icon name="checkmark" size="1rem" color={config.colors.c3} />}
          <P2 fontSize="13px" align="center" color={config.colors.c3} mb={1}>
            {copied ? "Copied" : "Click to copy"}
          </P2>
        </HStack>
        {children}
      </Box>
    </CopyToClipboard>
  );
};

export const UserInboxAddressCopyBox = ({ email }) => {
  return (
    <CopyWrapper copyText={email} mt={1}>
      <HStack align="center">
        <StyledUserInboxAddress email={email} stretch />
      </HStack>
    </CopyWrapper>
  );
};

export function notifySourcePreviewForReload(sourceIndex) {
  const reloadSourceEvent = new CustomEvent("mailbrew:reload_source", { detail: { sourceIndex } });
  window.dispatchEvent(reloadSourceEvent);
}

export default InboxSourceEditor;
