import { useState } from "react";
import { Input } from "@mailbrew/uikit";

const SearchField = (props) => {
  const {
    initialValue = "",
    placeholder,
    fetchSuggestions,
    onSuggestionClick,
    autofillSuggestionClick = "value", // name, empty
    width = "100%",
    autoFocus,
    autoCorrect,
    autoCapitalize,
    spellCheck,
  } = props;

  const [typedValue, setTypedValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsNames, setSuggestionsNames] = useState([]);
  const [suggestionsDetails, setSuggestionsDetails] = useState([]);
  const [suggestionsIcons, setSuggestionsIcons] = useState([]);
  const [suggestionsImages, setSuggestionsImages] = useState([]);
  const [suggestionsPayloads, setSuggestionsPayloads] = useState([]);

  const [error, setError] = useState(null);

  return (
    <Input
      autoFocus={autoFocus}
      width={width}
      value={typedValue}
      onChange={(e) => {
        setTypedValue(e.target.value);
        fetchSuggestions(e.target.value, {
          setSuggestions,
          setSuggestionsDetails,
          setSuggestionsNames,
          setSuggestionsIcons,
          setSuggestionsImages,
          setSuggestionsPayloads,
          setError,
          setLoading,
        });
      }}
      type="text"
      autoComplete="off"
      autoCorrect={autoCorrect}
      autoCapitalize={autoCapitalize}
      spellCheck={spellCheck}
      icon="search"
      iconSide="left"
      placeholder={placeholder}
      error={error}
      // autoFocus={autoFocus}
      suggestions={suggestions}
      suggestionsNames={suggestionsNames}
      suggestionsDetails={suggestionsDetails}
      suggestionsIcons={suggestionsIcons}
      suggestionsIconsSize="2em"
      suggestionsImagesSize="2em"
      suggestionsImages={suggestionsImages}
      onSuggestionClick={(sugg, index) => {
        const suggName = suggestionsNames[index];
        const suggPayload = suggestionsPayloads[index];

        if (autofillSuggestionClick === "value") {
          setTypedValue(sugg);
        } else if (autofillSuggestionClick === "name") {
          setTypedValue(suggName);
        } else if (autofillSuggestionClick === "empty") {
          setTypedValue("");
        }

        onSuggestionClick && onSuggestionClick(sugg, suggName, suggPayload);
      }}
      loading={loading}
    />
  );
};

export default SearchField;
