import ImagesStyleSetting from "components/ImagesStyleSetting";
import debounce from "just-debounce-it";
import { connect } from "react-redux";
import api from "dependencies/api";
import useInputValidator from "hooks/useInputValidator";
import useRandomItem from "hooks/useRandomItem";
import { updateSourceField } from "reducers/newslettersReducer";
import kFormatter from "utils/kFormatter";
import { handleNetworkError } from "utils/networkErrors";
import numberValidator from "utils/numberValidator";
import EditorSubpage from "components/editor/EditorSubpage";
import SearchField from "components/editor/SearchField";
import SourceEditorSection from "components/editor/SourceEditorSection";
import { colorPickerPalette } from "components/editor/ColorPicker";
import LayoutOptions from "components/editor/LayoutOptions";
import PostBody from "components/editor/PostBody";
import QuoteMode from "components/editor/QuoteMode";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";

const minPosts = 1;
const maxPosts = 15;

const SubredditSourceEditor = (props) => {
  const { source, sourceIndex, dispatch } = props;

  const [numPosts] = useInputValidator(source.num_posts, numberValidator(minPosts, maxPosts), (value) =>
    dispatch(updateSourceField(sourceIndex, "num_posts", parseInt(value)))
  );

  const placeholder = useRandomItem([
    "NoNoNoNoYes",
    "AskReddit",
    "HoldMyBeer",
    "Aww",
    "TodayILearned",
    "Facepalm",
    "CrappyDesign",
    "MildlyVandalized",
  ]);

  return (
    <EditorSubpage
      title={source.subreddit ? `r/${source.subreddit}` : "Subreddit"}
      type={source.type}
      sourceIndex={sourceIndex}
    >
      <SourceEditorSection noBorderTop title="Subreddit" icon="reddit">
        <SearchField
          initialValue={source.subreddit}
          placeholder={placeholder}
          autoFocus={!(source.subreddit && source.subreddit.length > 0)}
          fetchSuggestions={fetchRedditSuggestions}
          onSuggestionClick={(suggestion) => {
            dispatch(updateSourceField(sourceIndex, "subreddit", suggestion));
          }}
        />
      </SourceEditorSection>

      {/* NUMBER OF POSTS */}
      <NumPostsSourceEditor
        source={source}
        sourceIndex={sourceIndex}
        min={1}
        max={10}
        fieldName="num_posts"
        explanationModalBody={`We'll take the ${numPosts} most popular posts since the last issue of your digest.`}
        explanationModalIcon="arrowUpBold"
        shortExplanationBuilder={(numPosts) =>
          numPosts > 1 ? `Receive the ${numPosts} most popular posts.` : `Receive the most popular post.`
        }
      />

      <SourceEditorSection>
        <ImagesStyleSetting
          disabled={source.quote_mode}
          style={source.images_style}
          onChange={(style) => dispatch(updateSourceField(sourceIndex, "images_style", style))}
          pb={0}
          pt={0}
        />
        <PostBody
          disabled={source.quote_mode}
          selected={source.post_body || "excerpt"}
          onChange={(option) => dispatch(updateSourceField(sourceIndex, "post_body", option))}
          pb={0}
        />
        <LayoutOptions
          selected={source.columns || 1}
          onChange={(option) => dispatch(updateSourceField(sourceIndex, "columns", option))}
        />
      </SourceEditorSection>

      <QuoteMode
        enabled={source.quote_mode}
        onEnabledChange={() => {
          const enabled = !source.quote_mode;

          dispatch(updateSourceField(sourceIndex, "quote_mode", !source.quote_mode));

          // set default color  and text option when first enabling the quote option
          if (enabled && !source.quote_color)
            dispatch(updateSourceField(sourceIndex, "quote_color", colorPickerPalette.quote[0]));
          if (enabled && !source.quote_text_option)
            dispatch(updateSourceField(sourceIndex, "quote_text_option", "title"));
        }}
        color={source.quote_color}
        onColorChange={(color) => dispatch(updateSourceField(sourceIndex, "quote_color", color))}
        quoteTextOption={source.quote_text_option}
        onQuoteTextOptionChange={(opt) => dispatch(updateSourceField(sourceIndex, "quote_text_option", opt))}
      />
    </EditorSubpage>
  );
};

export const fetchRedditSuggestions = debounce(
  async (
    query,
    { setSuggestions, setSuggestionsNames, setSuggestionsDetails, setSuggestionsImages, setLoading, setError }
  ) => {
    if (!query || query.length <= 2) {
      setSuggestions([]);
      setError(null);
      return;
    }

    setLoading(true);

    try {
      setError("");
      const res = await api.get("/subreddit_search/", {
        params: { subreddit: query },
      });
      setSuggestions(res.data.map((s) => s.name));
      setSuggestionsNames(res.data.map((s) => s.name));
      setSuggestionsImages(res.data.map((s) => s.icon));
      // prettier-ignore
      setSuggestionsDetails(res.data.map(s => `${kFormatter(s.subscribers, 0)} subscribers - ${s.description}`));
    } catch (err) {
      const handled = handleNetworkError(err);
      if (handled) return;
      const errorMessage = err.response.data.detail || "Can't search Reddit right now.";
      setSuggestions([]);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  },
  300
);

export default connect((state) => ({
  newsletter: state.newsletters.currentNewsletter,
}))(SubredditSourceEditor);
