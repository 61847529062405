import { Input, P2, SegmentedControl, useConfig } from "@mailbrew/uikit";
import ConnectTwitterAccountSection from "components/ConnectTwitterAccountSection";
import ImagesStyleSetting from "components/ImagesStyleSetting";
import MaxLinksSection from "components/MaxLinksSection";
import SharesStyleSetting from "components/SharesStyleSetting";
import TwitterListEditorSection from "components/TwitterListEditorSection";
import { useEffect } from "react";
import { Disable } from "react-disable";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import { authUserSelector } from "reducers/authReducer";
import { updateSourceField } from "reducers/newslettersReducer";
import ConnectedTwitterAccount from "components/editor/ConnectedTwitterAccount";
import EditorSubpage from "components/editor/EditorSubpage";
import SettingSwitchRow from "components/editor/SettingSwitchRow";
import SourceEditorSection from "components/editor/SourceEditorSection";

const minLinks = 1;
const maxLinksLimit = 15;

const TwitterListSourceEditor = (props) => {
  const { source, sourceIndex } = props;
  const config = useConfig();

  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  const twitterAccount = user?.connected_accounts?.find((acc) => acc.type === "twitter");
  const twitterAccountConnected = twitterAccount && !twitterAccount.error;

  const { data: lists, error: listsApiError } = useSWR("twitter_lists/");

  useEffect(() => {
    const list = lists?.find((l) => l.id === source.list_id);
    if (list) {
      dispatch(updateSourceField(sourceIndex, "ft_list_title", list.name));
    }
  }, [dispatch, lists, source.list_id, sourceIndex]);

  const onSelectTwitterList = (listId) => {
    const list = lists.find((l) => l.id === listId);
    dispatch(updateSourceField(sourceIndex, "list_id", list.id));
  };

  const onSwitchHomeListLinks = (type) => {
    dispatch(updateSourceField(sourceIndex, "use_home_timeline", type === "timeline"));
  };

  return (
    <EditorSubpage title={source.title} type={source.type} sourceIndex={sourceIndex} sourceIsValid>
      {!twitterAccountConnected && (
        <SourceEditorSection icon="twitter" title="Connected Twitter Account" noBorderTop>
          <ConnectedTwitterAccount />
        </SourceEditorSection>
      )}
      <Disable disabled={!(twitterAccount && !twitterAccount.error)}>
        <SourceEditorSection noBorderTop>
          <P2 color={config.colors.c2} mb={2}>
            Whose tweets should be check?
          </P2>
          <SegmentedControl
            options={["timeline", "list"]}
            optionsNames={["My follows", "From a list"]}
            active={source.use_home_timeline ? "timeline" : "list"}
            onOptionChange={onSwitchHomeListLinks}
            backdropStyle={{
              ...config.SegmentedControl.backdropStyle,
              background: config.colors.twitter,
            }}
            mb={source.use_home_timeline ? 0 : 3}
          />
          {!source.use_home_timeline && (
            <TwitterListEditorSection
              source={source}
              lists={lists}
              error={listsApiError}
              onSelectTwitterList={onSelectTwitterList}
            />
          )}
        </SourceEditorSection>

        {/* TITLE */}
        <SourceEditorSection title="Title" icon="writeBold">
          <Input
            placeholder="Title"
            type="text"
            autoComplete="off"
            value={source.title}
            onChange={(e) => dispatch(updateSourceField(sourceIndex, "title", e.target.value))}
          />
        </SourceEditorSection>

        {/* MAX NUMBER OF LINKS */}
        <MaxLinksSection source={source} sourceIndex={sourceIndex} min={minLinks} max={maxLinksLimit}>
          <SettingSwitchRow
            state={source.min_shares_to_include === 1}
            onChange={() =>
              dispatch(
                updateSourceField(sourceIndex, "min_shares_to_include", source.min_shares_to_include === 1 ? 2 : 1)
              )
            }
            copy="Include links with only one share"
            tooltip="If you see too much noise you can try disabling this to remove links shared only by one user."
            color={config.colors.c3}
            mt={3}
          />
        </MaxLinksSection>

        <SourceEditorSection>
          <ImagesStyleSetting
            allowWideThumbnails
            style={source.images_style}
            onChange={(style) => dispatch(updateSourceField(sourceIndex, "images_style", style))}
            pb={0}
            pt={0}
          />
          <SharesStyleSetting source={source} sourceIndex={sourceIndex} />
        </SourceEditorSection>
      </Disable>

      {twitterAccountConnected && <ConnectTwitterAccountSection noBorderBottom />}
    </EditorSubpage>
  );
};

export default TwitterListSourceEditor;
