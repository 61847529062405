import { Input, P2 } from "@mailbrew/uikit";
import EditorSubpage from "components/editor/EditorSubpage";
import SourceEditorSection from "components/editor/SourceEditorSection";
import ExternalLink from "components/ExternalLink";
import { PillButton } from "components/PillButton";
import useInputValidator from "hooks/useInputValidator";
import { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import { currentNewsletterSelector, updateSourceField } from "reducers/newslettersReducer";
import supportLinks from "supportLinks";
import { useDebouncedCallback } from "use-debounce";
import createShareURL from "utils/createShareURL";
import createTwitterLink from "utils/createTwitterLink";
import * as Yup from "yup";

const TextSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const user = useSelector(authUserSelector);
  const dispatch = useDispatch();
  const newsletter = useSelector(currentNewsletterSelector);

  const textAreaRef = useRef();

  const [debouncedUpdateText] = useDebouncedCallback(
    (text) => dispatch(updateSourceField(sourceIndex, "text", text)),
    1000,
    { leading: false }
  );

  const [text, setText, textError] = useInputValidator(
    source.text,
    Yup.string().max(1500, "Please enter a shorter text."),
    (text) => debouncedUpdateText(text)
  );

  /* -------------------------------- Shortcuts ------------------------------- */

  const title = newsletter.title ? newsletter.title : "this digest";
  const tweetText = `Check out ${title}, made with @mailbrew.

Subscribe 👇`;
  const shareUrl = useMemo(() => createShareURL(newsletter), [newsletter]);
  const twitterLink = useMemo(() => createTwitterLink({ text: tweetText, url: shareUrl }), [shareUrl, tweetText]);
  const affiliateLink = user?.is_affiliate && user?.affiliate_link;

  const shortcuts = [
    {
      title: "Tweet brew",
      icon: "twitter",
      text: `[Tweet about it](${twitterLink})`,
    },
    {
      title: affiliateLink ? "Affiliate Link" : "Powered by",
      icon: "lightning",
      text: `⚡️ Powered by [Mailbrew](${affiliateLink ? affiliateLink : "https://mailbrew.com"})`,
    },
    {
      title: "Section",
      icon: "writeBold",
      text: `## 🍕 This is a section\n\n---`,
    },
    {
      title: "Link",
      icon: "link",
      text: `[This is a link](https://mailbrew.com)`,
    },
    {
      title: "Bold",
      icon: "bold",
      text: `**bold text**`,
    },
    {
      title: "Divider",
      icon: "minus",
      text: `\n---\n\n`,
    },
    {
      title: "Image",
      icon: "pictureBold",
      text: `![](https://mailbrew.com/card.png)`,
    },
    {
      title: "List",
      icon: "list",
      text: `\n* First item\n* Another item\n* Last item`,
    },
  ];

  function addShortcut(shortcut) {
    setText(text + `\n` + shortcut);
    if (textAreaRef.current) textAreaRef.current.focus();
  }

  return (
    <EditorSubpage type={source.type} sourceIndex={sourceIndex}>
      <SourceEditorSection
        title="Content"
        subtitle="You can use this source to put reminders, links, or anything else you'd like to include in your brew."
        icon="writeBold"
        noBorderTop
      >
        <Input
          component="textarea"
          name="text"
          autoComplete="off"
          type="text"
          placeholder="Write anything here... We support **Markdown**."
          value={text}
          onChange={(e) => setText(e.target.value)}
          error={textError}
          style={{ width: "100%", lineHeight: 1.3 }}
          height="10em"
          ref={textAreaRef}
        />
        <P2 mt={2}>
          Use <ExternalLink href={supportLinks.markdown}>Markdown syntax</ExternalLink> to format this text.
        </P2>
      </SourceEditorSection>
      <SourceEditorSection title="Shortcuts" icon="hand">
        {shortcuts.map((s) => (
          <PillButton mr="5px" mb="6px" icon={s.icon} onClick={() => addShortcut(s.text)}>
            {s.title}
          </PillButton>
        ))}
      </SourceEditorSection>
    </EditorSubpage>
  );
};

export default TextSourceEditor;
