import { Box, Card, SafeAreaInsetBottom, useBreakpoint, useConfig } from "@mailbrew/uikit";
import useDarkModeListener from "hooks/useDarkModeListener";
import { forwardRef } from "react";
import { TAB_BAR_BREAKPOINT } from "./Page";

export const FullWidthCard = forwardRef((props, ref) => {
  const { children, noCard, fullWidth = true, noPadding, style, ...otherProps } = props;
  const config = useConfig();

  const cardStyle = {
    ...(fullWidth ? fullWidthCardStyle(config, noPadding) : {}),
    style,
  };

  if (noCard) {
    return (
      <Box ref={ref} style={style} {...otherProps}>
        {children}
      </Box>
    );
  } else {
    return (
      <Card className="fullwidth-card card" ref={ref} style={cardStyle} {...otherProps}>
        {children}
      </Card>
    );
  }
});

export const FullWidthCardFooter = ({ children, fullWidth = true, style, expanded, ...otherProps }) => {
  const { isDark } = useDarkModeListener();
  const config = useConfig();
  const tabBar = useBreakpoint(TAB_BAR_BREAKPOINT);

  const cardPadding = parseInt(config.Card.bigPadding);
  const lateralPadding = 12;

  return (
    <Box
      background={
        isDark
          ? config.Card.background
          : `linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,${expanded ? "0.5" : "0.95"}) 70%)`
      }
      className="fullwidth-card-footer"
      style={{
        position: expanded ? "sticky" : "absolute",
        bottom: expanded && tabBar ? `calc( ${config.TabBar.height} - 1px)` : 0,
        marginLeft: expanded ? -cardPadding : 0,
        marginBottom: expanded ? "-12px" : 0,
        marginRight: 0,
        width: expanded ? `calc( 100% + ${cardPadding * 2}px)` : "100%",
        left: 0,
        borderRadius: expanded ? 0 : `0 0 ${config.Card.bigRadius} ${config.Card.bigRadius}`,
        paddingTop: isDark ? "6px" : "40px",
        paddingBottom: "6px",
        paddingLeft: lateralPadding,
        paddingRight: lateralPadding,
        marginTop: 0,
      }}
      {...otherProps}
    >
      {children}
      {expanded && <SafeAreaInsetBottom />}
    </Box>
  );
};

const fullWidthCardStyle = (config, noPadding) => ({
  marginLeft: config.layout.padding,
  marginRight: config.layout.padding,
  paddingLeft: noPadding ? 0 : config.Card.bigPadding,
  paddingRight: noPadding ? 0 : config.Card.bigPadding,
});
