import { Box } from "@mailbrew/uikit";
import SettingSwitchRow from "components/editor/SettingSwitchRow";

const ImagesShow = (props) => {
  const { state, onChange, disabled, ...rest } = props;

  return (
    <Box pt={3} pb={3} {...rest}>
      <SettingSwitchRow state={state} onChange={onChange} icon="pictureBold" copy="Show Images" />
    </Box>
  );
};

export default ImagesShow;
