import { Button, Icon, P2, SliderWithInput } from "@mailbrew/uikit";
import useInputValidator from "hooks/useInputValidator";
import { Fragment } from "react";
import { pluralize } from "utils/pluralize";
import { usePaywallState } from "./PaywallStateProvider";

export default function MonetizationAwareSliderWithInput({
  min,
  max: providedMax,
  value: providedValue,
  onChange,
  postName = "post",
}) {
  const { monetization, setPaywallModalShown } = usePaywallState();
  const monetizationMaxItems = monetization?.data.features.max_items_per_source;

  const [value, setValue, valueError] = useInputValidator(
    providedValue,
    (value) => {
      const number = parseInt(value);

      if (isNaN(number)) {
        return "Please insert a number";
      }

      if (number > monetizationMaxItems) {
        return `__upgrade__`;
      }

      if (!(min <= number && number <= providedMax)) {
        return `Must be between ${min} and ${providedMax}`;
      }

      return null;
    },
    (value) => onChange(value)
  );

  const isUpgradeError = valueError === "__upgrade__";

  return (
    <Fragment>
      <SliderWithInput
        min={min}
        max={providedMax}
        value={value}
        onChange={setValue}
        error={!isUpgradeError && valueError}
      />
      {isUpgradeError && (
        <P2>
          <Icon name="warningBold" currentColor size="1em" /> You must{" "}
          <Button variant={["link"]} onClick={() => setPaywallModalShown(true)}>
            upgrade
          </Button>{" "}
          to show more than {monetizationMaxItems} {pluralize(postName, monetizationMaxItems)}.
        </P2>
      )}
    </Fragment>
  );
}
