import {
  Button,
  Grid,
  Input,
  Modal,
  notif,
  NotifWithButton,
  P1,
  Section,
  Stack,
  useBreakpoint,
  useConfig,
} from "@mailbrew/uikit";
import CardsAutoGrid from "components/CardsAutoGrid";
import EmptyStateCard from "components/EmptyStateCard";
import InboxMessageModal from "components/InboxMessageModal";
import { LoadMore } from "components/LoadMore";
import Page from "components/Page";
import { usePaywallState } from "components/PaywallStateProvider";
import { PillButton } from "components/PillButton";
import { ReaderViewModal } from "components/ReaderViewModal";
import { ReadLaterInstallInstructions } from "components/ReadLaterInstallInstructions";
import RecencySections from "components/RecencySections";
import SavedItem from "components/SavedItem";
import SmallActionButton from "components/SmallActionButton";
import api from "dependencies/api";
import loggedInPage from "hoc/loggedInPage";
import useKeyPressed from "hooks/useKeyPressed";
import useSWRInfiniteHelper from "hooks/useSWRInfiniteHelper";
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSWRInfinite } from "swr";
import urls from "urls";
import confirmPromise from "utils/confirmPromise";

const SavedPage = ({ showArchived = false }) => {
  const config = useConfig();
  const router = useRouter();
  const breakpointHit = useBreakpoint();

  const [showInstructions, setShowInstructions] = useState(false);
  const [readerModeShown, setReaderModeShown] = useState(false);
  const [inboxModalShown, setInboxModalShown] = useState(false);

  const [openSavedItemId, setOpenSavedItemId] = useState(null);
  const [readerModeItem, setReaderModeItem] = useState(null);
  const [inboxMessageItem, setInboxMessageItem] = useState(null);

  const { data, error, size, setSize, mutate } = useSWRInfinite(getPageKey(showArchived), {
    refreshInterval: 4 * 3600 * 1000,
    revalidateOnFocus: true,
    // fetch *all* the pages revalidate, necessary for refreshInterval & revalidateOnFocus to work
    revalidateAll: true,
  });
  const { isEmpty: _isEmpty, loadMore, isLoadingMore, canLoadMore } = useSWRInfiniteHelper({
    data,
    error,
    size,
    setSize,
  });

  const { savedEmpty } = usePaywallState();

  const isEmpty = savedEmpty ?? _isEmpty;

  const readLaterItems = data ? [].concat(...data.map((d) => d.results)) : [];

  const externalUrl = router.query.url;

  useEffect(() => {
    if (externalUrl && externalUrl.length > 0) {
      saveReadLaterUrl(externalUrl, mutate);
    }
  }, [externalUrl, mutate]);

  // removes the deleted/archived item from the local SWR cache
  const handleMutateForDeleteOrArchive = (itemId) => (cachedData) => {
    return cachedData.map((page) => ({ ...page, results: page.results.filter((item) => item.id !== itemId) }));
  };

  async function handleDeleteItem(itemId, mutate, { askConfirmation = true }) {
    const confirmed = askConfirmation ? await confirmPromise("Are you sure you want to delete this item?") : true;
    if (confirmed) {
      mutate(handleMutateForDeleteOrArchive(itemId), false);
      await api.delete(`/read_later/${itemId}/`);
    }
  }

  async function handleArchiveItem(itemId, mutate, { archived = true }) {
    mutate(handleMutateForDeleteOrArchive(itemId), false);
    await api.patch(`/read_later/${itemId}/`, { archived });

    notif.show((t) => (
      <NotifWithButton
        msg={`${archived ? "Archived" : "Restored"}`}
        action="Undo"
        actionIcon="undo"
        onClick={async () => {
          notif.dismiss(t.id);
          await api.patch(`/read_later/${itemId}/?undo=true`, { archived: !archived });
          mutate();
        }}
      />
    ));
  }

  function handleArchiveNavigationClick() {
    if (showArchived) {
      router.push(urls.saved());
    } else {
      router.push(urls.savedArchive());
    }
  }

  return (
    <PagePrivate
      leftHeaderComponents={
        <Stack vAlign="center" gap={2}>
          <AddLinkManually />
          {!isEmpty && (
            <SmallActionButton size={26} icon="questionAlt" onClick={() => setShowInstructions(!showInstructions)} />
          )}
          <SmallActionButton
            size={26}
            icon={showArchived ? "archiveBoldOpen" : "archiveBold"}
            color={showArchived && config.colors.accent1}
            onClick={handleArchiveNavigationClick}
          />
        </Stack>
      }
    >
      {isEmpty && !showArchived && (
        <Fragment>
          <Section first center pt={2} pb={0}></Section>
          <Section center pt={0}>
            <EmptyStateCard w="600px" glyph="later" title={"Save links for later with Mailbrew"}>
              <ReadLaterInstallInstructions />
            </EmptyStateCard>
          </Section>
        </Fragment>
      )}
      {isEmpty && showArchived && (
        <Fragment>
          <Section first center pt={2} pb={0}></Section>
          <Section center>
            <P1 align="center" mb={3}>
              You have no archived items.
            </P1>
            <Button icon="bookmark" variant="white" onClick={handleArchiveNavigationClick}>
              Show All
            </Button>
          </Section>
        </Fragment>
      )}
      {!isEmpty && (
        <Fragment>
          <Section>
            <RecencySections
              items={readLaterItems}
              dateKey="date_saved"
              showTitlesImmediately
              itemsWrapperComponent={CardsAutoGrid}
              renderItem={({ item, itemIndex }) => {
                return (
                  <SavedItem
                    key={item.id}
                    index={itemIndex}
                    item={item}
                    isArchived={showArchived}
                    onArchiveClick={() => handleArchiveItem(item.id, mutate, { archived: !showArchived })}
                    onDeleteClick={(opts) => handleDeleteItem(item.id, mutate, opts ?? {})}
                    onReaderModeClick={(isNewsletter, newsletterItem) => {
                      setOpenSavedItemId(item.id);
                      if (isNewsletter) {
                        setInboxMessageItem(newsletterItem);
                        setInboxModalShown(true);
                      } else {
                        setReaderModeItem(item);
                        setReaderModeShown(true);
                      }
                    }}
                  />
                );
              }}
            />
            <LoadMore isLoadingMore={isLoadingMore} canLoadMore={canLoadMore} loadMore={loadMore} />
          </Section>
          <Modal bottomSheet={breakpointHit} show={showInstructions} setShow={setShowInstructions}>
            <ReadLaterInstallInstructions header={false} />
          </Modal>
          <ReaderViewModal
            show={readerModeShown}
            setShow={setReaderModeShown}
            url={readerModeItem?.url}
            source="Saved"
            hideReadLater
            withArchive
            onArchiveClick={() => handleArchiveItem(openSavedItemId, mutate, { archived: !showArchived })}
          />
          <InboxMessageModal
            key={inboxMessageItem?.message_id}
            id={inboxMessageItem?.message_id}
            show={inboxModalShown}
            setShow={setInboxModalShown}
            withArchive
            onArchiveClick={() => handleArchiveItem(openSavedItemId, mutate, { archived: !showArchived })}
            hideReadLater
            src="saved-page"
          />
        </Fragment>
      )}
    </PagePrivate>
  );
};

const AddLinkManually = ({ externalUrl }) => {
  const [modalShown, setModalShown] = useState(false);
  return (
    <Fragment>
      <PillButton icon="plus" onClick={() => setModalShown(true)}>
        Save
      </PillButton>
      <SaveLinkModal show={modalShown} setShow={setModalShown} externalUrl={externalUrl} />
    </Fragment>
  );
};

export const SaveLinkModal = ({ show, setShow, externalUrl }) => {
  const [url, setUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { mutate } = useSWRInfinite(show && getPageKey(false));

  useKeyPressed("Escape", () => {
    setShow(false);
  });

  const handleAddURL = async (url) => {
    if (loading) return;

    setErrorMessage(null);
    let error = false;

    try {
      setLoading(true);
      await api.post("/add_read_later_item_via_url/?sync=true", { url });
      setShow(false);
      mutate();
    } catch (err) {
      error = true;
      const message = err?.response?.data?.detail;
      setErrorMessage(message ?? "Ooops, there was an error fetching your link. Please retry.");
    } finally {
      setLoading(false);
      if (!error) setShow(false);
    }
  };

  useEffect(() => {
    if (!show) setUrl("");
  }, [show]);

  useEffect(() => {
    if (externalUrl) {
      handleAddURL(externalUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalUrl]);

  const inputRef = useRef(null);

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => inputRef.current?.focus(), 50);
      return () => clearTimeout(timeout);
    }
  }, [show]);

  return (
    <Modal show={show} setShow={setShow}>
      <P1 mb={3}>Paste a URL here to save it for later:</P1>
      <Grid columns="1fr auto" gap={1} vAlign="center">
        <Input
          ref={inputRef}
          placeholder="https://..."
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          onSubmit={() => handleAddURL(url)}
          onKeyUp={(e) => e.key === "Escape" && setShow(false)}
        />
        <Button icon="plus" loading={loading} onClick={() => handleAddURL(url)}>
          Save
        </Button>
      </Grid>
      {errorMessage && (
        <P1 mt={2} color={(c) => c.colors.error}>
          {errorMessage}
        </P1>
      )}
    </Modal>
  );
};

const saveReadLaterUrl = async (url, mutate) => {
  try {
    await api.post("/add_read_later_item_via_url/?sync=true", { url });
  } catch (err) {
  } finally {
    mutate();
  }
};

const PagePrivate = ({ children, leftHeaderComponents }) => (
  <Page fullTitle="Saved" showMonetizationBanner leftHeaderComponents={leftHeaderComponents} showTimedOffer>
    {children}
  </Page>
);

const getPageKey = (showArchived) => (page, previousPageData) =>
  page === 0 ? `/read_later/?archived=${showArchived ? "true" : "false"}&size=50` : previousPageData.next;

export default loggedInPage(SavedPage);
