/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";
import { AutoGrid, Card, Checkbox, HStack, Icon, P1, P2, Spacer, Stack, useConfig, VStack } from "@mailbrew/uikit";
import ConnectTwitterAccountButton from "components/editor/ConnectTwitterAccountButton";
import SearchField from "components/editor/SearchField";
import { fetchSuggestions } from "components/editor/sources/TwitterUserSourceEditor";
import useShowNotificationForApiError from "hooks/useShowNotificationForApiError";
import { Fragment, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { userTwitterAccountSelector } from "reducers/authReducer";
import supportLinks from "supportLinks";
import useSWR from "swr";
import urls from "urls";
import { range } from "utils/array";
import { v4 as uuidv4 } from "uuid";
import ExternalLink from "./ExternalLink";
import SmallActionButton from "./SmallActionButton";

const TwitterAccountsSelector = (props) => {
  const { children, scrollable, height, isReadWriteTokenRequired = false } = props;

  const twitterAccount = useSelector(userTwitterAccountSelector);
  const needsToConnectAccount =
    // account not connected or in error
    (!twitterAccount && !twitterAccount?.error) ||
    // account connected but wrong token type
    (twitterAccount && isReadWriteTokenRequired && ![2, 4].includes(twitterAccount.token_type));

  const [manuallyAddedAccounts, setManuallyAddedAccounts] = useState([]);
  const [selectedAccountsIds, setSelectedAccountsIds] = useState([]);

  const { data: fetchedAccounts, error: fetchedAccountsError } = useSWR(() =>
    twitterAccount && !twitterAccount?.error ? "/twitter_suggested_brew_accounts/" : null
  );

  const allAccounts = useMemo(() => {
    return fetchedAccounts ? manuallyAddedAccounts.concat(fetchedAccounts) : [];
  }, [fetchedAccounts, manuallyAddedAccounts]);

  const loading = !fetchedAccounts && !fetchedAccountsError;

  useShowNotificationForApiError(fetchedAccountsError, "Can't fetch Twitter suggestions right now");

  const sources = useMemo(
    () =>
      selectedAccountsIds
        .map((id) => allAccounts.find((account) => account?.id === id))
        .map((account) => ({
          id: uuidv4(),
          type: "twitter_user",
          screen_name: account.screen_name,
          max_tweets: numberOfTweets(selectedAccountsIds.length),
          mode: "most_popular",
          include_rt: false,
          images_style: "full",
        })),
    [allAccounts, selectedAccountsIds]
  );

  const config = useConfig();

  const wrapperStyle = {
    flex: height ? "none" : "1 1 20em",
    height: height,
    minHeight: "15em",
    overflow: "scroll",
    width: "calc(100% - 1em)",
    padding: "0.5rem",
    borderRadius: config.appearance.smallRadius,
  };

  const isEmpty = !loading && !allAccounts?.length;

  if (needsToConnectAccount) {
    return (
      <Fragment>
        <div
          style={
            scrollable
              ? {
                  ...wrapperStyle,
                  background: config.colors.bg3,
                  border: `1px solid ${config.colors.c6}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }
              : { width: "100%" }
          }
        >
          <VStack align="center">
            <ConnectTwitterAccountButton />
            <P2 align="center">
              Connect Twitter to use this feature{" "}
              <Icon
                offset="-3px"
                size={18}
                name="questionBold"
                color={config.colors.c4}
                onClick={() => window.open(supportLinks.twitterPermissions)}
              />
            </P2>
          </VStack>
        </div>
        {children && children(sources)}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <HStack align={"center"} width="100%" noWrap>
        <SearchField
          width="18em"
          autofillSuggestionClick="empty"
          initialValue={""}
          placeholder="Search @handle or Name"
          fetchSuggestions={fetchSuggestions}
          onSuggestionClick={(suggestion, index, payload) => {
            if (!allAccounts.find((account) => account.id === payload.id)) {
              setManuallyAddedAccounts([...manuallyAddedAccounts, payload]);
              setSelectedAccountsIds([...selectedAccountsIds, payload.id]);
            } else {
              setSelectedAccountsIds([...selectedAccountsIds, payload.id]);
            }
          }}
        />
      </HStack>
      <Spacer />
      <div
        style={{
          display: isEmpty ? "flex" : "block",
          width: "100%",
          ...(scrollable
            ? {
                ...wrapperStyle,
                background: "rgba(0,0,0,.05)",
                border: `1px solid ${config.colors.uiBorderColor}`,
              }
            : {}),
        }}
      >
        {loading && (
          <AutoGrid cellWidth="14em" gap=".4rem">
            {range(51).map((_, i) => (
              <TwitterAccountLoadingCard key={i} />
            ))}
          </AutoGrid>
        )}
        {!loading &&
          (allAccounts?.length ? (
            <AutoGrid cellWidth="14em" gap=".4rem" width="100%">
              {allAccounts.map((account, i) => {
                return (
                  <TwitterAccountCard
                    key={i}
                    account={account}
                    selectedAccountsIds={selectedAccountsIds}
                    setSelectedAccountsIds={setSelectedAccountsIds}
                  />
                );
              })}
            </AutoGrid>
          ) : (
            <Stack align="center" style={{ flex: 1 }}>
              <P2 mt={4} align="center">
                We can't find any suggestions for you, use the search field above to start adding accounts.
              </P2>
            </Stack>
          ))}
      </div>
      {children && children(sources)}
    </Fragment>
  );
};

const avatarStyle = {
  width: "38px",
  height: "38px",
  borderRadius: "38px",
  flex: "0 0 38px",
};

const cardStyle = (config) => ({
  borderRadius: config.appearance.smallRadius,
  padding: "0.4rem 0.1rem 0.4rem 0.5rem",
});

const TwitterAccountLoadingCard = () => {
  const config = useConfig();
  const loadingAni = keyframes`
    25% { opacity: .4 }
    74% { opacity: .8 }
  `;
  return (
    <Card
      inline
      style={cardStyle(config)}
      CSS={css`
        user-select: none;
        opacity: 0.5;
        animation: ${loadingAni} 1.5s infinite alternate ease-in-out;
      `}
    >
      <HStack vAlign="center" width="100%" noWrap>
        <div
          style={{
            background: config.colors.c6,
            ...avatarStyle,
          }}
        />
      </HStack>
    </Card>
  );
};

const TwitterAccountCard = ({ account, selectedAccountsIds, setSelectedAccountsIds }) => {
  const config = useConfig();
  const { screen_name, id, name, profile_image_url: avatar } = account;
  const selected = selectedAccountsIds.find((el) => el === id);
  return (
    <Card
      inline
      style={cardStyle(config)}
      background={selected ? config.colors.accent1 : undefined}
      CSS={css`
        user-select: none;
        cursor: pointer;
        .open-icon {
          display: none;
          opacity: 0.5;
          :hover {
            opacity: 1;
          }
        }

        /* show on hover only on non-touch devices */
        @media (hover: hover) {
          :hover {
            .open-icon {
              display: block;
            }
          }
        }

        /* always show on touch devices */
        @media (hover: none) {
          .open-icon {
            display: block;
          }
        }
      `}
      onClick={() => {
        if (!selectedAccountsIds.find((el) => el === id)) {
          setSelectedAccountsIds([...selectedAccountsIds, id]);
        } else {
          setSelectedAccountsIds(selectedAccountsIds.filter((el) => el !== id));
        }
      }}
    >
      <HStack vAlign="center" width="100%" gap="0.3em" noWrap>
        <img
          src={avatar}
          style={{
            ...avatarStyle,
          }}
          alt={name}
        />
        <VStack style={{ flex: "1 1 auto" }} gap="1px" overflow="hidden">
          <P1
            noWrap
            maxWidth="100%"
            overflow="hidden"
            color={selected ? "white" : config.colors.c1}
            style={{ lineHeight: 1.1, fontSize: "15px", fontWeight: 500 }}
          >
            {name}
          </P1>
          <P2
            noWrap
            maxWidth="100%"
            overflow="hidden"
            color={selected ? "white" : config.colors.c3}
            style={{ lineHeight: 1.3, fontSize: "13px" }}
          >
            @{screen_name}
          </P2>
        </VStack>
        {/* <div
          className="open-icon"
          onClick={(e) => {
            e.stopPropagation();
            window.open(`https://twitter.com/${screen_name}`);
          }}
        >
          <Icon
            name="open"
            size="1.1em"
            color={selected ? "white" : config.colors.c2}
            style={{ marginRight: "0.3em" }}
          />
        </div> */}
        <Checkbox checked={selected} style={{ marginRight: "0.8em" }} />
      </HStack>
    </Card>
  );
};

function numberOfTweets(numberOfAccounts) {
  if (numberOfAccounts < 10) {
    return 4;
  } else if (numberOfAccounts < 20) {
    return 3;
  } else if (numberOfAccounts < 30) {
    return 2;
  } else {
    return 1;
  }
}

export default TwitterAccountsSelector;
