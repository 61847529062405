import { Input } from "@mailbrew/uikit";
import EditorSubpage from "components/editor/EditorSubpage";
import SourceEditorSection from "components/editor/SourceEditorSection";
import useDebouncedDispatch from "hooks/useDebouncedDispatch";
import useInputValidator from "hooks/useInputValidator";
import { Fragment, useMemo } from "react";
import { updateSourceField } from "reducers/newslettersReducer";
import useSWR from "swr";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";

const DevToSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  return (
    <EditorSubpage type={source.type} sourceIndex={sourceIndex}>
      {/* NUMBER OF POSTS */}
      <NumPostsSourceEditor
        noBorderTop
        source={source}
        sourceIndex={sourceIndex}
        min={1}
        max={30}
        fieldName="num_posts"
        explanationModalBody="We'll include the top posts from Dev.to published since the previous issue of your digest."
        shortExplanationBuilder={(numPosts) =>
          numPosts > 1 ? `You'll receive the ${numPosts} most popular posts.` : `You'll receive the most popular post.`
        }
      />

      {/* FILTER BY TAG */}
      <SourceEditorSection title="Filter by Tag" icon="hashtag" noPadding>
        <TagEditor source={source} sourceIndex={sourceIndex} />
      </SourceEditorSection>
    </EditorSubpage>
  );
};

const TagEditor = ({ source, sourceIndex }) => {
  const { data: popularTags } = useSWR("devto_popular_tags/");

  const dispatch500 = useDebouncedDispatch(500);

  const [tag, setTag] = useInputValidator(
    source.tag,
    () => null,
    (value) => {
      if (value && value.length > 0) {
        dispatch500(updateSourceField(sourceIndex, "tag", value));
      } else {
        dispatch500(updateSourceField(sourceIndex, "tag", undefined));
      }
    }
  );

  const suggestions = useMemo(() => {
    if (!popularTags) return [];
    let res = popularTags.map((t) => t.name);

    if (tag && tag.length > 0) {
      res = res.filter((t) => t.includes(tag.toLowerCase()));
    }

    return res;
  }, [popularTags, tag]);

  const handleTagSuggestionClick = (tag) => {
    setTag(tag);
  };

  return (
    <Fragment>
      <Input
        placeholder="javascript"
        value={tag}
        onChange={(e) => setTag(e.target.value)}
        suggestions={suggestions}
        onSuggestionClick={handleTagSuggestionClick}
      />
    </Fragment>
  );
};

export default DevToSourceEditor;
