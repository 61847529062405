import { Input } from "@mailbrew/uikit";
import ConnectTwitterAccountSection from "components/ConnectTwitterAccountSection";
import ConnectedTwitterAccount from "components/editor/ConnectedTwitterAccount";
import EditorSubpage from "components/editor/EditorSubpage";
import LayoutOptions from "components/editor/LayoutOptions";
import PopularOrLatestSelector from "components/editor/PopularOrLatestSelector";
import SourceEditorSection from "components/editor/SourceEditorSection";
import EditorHelpPostIt from "components/EditorHelpPostIt";
import ImagesStyleSetting from "components/ImagesStyleSetting";
import MaxTweetsPerUserSection from "components/MaxTweetsPerUserSection";
import MaxTweetsSection from "components/MaxTweetsSection";
import TwitterListEditorSection from "components/TwitterListEditorSection";
import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import { Disable } from "react-disable";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector, updateMe } from "reducers/authReducer";
import { updateSourceField } from "reducers/newslettersReducer";
import useSWR from "swr";
import notifApiError from "utils/notifApiError";

const minTweets = 1;
const maxPosts = 200;

const TwitterListSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  const twitterAccount = user?.connected_accounts?.find((acc) => acc.type === "twitter");
  const twitterAccountConnected = twitterAccount && !twitterAccount.error;

  const helpId = useRouter().query.help;

  const { data: lists, error: listsApiError } = useSWR("twitter_lists/");

  const onSelectTwitterList = (listId) => {
    const list = lists.find((l) => l.id === listId);
    dispatch(updateSourceField(sourceIndex, "list_id", list.id));
    dispatch(updateSourceField(sourceIndex, "title", list.name));

    if (list.member_count >= 30) {
      dispatch(updateSourceField(sourceIndex, "max_tweets_per_user", 1));
    } else if (list.member_count >= 10) {
      dispatch(updateSourceField(sourceIndex, "max_tweets_per_user", 2));
    } else {
      dispatch(updateSourceField(sourceIndex, "max_tweets_per_user", 3));
    }
  };

  // refresh the twitter accounts to show potential errored twitter account when we can't fetch the lists
  useEffect(() => {
    if (listsApiError) {
      dispatch(updateMe());
      notifApiError(listsApiError);
    }
  }, [dispatch, listsApiError]);

  const sourceIsValid = source.list_id;

  return (
    <EditorSubpage
      title={source.title?.length ? source.title : "Twitter List"}
      type={source.type}
      sourceIndex={sourceIndex}
    >
      {helpId === "digest" && (
        <EditorHelpPostIt icon="checkmark" title="List created!">
          We've created a list for you with your selected accounts. Feel free to try another one!
        </EditorHelpPostIt>
      )}
      {!twitterAccountConnected && (
        <SourceEditorSection icon="twitter" title="Connected Twitter Account" noBorderTop>
          <ConnectedTwitterAccount />
        </SourceEditorSection>
      )}
      <Disable disabled={!(twitterAccount && !twitterAccount.error)}>
        {/* LIST */}
        <SourceEditorSection title="List" icon="list" noBorderTop>
          <TwitterListEditorSection
            source={source}
            lists={lists}
            error={listsApiError}
            onSelectTwitterList={onSelectTwitterList}
          />
        </SourceEditorSection>

        {sourceIsValid && (
          <Fragment>
            {/* TITLE */}
            <SourceEditorSection title="Title" icon="writeBold">
              <Input
                placeholder="Title"
                type="text"
                autoComplete="off"
                value={source.title}
                onChange={(e) => dispatch(updateSourceField(sourceIndex, "title", e.target.value))}
              />
            </SourceEditorSection>

            <SourceEditorSection noChildrenLateralPadding title="Customize" icon="settingsAlt" collapsable>
              <SourceEditorSection title="Tweets to include" icon="twitter" noBorderTop>
                {/* MODE */}
                <PopularOrLatestSelector source={source} sourceIndex={sourceIndex} />
              </SourceEditorSection>

              {/* MAX NUMBER OF TWEETS */}
              <MaxTweetsSection source={source} sourceIndex={sourceIndex} min={minTweets} max={maxPosts} />

              {/* MAX TWEETS PER USER */}
              <MaxTweetsPerUserSection source={source} sourceIndex={sourceIndex} />

              <SourceEditorSection noBorderBottom>
                <ImagesStyleSetting
                  style={source.images_style}
                  onChange={(style) => dispatch(updateSourceField(sourceIndex, "images_style", style))}
                  pb={0}
                  pt={0}
                />
                <LayoutOptions
                  selected={source.columns || 1}
                  onChange={(option) => dispatch(updateSourceField(sourceIndex, "columns", option))}
                />
              </SourceEditorSection>
            </SourceEditorSection>
          </Fragment>
        )}
      </Disable>

      {twitterAccountConnected && <ConnectTwitterAccountSection noBorderBottom />}
    </EditorSubpage>
  );
};

export default TwitterListSourceEditor;
