import BetaListSourceEditor from "components/editor/sources/BetaListSourceEditor";
import CalendarSourceEditor from "components/editor/sources/CalendarSourceEditor";
import CovidSourceEditor from "components/editor/sources/CovidSourceEditor";
import CryptoSourceEditor from "components/editor/sources/CryptoSourceEditor";
import DevToSourceEditor from "components/editor/sources/DevToSourceEditor";
import ExchangeRateSourceEditor from "components/editor/sources/ExchangeRateSourceEditor";
import GoogleNewsSourceEditor from "components/editor/sources/GoogleNewsSourceEditor";
import HackerNewsSourceEditor from "components/editor/sources/HackerNewsSourceEditor";
import InboxSourceEditor from "components/editor/sources/InboxSourceEditor";
import ProductHuntSourceEditor from "components/editor/sources/ProductHuntSourceEditor";
import ReadLaterSourceEditor from "components/editor/sources/ReadLaterSourceEditor";
import ReadwiseSourceEditor from "components/editor/sources/ReadwiseSourceEditor";
import RssFeedSourceEditor from "components/editor/sources/RssFeedSourceEditor";
import SeparatorSourceEditor from "components/editor/sources/SeparatorSourceEditor";
import StocksSourceEditor from "components/editor/sources/StocksSourceEditor";
import SubredditSourceEditor from "components/editor/sources/SubredditSourceEditor";
import TextSourceEditor from "components/editor/sources/TextSourceEditor";
import TodaySourceEditor from "components/editor/sources/TodaySourceEditor";
import TwitterLikesSourceEditor from "components/editor/sources/TwitterLikesSourceEditor";
import TwitterListSourceEditor from "components/editor/sources/TwitterListSourceEditor";
import TwitterSearchSourceEditor from "components/editor/sources/TwitterSearchSourceEditor";
import TwitterTimelineSourceEditor from "components/editor/sources/TwitterTimelineSourceEditor";
import TwitterTopLinksSourceEditor from "components/editor/sources/TwitterTopLinksSourceEditor";
import TwitterUserSourceEditor from "components/editor/sources/TwitterUserSourceEditor";
import YouTubeChannelSourceEditor from "components/editor/sources/YouTubeChannelSourceEditor";
import YouTubeSourceEditor from "components/editor/sources/YouTubeSourceEditor";
import { sourcesData } from "../data/sourcesData";
import { v4 as uuidv4 } from "uuid";

const twitterDigestShortcut = {
  kind: "flow",
  title: "Digest",
  id: "twitter_digest",
  icon: "twitter_user",
  info: "Select your favorite follows and receive their best tweets",
};

const mailbrewChangelogSource = {
  kind: "source",
  title: "Changelog",
  id: "mailbrew_changelog",
  source: {
    id: uuidv4(),
    type: "rss_feed",
    title: "Mailbrew Updates",
    url: "https://changelog.mailbrew.com/rss",
    num_posts: 1,
    show_images: true,
    post_body: "excerpt",
  },
};

export const suggestedSourceShortcuts = [
  {
    title: "Sources to start your day",
    content: ["today", "inbox", "calendar"],
  },
  {
    title: "Tech & programming",
    content: ["hacker_news", "product_hunt", "devto"],
  },
  {
    title: "Get the best of Twitter",
    content: [twitterDigestShortcut, "twitter_list", "twitter_user", "twitter_timeline", "twitter_top_links"],
  },
  {
    title: "Follow your interests",
    content: ["rss_feed", "subreddit", "youtube_playlist"],
  },
  {
    title: "Keep things monitored",
    content: ["covid", "stocks", "crypto"],
  },
  {
    title: "From the Mailbrew team",
    content: [mailbrewChangelogSource],
  },
];

export const sourcesModalShortcuts = [
  {
    title: "Start your day",
    content: ["today", "inbox", "calendar"],
  },
  {
    title: "Get the best of Twitter",
    content: [
      twitterDigestShortcut,
      "twitter_list",
      "twitter_timeline",
      "twitter_top_links",
      "twitter_likes",
      "twitter_user",
      "twitter_search",
    ],
  },
  {
    title: "Follow your interests",
    content: ["rss_feed", "subreddit", "youtube_playlist", "readwise", "read_later"],
  },
  {
    title: "Tech & programming",
    content: ["hacker_news", "product_hunt", "betalist", "devto"],
  },
  {
    title: "Keep things monitored",
    content: ["exchange_rate", "stocks", "crypto", "google_news", "covid"],
  },
  {
    title: "Decorate your brew",
    content: ["text", "separator"],
  },
  {
    title: "From the Mailbrew team",
    content: [mailbrewChangelogSource],
  },
];

export const sourceTypeToSourceEditor = {
  twitter_user: TwitterUserSourceEditor,
  twitter_search: TwitterSearchSourceEditor,
  twitter_list: TwitterListSourceEditor,
  twitter_timeline: TwitterTimelineSourceEditor,
  twitter_top_links: TwitterTopLinksSourceEditor,
  rss_feed: RssFeedSourceEditor,
  subreddit: SubredditSourceEditor,
  hacker_news: HackerNewsSourceEditor,
  youtube_playlist: YouTubeSourceEditor,
  youtube_channel: YouTubeChannelSourceEditor,
  today: TodaySourceEditor,
  google_news: GoogleNewsSourceEditor,
  covid: CovidSourceEditor,
  exchange_rate: ExchangeRateSourceEditor,
  stocks: StocksSourceEditor,
  crypto: CryptoSourceEditor,
  text: TextSourceEditor,
  separator: SeparatorSourceEditor,
  inbox: InboxSourceEditor,
  devto: DevToSourceEditor,
  product_hunt: ProductHuntSourceEditor,
  betalist: BetaListSourceEditor,
  read_later: ReadLaterSourceEditor,
  readwise: ReadwiseSourceEditor,
  calendar: CalendarSourceEditor,
  twitter_likes: TwitterLikesSourceEditor,
};

export function getSourceTitle(source) {
  if (!source) return "Source";

  const maxLength = 32;

  // ft_name are params only used in the frontend

  // prettier-ignore
  const title = (() => {
    if (source.type === "today") return "Date";
    if (source.type === "subreddit") return source.subreddit ? `${source.subreddit}` : "Subreddit";
    if (source.type === "twitter_user") return source.screen_name ? `@${source.screen_name}` : "Twitter User";
    if (source.type === "twitter_search") return source.title || "Twitter Search";
    if (source.type === "twitter_list") return source.title || "Twitter List";
    if (source.type === "twitter_timeline") return source.title || "Twitter Timeline";
    if (source.type === "twitter_top_links") return source.title || (source.ft_list_title ? "Top links from " + source.ft_list_title : null) || "Twitter Top Links";
    if (source.type === "twitter_likes") return source.title || (source.ft_list_title ? "Liked by " + source.ft_list_title : null) || "Twitter Likes";
    if (source.type === "rss_feed") return source.title || "RSS Feed";
    if (source.type === "youtube_playlist") return source.title || "YouTube";
    if (source.type === "youtube_channel") return source.title || "YouTube";
    if (source.type === "covid") return source.country_name ? `Covid-19 / ${source.country_name}` : "Covid-19";
    if (source.type === "hacker_news") return source.title.length ? source.title : "Hacker News";
    if (source.type === "google_news" && source.mode === "query")  return source.title || source.query.length > 0 ? `"${source.query}" on Google News` : "Google News";
    if (source.type === "google_news" && source.mode === "topic") return source.title || `Google News`;
    if (source.type === "exchange_rate") return `Exchange Rate - ${source.base}`;
    if (source.type === "stocks") return source.title?.length > 0 ? source.title : "Stocks";
    if (source.type === "crypto") return source.title?.length > 0 ? source.title : "Crypto";
    if (source.type === "text") return source.text?.length > 0 ? clamp(source.text, maxLength) : "Text";
    if (source.type === "separator") return "Separator";
    if (source.type === "inbox") return source.title || "Newsletters";
    if (source.type === "product_hunt") return "Product Hunt";
    if (source.type === "betalist") return "BetaList";
    if (source.type === "devto") return `Dev.to` + (source.tag ? ` #${source.tag}` : "");
    if (source.type === "read_later") return "Saved";
    if (source.type === "readwise") return "Readwise";
    if (source.type === "calendar") return `Calendar`;
    return sourcesData[source.type]?.name || "Source";
  })();

  return clamp(title, maxLength);
}

function clamp(text, maxLength) {
  return text.length > maxLength ? text.slice(0, maxLength - 3) + "..." : text;
}

// prettier-ignore
export function getSourceURL(source) {
  if (source.type === "subreddit") return `https://reddit.com/r/${source.subreddit}`;
  if (source.type === "twitter_user") return `https://twitter.com/${source.screen_name}`;
  if (source.type === "twitter_search") return `https://twitter.com/search?q=${encodeURIComponent(source.query)}`;
  if (source.type === "twitter_list") return `https://twitter.com/i/lists/${source.list_id}`;
  if (source.type === "youtube_channel") return source.channel_url;
  if (source.type === "google_news" && source.mode === "query") return `https://news.google.com/search?q=${source.query}`;
  if (source.type === "google_news" && source.mode === "topic") return source.url;
  if (source.type === "devto") return source.tag ? `https://dev.to/t/${source.tag}` : "https://dev.to/";
  if (source.type === "product_hunt") return "https://www.producthunt.com/";
  if (source.type === "betalist") return "https://betalist.com/";
  if (source.type === "readwise") return "https://readwise.io/";
  if (source.type === "calendar") return "https://calendar.google.com";
  return null;
}
