import { Input, P2, SegmentedControl, Select, Spacer, useConfig } from "@mailbrew/uikit";
import EditorSubpage from "components/editor/EditorSubpage";
import InfoText from "components/editor/InfoText";
import LayoutOptions from "components/editor/LayoutOptions";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import SourceEditorSection from "components/editor/SourceEditorSection";
import SwitchWithExplanation from "components/SwitchWithExplanation";
import useInputValidator from "hooks/useInputValidator";
import useRandomItem from "hooks/useRandomItem";
import debounce from "just-debounce-it";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import * as Yup from "yup";

const GoogleNewsSourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();
  const config = useConfig();
  const queryPlaceholder = useRandomItem(placeholders);

  const [query, setQuery, queryError] = useInputValidator(
    source.query,
    Yup.string().max(256, "Type a shorter query."),
    (query) => debouncedUpdateQuery(dispatch, sourceIndex, query)
  );
  const [url, setURL, urlError] = useInputValidator(
    source.url,
    // https://news.google.com/topics/CAAqBwgKMOvMpAow7td3?hl=en-US&gl=US&ceid=US%3Aen
    Yup.string().matches(/^https?:\/\/news.google.com\/topics\/.+/, "Not a valid Google News topic URL."),
    (url) => dispatch(updateSourceField(sourceIndex, "url", url))
  );

  const isModeSearch = source.mode === "query";
  const isModeTopic = source.mode === "topic";

  return (
    <EditorSubpage title={source.title || "Google News"} type={source.type} sourceIndex={sourceIndex}>
      <SourceEditorSection noBorderTop>
        <SegmentedControl
          color={config.colors.c3}
          options={["query", "topic"]}
          optionsNames={["Search", "Topic"]}
          active={source.mode}
          onOptionChange={(option) => dispatch(updateSourceField(sourceIndex, "mode", option))}
        />
        <Spacer size={4} />
        {isModeSearch && <P2>Get Google results in your brew.</P2>}
        {isModeTopic && <P2>Get news aggregated by popular topics.</P2>}
      </SourceEditorSection>

      {isModeSearch && (
        <>
          <SourceEditorSection title="SEARCH QUERY" icon="search">
            <Input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              type="text"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              placeholder={queryPlaceholder}
              error={queryError}
              autoFocus={!(source?.title?.length > 0)}
            />
            {query.trim().includes(" ") && (
              <>
                <Spacer size="s" />
                <SwitchWithExplanation
                  title="Exact Match"
                  description="Search exact sentence in the articles' content."
                  enabled={source.exact_match}
                  setEnabled={(enabled) => dispatch(updateSourceField(sourceIndex, "exact_match", enabled))}
                />
              </>
            )}
          </SourceEditorSection>

          {/* LANGUAGE */}
          <SourceEditorSection title="LANGUAGE" icon="flagBold">
            <Select
              selectedOption={source.lang}
              onSelect={(l) => dispatch(updateSourceField(sourceIndex, "lang", l))}
              options={languages.map((l) => l.code)}
              optionNames={languages.map((l) => l.name)}
            />
          </SourceEditorSection>
        </>
      )}

      {isModeTopic && (
        <>
          <SourceEditorSection title="GOOGLE NEWS TOPIC" subtitle="Paste Google News URL here." icon="search">
            <Input
              value={url}
              onChange={(e) => setURL(e.target.value)}
              placeholder="Google News Topic URL"
              type="text"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              error={urlError}
              autoFocus={!(source?.title?.length > 0)}
            />
            <Spacer size="xxs" />
            <InfoText
              text="To find out how to add topics, "
              label="click here."
              modalTitle="How to add Google News topics"
              modalBody={`If you visit Google News and type something in the search field, you'll see lots of suggestions coming up. Some suggestions have <strong>a Topic or Location label</strong>.\n\nIf you click on a suggested Topic or Location and copy its URL, you can paste this URL here to add it to your brew.`}
              video="google-news-topic"
            />
          </SourceEditorSection>
        </>
      )}

      {/* TITLE */}
      <SourceEditorSection title="Title" icon="writeBold">
        <Input
          name="title"
          placeholder="Title"
          type="text"
          autoComplete="off"
          value={source.title}
          onChange={(e) => dispatch(updateSourceField(sourceIndex, "title", e.target.value))}
        />
      </SourceEditorSection>

      {/* NUMBER OF POSTS */}
      <NumPostsSourceEditor
        title="Included Posts"
        source={source}
        sourceIndex={sourceIndex}
        fieldName="num_posts"
        min={1}
        max={50}
        shortExplanationBuilder={(num) =>
          num === 1
            ? `We'll send you the latest post from this ${source.mode === "topic" ? "topic" : "search"}.`
            : `We'll send you the ${num} latest posts from this ${source.mode === "topic" ? "topic" : "search"}.`
        }
      />

      <SourceEditorSection>
        <LayoutOptions
          pt={0}
          selected={source.columns || 1}
          onChange={(option) => dispatch(updateSourceField(sourceIndex, "columns", option))}
        />
      </SourceEditorSection>
    </EditorSubpage>
  );
};

// spell-checker: disable
const placeholders = [
  "Apple",
  "Apple Rumor",
  "Tesla",
  "SpaceX",
  "NASA",
  "Hong Kong",
  "San Francisco",
  "Breaking News",
  "Microsoft",
  "Mailbrew",
  "Your Company",
  "Your Product Name",
  "Coronavirus",
  "ARM Macs",
  "AirPods",
  "Keto Diet",
  "SEO",
  "World Cup",
];
// spell-checker: enable

const languages = [
  { code: "en", name: "English" },
  { code: "es", name: "Spanish" },
  { code: "pt", name: "Portuguese" },
  { code: "it", name: "Italian" },
  { code: "fr", name: "French" },
  { code: "de", name: "German" },
  { code: "nl", name: "Dutch" },
  { code: "ar", name: "Arabic" },
  { code: "ru", name: "Russian" },
  { code: "ko", name: "Korean" },
  { code: "ja", name: "Japanese" },
];

const debouncedUpdateQuery = debounce((dispatch, sourceIndex, query) => {
  dispatch(updateSourceField(sourceIndex, "query", query));
}, 500);

export default GoogleNewsSourceEditor;
