import { Divider, H2, H3, P2, Stack, useBreakpoint } from "@mailbrew/uikit";

const TitleDivider = (props) => {
  const { title, color, small, bold = true, responsive, ...otherProps } = props;
  const breakpointHit = useBreakpoint();

  if (title) {
    if (responsive && breakpointHit) {
      return (
        <H2 mt={5} mb={4}>
          {title}
        </H2>
      );
    } else {
      return (
        <Stack gap={3} noWrap width="100%" align="stretch" mt={small ? 4 : 6} mb={small ? 4 : 6} {...otherProps}>
          <Divider style={{ flex: 1 }} size={0} />
          {!small ? (
            <H3 color={color} small bold={bold} style={{ flex: "0 1 auto" }}>
              {title}
            </H3>
          ) : (
            <P2 style={{ flex: "0 1 auto" }}>{title}</P2>
          )}
          <Divider style={{ flex: 1 }} size={0} />
        </Stack>
      );
    }
  } else {
    return <Divider size={small ? 4 : 8} width="100%" {...otherProps} />;
  }
};

export default TitleDivider;
