import {
  Badge,
  Box,
  Button,
  Card,
  CardHeader,
  Expandable,
  getColorFromCssVariable,
  Grid,
  H2,
  H4,
  Icon,
  P1,
  P2,
  ProgressBar,
  SmallText,
  Stack,
  SubtleCard,
  Tabs,
  useBreakpoint,
  useConfig,
} from "@mailbrew/uikit";
import { motion } from "framer-motion";
import useStripeCheckout from "hooks/useStripeCheckout";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import { reactivateSubscription } from "reducers/monetizationReducer";
import useSWR from "swr";
import tinycolor from "tinycolor2";
import urls from "urls";
import clamp from "utils/clamp";
import { fromNow } from "utils/formatDate";
import plausible from "utils/plausible";
import { pluralize } from "utils/pluralize";
import BrianLovinImage from "../images/avatars/Brian Lovin.jpg";
import DHHImage from "../images/avatars/DHH.jpg";
import FabrizioRinaldiImage from "../images/avatars/FabrizioRinaldi.png";
import FrancescoDiLorenzoImage from "../images/avatars/FrancescoDiLorenzo.png";
import JanelImage from "../images/avatars/Janel.jpg";
import ViticciImage from "../images/avatars/Viticci.jpg";
import ConfettiEffect from "./ConfettiEffect";
import InfoButton from "./InfoButton";
import { usePaywallState } from "./PaywallStateProvider";
import SourceShowcaseCard from "./SourceShowcaseCard";
import StripeProvider from "./StripeProvider";
import StyledLink from "./StyledLink";
import SubstackPaywall from "./SubstackPaywall";
import TitleDivider from "./TitleDivider";
import UserAvatar from "./UserAvatar";

export default function Paywall({ inModal, onKeepBrewingClick, maxWidth = "100%" }) {
  const config = useConfig();
  const dispatch = useDispatch();
  const router = useRouter();

  const [selectedProductId, setSelectedProductId] = useState("brewer");
  const [selectedPeriodicity, setSelectedPeriodicity] = useState("yearly");

  const {
    user,
    monetization,
    products,
    config: userConfig,
    highlightedFeature,
    productId: userPlanId,
    justUpgraded,
    trialLength,
  } = usePaywallState();

  useEffect(() => {
    if (highlightedFeature) {
      plausible.track("highlightedFeature", { feature: highlightedFeature });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedFeature, dispatch]);

  const monetizationStatus = monetization?.status;
  const freemium = !user || userConfig?.freemium;
  const ccTrialAvailable = !user || userConfig?.cc_trial_available;
  const { days_left: daysLeft, duration: trialDuration } = user?.monetization?.data || {};

  const isPatron = userPlanId === "patron";
  const isTrialingWithCc = monetizationStatus === "trialing_cc";
  const isPastDue = monetizationStatus === "past_due";
  const isCanceledWithAccess = monetizationStatus === "canceled_with_access";

  const isSubscribed = getUserIsSubscribed(monetization);
  const userHasAccess = getUserHasAccess(monetization);

  const state = (() => {
    if (isTrialingWithCc) return "trialing_cc";
    if (isSubscribed) return "subscribed";
    if (ccTrialAvailable && freemium && !userHasAccess) return "substack_paywall";
    else return "not_subscribed";
  })();

  const TrialingCC = () => (
    <Header inModal={inModal} maxWidth={maxWidth}>
      {justUpgraded && <ConfettiEffect />}
      <Stack vertical align="center" gap={0} maxW="100%">
        <AvatarWithBadge mb={4} />
        <H2 align="center" mb={justUpgraded ? 2 : 0}>
          {justUpgraded ? "Trial started" : "You're trialing"}
        </H2>
        <P1 color={config.colors.c1} align="center" maxWidth="400px" w="100%" mt={2}>
          You can now try all our features, and see how they improve your life.
        </P1>
        {justUpgraded && <KeepBrewing inModal={inModal} onKeepBrewingClick={onKeepBrewingClick} />}
        {justUpgraded && <NextBilling />}
      </Stack>
      <SectionHeader title="Enjoy the full experience" mt={6} mb={0} />
    </Header>
  );

  const Subscribed = () => (
    <Header inModal={inModal} maxWidth={maxWidth}>
      {justUpgraded && <ConfettiEffect />}
      <Stack vertical align="center" gap={2} maxW="100%">
        <AvatarWithBadge mb={2} />
        <H2 align="center">
          {isCanceledWithAccess ? (
            <>You canceled your plan</>
          ) : isPatron ? (
            <>You're a Patron 🔥</>
          ) : (
            <>You've upgraded 🔥</>
          )}
        </H2>
        {justUpgraded && (
          <P1 color={config.colors.c1} align="center" maxWidth="400px" w="100%">
            Thanks for upgrading. It's thanks to people like you that we can work on Mailbrew every day.
          </P1>
        )}
        {isPastDue && <FixBillingCta />}
        {isCanceledWithAccess && <ReactivatePlanCta />}
        {justUpgraded && <KeepBrewing inModal={inModal} onKeepBrewingClick={onKeepBrewingClick} />}
      </Stack>
      <SectionHeader title="Enjoy the full experience" mt={6} mb={0} />
    </Header>
  );

  const NotSubscribed = ({ substack }) => {
    return (
      <Header inModal={inModal} maxWidth={maxWidth}>
        <Stack align="center" gap={0} vertical width={highlightedFeature && freemium ? "400px" : "260px"} maxW="100%">
          <AvatarWithBadge productId={selectedProductId} mb={3} />
          {(() => {
            if (!user) return null;
            if (freemium && monetizationStatus === "trialing")
              return <TitleWithLine width="166px">You're trying our pro features</TitleWithLine>;
            if (freemium) return <TitleWithLine width="214px">Upgrade your information&nbsp;diet</TitleWithLine>;
            return <TitleWithLine width="140px">Keep using Mailbrew</TitleWithLine>;
          })()}
          {!user && (
            <H2 mt={2} align="center">
              Pricing
            </H2>
          )}
        </Stack>

        <StripeProvider>
          {!substack && (
            <>
              <PriceAndCta
                selectedProductId={selectedProductId}
                selectedPeriodicity={selectedPeriodicity}
                setSelectedPeriodicity={setSelectedPeriodicity}
                width={"250px"}
                handlesCompletion
              />
              {monetizationStatus === "trialing" && (
                <TrialProgress daysLeft={daysLeft} trialDuration={trialDuration} mt={3} />
              )}
              <Plans products={products} productId={selectedProductId} setProductId={setSelectedProductId} />
            </>
          )}
          {substack && <InternalSubstackPaywall />}
        </StripeProvider>
      </Header>
    );
  };

  const InternalSubstackPaywall = () => {
    const { coupon } = usePaywallState();

    const { handleUpgrade, upgradeLoading } = useStripeCheckout({
      freeTrialEnabled: true,
      handlesCompletion: true,
      redirectPath: router.asPath,
    });

    const handleSubscribeToPlan = (productId, periodicity) => {
      const priceId = products[productId].plans[periodicity + "ly"].price_id;
      handleUpgrade({ priceId, coupon, trial_period_days: trialLength });
    };

    return (
      <Fragment>
        <Expandable expanded={coupon}>{coupon && <CouponCard coupon={coupon} />}</Expandable>
        <SubstackPaywall
          currentPlan="free"
          onSubscribeToPlan={handleSubscribeToPlan}
          loading={upgradeLoading}
          ctaOptions={{
            hideFreePlanCta: true,
            standardCopy: trialLength === 0 ? "Subscribe" : "Start 2-week free trial",
          }}
        />
      </Fragment>
    );
  };

  return (
    <Box mb={inModal ? 2 : 12}>
      {state === "trialing_cc" && <TrialingCC />}
      {state === "subscribed" && <Subscribed />}
      {state === "substack_paywall" && <NotSubscribed substack />}
      {state === "not_subscribed" && <NotSubscribed />}
      <PaywallContent
        inModal={inModal}
        subscribed={isSubscribed}
        selectedProductId={selectedProductId}
        setSelectedProductId={setSelectedProductId}
        maxWidth={maxWidth}
        substack={state === "substack_paywall"}
      />
    </Box>
  );
}

const FixBillingCta = () => {
  return (
    <Fragment>
      <P1 align="center" w="100%" maxW="400px" mb={3} mt={3}>
        We couldn't charge your card. Please update your card to keep received your digests.
      </P1>
      <StyledLink icon="checkmarkCircle" to={urls.settings_billing()}>
        Fix payment method
      </StyledLink>
    </Fragment>
  );
};

const ReactivatePlanCta = () => {
  const dispatch = useDispatch();
  const { config: userConfig } = usePaywallState();

  const isFreemium = userConfig?.freemium;

  return (
    <Fragment>
      <P1 align="center" w="100%" maxW="400px" mb={3} mt={2}>
        {isFreemium ? (
          <>
            You'll soon be downgraded to our free plan. Please, reactive your plan to keep access to all pro features.
          </>
        ) : (
          <>We'll stop sending your brews soon, please reactive your plan if you wish to keep receiving them.</>
        )}
      </P1>
      <Button icon="refresh" onClick={() => dispatch(reactivateSubscription())}>
        Reactivate plan
      </Button>
    </Fragment>
  );
};

const NextBilling = () => {
  const { data: nextPaymentInfo } = useSWR("/upcoming_invoice/?customer=true");

  if (!nextPaymentInfo) return null;

  return (
    <P2 mt={4} align="center">
      You'll be automatically billed when the trial ends {fromNow(nextPaymentInfo.next_payment_attempt * 1000)}.
    </P2>
  );
};

const KeepBrewing = ({ inModal, onKeepBrewingClick }) => {
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    if (!user) return;
    plausible.track("Checkout Finished", {
      product: user.monetization?.data?.product_id,
      periodicity: user.monetization?.data?.periodicity,
    });
  }, [user]);
  return (
    <Fragment>
      {inModal ? (
        <Button variant="white" icon="coffee" mt={5} mb={2} width="200px" maxW="100%" onClick={onKeepBrewingClick}>
          Keep Brewing
        </Button>
      ) : (
        <StyledLink variant="white" icon="coffee" mt={5} mb={2} width="200px" maxW="100%" to={urls.brews()}>
          Keep Brewing
        </StyledLink>
      )}
    </Fragment>
  );
};

const AvatarWithBadge = ({ productId, ...otherProps }) => {
  const user = useSelector(authUserSelector);
  const size = "54px";
  const isPatron = productId === "patron";

  if (!user?.profile?.image_url) {
    return null;
  }

  return (
    <Box {...otherProps}>
      <Box w={size} h={size} position="relative">
        <PatronBadge show={isPatron} />
        <UserAvatar user={user} size={size} />
      </Box>
    </Box>
  );
};

const PatronBadge = ({ size = "24px", show = false }) => {
  return (
    <Box
      as={motion.div}
      animate={show ? { scale: 0.96, opacity: 1 } : { scale: 0, opacity: 0 }}
      transition={{ type: "spring", duration: 0.4, bounce: 0.22 }}
      style={{ position: "absolute", userSelect: "none", boxShadow: "0px 2px 6px rgba(0,0,0,0.1)" }}
      bottom="-3px"
      right="-3px"
      name="checkmarkCircle"
      width={size}
      height={size}
      radius={size}
      background="white"
      flex
      ai="center"
      jc="center"
    >
      <span style={{ fontSize: "14px", position: "relative", bottom: "1px" }}>🔥</span>
    </Box>
  );
};

const Plans = ({ products, productId, setProductId }) => {
  if (!products || Object.keys(products).length < 1) return null;
  const productEntries = Object.entries(products);
  return (
    <Tabs
      activeTab={productId}
      tabs={productEntries.map((p) => p[0])}
      tabsNames={productEntries.map((p) => remappedProductName[p[1].name] ?? p[1].name)}
      onTabChange={setProductId}
    />
  );
};

const remappedProductName = {
  Brewer: "Brewer",
  Patron: "Patron",
};

const Price = ({ product, selectedPeriodicity, appliedCoupon }) => {
  const config = useConfig();

  // Original Price
  const originalProduct = {
    price: product.price,
    monthlyPrice: product.monthly_price,
  };

  // Calculate Discounted Price
  const discountedProduct = (() => {
    if (!appliedCoupon) return null;
    return {
      price: product.price * (1 - appliedCoupon.percent_off / 100),
      monthlyPrice: product.monthly_price * (1 - appliedCoupon.percent_off / 100),
    };
  })();

  // Show discounted price or fallback to original
  const shownProduct = appliedCoupon ? discountedProduct : originalProduct;

  return (
    <Box w="100%" mb={2}>
      <Stack vertical gap={0} align="center" vAlign="center" w="100%">
        <H4 align="center" size="19px" weight="400" mb={1}>
          <span style={{ fontWeight: 500, letterSpacing: "0.6px" }}>
            {!appliedCoupon ? (
              formatPrice(shownProduct.monthlyPrice)
            ) : (
              <Fragment>
                <span
                  style={{
                    color: config.colors.c4,
                    fontWeight: 400,
                    textDecoration: "line-through",
                  }}
                >
                  {formatPrice(originalProduct.monthlyPrice)}
                </span>{" "}
                {formatPrice(shownProduct.monthlyPrice)}
              </Fragment>
            )}
            <span style={{ letterSpacing: "-0.5px" }}>/month</span>
          </span>
          {selectedPeriodicity !== "monthly" && `, billed ${selectedPeriodicity}`}
        </H4>
      </Stack>
      <Expandable expanded={appliedCoupon}>{appliedCoupon && <CouponCard coupon={appliedCoupon} />}</Expandable>
    </Box>
  );
};

const CouponCard = ({ coupon }) => {
  const config = useConfig();

  let explanation;

  if (coupon.duration_in_months === 12) {
    // no need for month/year distinction
    explanation = <>{coupon.percent_off}% off for the first year.</>;
  } else {
    explanation = (
      <>
        {coupon.percent_off}% off for the first {coupon.duration_in_months}{" "}
        {pluralize("month", coupon.duration_in_months)}, or the whole year if you pick the yearly plan.
      </>
    );
  }

  return (
    <Card inline w="340px" maxWidth="100%" mx="auto" mt={1} mb={3} pt={2} pb={3} px="18px">
      <P2 weight="500" align="center" color={config.colors.accent1}>
        <Icon size="18px" mr={1.5} offset="-4px" currentColor name="couponAltBold" />
        <span style={{ fontWeight: 700 }}>{coupon.name} discount applied</span>
      </P2>
      <Box
        ml="-18px"
        w="calc( 100% + 36px )"
        my={1.5}
        style={{ borderBottom: `1px dashed ${config.colors.uiBorderColor}` }}
      />
      <P2 align="center" color={config.colors.c2}>
        {explanation}
      </P2>
    </Card>
  );
};

const PriceAndCta = ({ selectedProductId, selectedPeriodicity, setSelectedPeriodicity, handlesCompletion }) => {
  const { user, products, coupon } = usePaywallState();

  const ccTrialAvailable = user?.config?.cc_trial_available;

  const router = useRouter();
  const monthlyInUrl = router.query.monthly;
  const disallowYearly = !couponSupportsYearlyBilling(coupon);

  useEffect(() => {
    if (monthlyInUrl) {
      setSelectedPeriodicity("monthly");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyInUrl]);

  const periodicity = disallowYearly ? "monthly" : selectedPeriodicity;
  const periodicityButtonShown = !disallowYearly;

  const selectedProduct = products?.[selectedProductId]?.plans?.[periodicity];
  const appliedCoupon = selectedProduct?.label !== "Patron" ? coupon : null;

  const { handleUpgrade: _handleUpgrade, upgradeLoading, upgradeError } = useStripeCheckout({
    freeTrialEnabled: ccTrialAvailable,
    handlesCompletion: handlesCompletion,
  });

  const handleUpgrade = () => {
    _handleUpgrade({ priceId: selectedProduct.price_id, coupon: appliedCoupon });
  };

  if (!selectedProduct) return null;

  return (
    <Fragment>
      <Price product={selectedProduct} selectedPeriodicity={periodicity} appliedCoupon={appliedCoupon} />
      {!user && (
        <StyledLink width="200px" maxWidth="100%" to={urls.signup()}>
          Get Started
        </StyledLink>
      )}
      {user &&
        (ccTrialAvailable ? (
          <Fragment>
            <Button maxWidth="100%" minWidth="200px" onClick={handleUpgrade} softLoading={upgradeLoading}>
              Try Free for 2 Weeks
            </Button>
            {upgradeError && <SmallText align="center">{upgradeError}</SmallText>}
          </Fragment>
        ) : (
          <Fragment>
            <Button maxWidth="100%" minWidth="200px" onClick={handleUpgrade} softLoading={upgradeLoading}>
              Upgrade Now
            </Button>
            {upgradeError && <SmallText align="center">{upgradeError}</SmallText>}
          </Fragment>
        ))}
      {periodicityButtonShown && (
        <PeriodicitySelector
          selectedPeriodicity={periodicity}
          setSelectedPeriodicity={setSelectedPeriodicity}
          appliedCoupon={appliedCoupon}
        />
      )}
    </Fragment>
  );
};

const PeriodicitySelector = ({ selectedPeriodicity, setSelectedPeriodicity, appliedCoupon, onClick }) => {
  if (selectedPeriodicity === "monthly") {
    return (
      <SmallText
        mt={2}
        weight="500"
        align="center"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setSelectedPeriodicity("yearly");
          onClick && onClick();
        }}
      >
        Switch to Yearly{appliedCoupon ? "" : " (save 20%)"}
      </SmallText>
    );
  }
  if (selectedPeriodicity === "yearly") {
    return (
      <SmallText
        mt={2}
        weight="500"
        align="center"
        style={{ cursor: "pointer" }}
        onClick={() => setSelectedPeriodicity("monthly")}
      >
        Switch to Monthly
      </SmallText>
    );
  }
  return null;
};

const Header = ({ inModal, children, maxWidth, ...otherProps }) => {
  const config = useConfig();
  const startColor = getColorFromCssVariable(config.colors.bg0);
  const endColor = tinycolor(getColorFromCssVariable(config.colors.bg0)).setAlpha(0.01).toString();
  if (inModal) {
    return (
      <CardHeader
        background="transparent"
        position="relative"
        zIndex="0"
        style={{ borderBottom: "none" }}
        overflow="hidden"
        padding={config.layout.padding}
        {...otherProps}
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="-1"
          maxHeight="600px"
          background="linear-gradient(60deg, hsla(220, 100%, 50%, 0.15) 10%, hsla(35, 90%, 75%, 0.1) 50%, hsla(25, 100%, 60%, 0.2) 80%)"
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="0"
          maxHeight="600px"
          background={`linear-gradient(0deg, ${startColor} 0%, ${endColor} 70%)`}
        />
        <Box position="relative" zIndex="2">
          <Stack vertical align="center" mt={7} gap={6} noWrap w={maxWidth} maxW="100%" mx="auto" breakAt={768}>
            {children}
          </Stack>
        </Box>
      </CardHeader>
    );
  } else {
    return (
      <Box {...otherProps}>
        <Stack vertical align="center" mt={7} gap={6} noWrap w={maxWidth} maxW="100%" mx="auto" breakAt={768}>
          {children}
        </Stack>
      </Box>
    );
  }
};

const TrialProgress = ({ ...otherProps }) => {
  const config = useConfig();

  const { user, config: userConfig } = usePaywallState();

  const { days_left: daysLeft, duration: trialDuration } = user?.monetization?.data || {};

  const freemium = userConfig?.freemium;

  return (
    <SubtleCard
      display="inline-block"
      background={config.colors.c3}
      px={4}
      pt={2}
      pb={3}
      w="auto"
      maxWidth="100%"
      mx="auto"
      {...otherProps}
    >
      <Stack vertical align="center" gap={2} breakAt={768}>
        {daysLeft > 2 && (
          <P2 color={config.colors.c2}>
            <strong>{daysLeft} days</strong> left in your trial
          </P2>
        )}
        {0 < daysLeft && daysLeft <= 2 && (
          <P2 color={config.colors.c2}>
            <strong>Trial almost over</strong> ({daysLeft} days)
          </P2>
        )}
        {0 === daysLeft && (
          <P2 color={config.colors.c2}>
            <strong>Your trial is over</strong>
          </P2>
        )}
        <ProgressBar width={"200px"} progress={clamp(1 - (trialDuration - daysLeft) / trialDuration, 0.01, 0.97)} />
        {freemium && (
          <Box mb={-1}>
            <InfoButton
              title="You'll switch to our free plan"
              description={`The free plan gives you a single brew with 10 sources, and all non "Pro" sources.`}
            >
              What happens at the end of the trial?
            </InfoButton>
          </Box>
        )}
      </Stack>
    </SubtleCard>
  );
};

const SectionHeader = ({ title, ...otherProps }) => {
  const hit = useBreakpoint();
  return <TitleDivider mt={hit ? 8 : 12} mb={hit ? 7 : 9} title={title} {...otherProps} />;
};

const FeaturesStack = ({ children, gap = 5, ...otherProps }) => {
  return (
    <Grid columns="1fr 1fr" width="100%" align="stretch" gap={gap} breakAt="768px" {...otherProps}>
      {children}
    </Grid>
  );
};

const Feature = ({ icon, title, descriptionHtml, active, onClick, highlighted }) => {
  const config = useConfig();
  const hit = useBreakpoint();
  return (
    <Card
      as={motion.div}
      initial={highlighted ? { scale: 0.7, opacity: 0 } : false}
      animate={highlighted ? { scale: 1, opacity: 1 } : null}
      transition={{ type: "spring", duration: 1.2, delay: 0.6, bounce: 0.45 }}
      maxW="100%"
      inline
      padding={hit ? 3 : 4}
      style={{ ...(active ? { opacity: 1 } : { opacity: 0.6 }), ...(highlighted ? { order: 0 } : { order: 1 }) }}
      position="relative"
      shadow={highlighted ? `0px 0px 24px -10px ${config.colors.accent1}` : "none"}
    >
      <Stack noWrap gap={4} vAlign="center" height="100%">
        <ActiveBadge active={active} onClick={onClick} style={onClick ? { cursor: "pointer" } : {}} />
        <Stack vertical gap={0}>
          <Stack>
            <Icon size="19px" name={icon} color={config.colors.c2} style={{ flex: "0 0 19px" }} />
            <P1 color={config.colors.c1} size="17.5px" weight="600">
              {title}
            </P1>
          </Stack>
          <P2
            maxWidth={hit ? "100%" : "380px"}
            width="100%"
            color={config.colors.c2}
            dangerouslySetInnerHTML={{ __html: descriptionHtml }}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

const ActiveBadge = ({ size = 26, iconSize = 16, active, style, onClick, ...otherProps }) => {
  const config = useConfig();
  return (
    <Box
      as={motion.div}
      animate={active ? { scale: 1 } : { scale: 0.9 }}
      transition={{ type: "spring", duration: 0.5, bounce: 0.2 }}
      width={size + "px"}
      height={size + "px"}
      radius={size + "px"}
      background={active ? config.colors.accent1 : "transparent"}
      shadow={active ? `inset 0px 0px 0px 0px ${config.colors.c4}` : `inset 0px 0px 0px 1px ${config.colors.c4}`}
      flex
      ai="center"
      jc="center"
      style={{ flex: `0 0 ${size}px`, transition: "0.3s shadow, 0.4s background", ...style }}
      onClick={onClick}
      {...otherProps}
    >
      <Box
        as={motion.div}
        animate={active ? { scale: 1 } : { scale: 0 }}
        transition={{ type: "spring", duration: 0.5, bounce: 0.3 }}
        flex
        ai="center"
        jc="center"
      >
        <Icon offset="-1px" strokeWidth={3.5} name="checkmark" color="white" size={iconSize} />
      </Box>
    </Box>
  );
};

const SourcesCarousel = ({ sourcesIds, highlightedFeature, children, ...otherProps }) => {
  const hit = useBreakpoint();
  const padding = hit ? 4 : 6;
  const internalPadding = hit ? 2 : 3;
  return (
    <Card
      inline
      noShadow
      w="100%"
      overflow="hidden"
      p={0}
      background="linear-gradient(25deg, hsla(60, 100%, 50%, 0) 40%, hsla(25, 100%, 60%, 0.05) 100%)"
      {...otherProps}
    >
      {children}
      <Box
        flex
        ai="center"
        jc="flex-start"
        py={padding}
        style={{
          overflowX: "scroll",
          overflowY: "hidden",
        }}
      >
        <Stack mx={padding} gap={0} noWrap vAlign="stretch">
          {sourcesIds.map((id, i) => {
            const last = i === sourcesIds.length - 1;
            return (
              <SourceShowcaseCard
                key={id}
                width={hit ? 240 : 260}
                source={id}
                pr={last ? padding : internalPadding}
                highlighted={id !== "inbox" && highlightedFeature === id}
              />
            );
          })}
        </Stack>
      </Box>
    </Card>
  );
};

const AboutUs = (props) => {
  return (
    <Box w="100%" {...props}>
      <P1 align="center" w="660px" maxW="100%" mx="auto" mt={-2} mb={6}>
        We left our jobs to work on this full-time, and we're on a mission to help people unplug from feeds, stay
        informed, and follow their&nbsp;passions.
      </P1>
      <MailbrewTeam />
    </Box>
  );
};

export const MailbrewTeam = () => {
  const breakpointHit = useBreakpoint(980);
  return (
    <Grid w="100%" gap={breakpointHit ? 2 : 3} columns="1fr 1fr" breakAt="768px">
      <UserCard
        avatarImage={FabrizioRinaldiImage}
        name="Fabrizio Rinaldi"
        description="Designer & Overthinker"
        twitterHandle="linuz90"
      />
      <UserCard
        avatarImage={FrancescoDiLorenzoImage}
        name="Francesco Di Lorenzo"
        description="Developer & Idioms Lord"
        twitterHandle="frankdilo"
      />
    </Grid>
  );
};

const AboutPricing = (props) => {
  return (
    <Box w="100%" {...props}>
      <SectionHeader title="How much is your time worth?" />
      <P1 mt={-2} mb={3} maxW="480px" w="100%" mx="auto" align="center">
        We priced Mailbrew fairly to make it affordable for our users and profitable for us, and that's how we're able
        to work on it full time.
      </P1>
      <P1 maxW="440px" w="100%" mx="auto" align="center">
        We believe this subscription pays for itself with the time and attention it will save you 🍃
      </P1>
    </Box>
  );
};

const Testimonials = (props) => {
  const breakpointHit = useBreakpoint(980);
  return (
    <Box w="100%" {...props}>
      <SectionHeader title="Here's what our users say" />
      <Grid mx="auto" w="100%" gap={breakpointHit ? 2 : 3} columns="1fr 1fr" breakAt="768px">
        <UserCard
          avatarImage={BrianLovinImage}
          name="Brian Lovin"
          // customLink="https://twitter.com/brian_lovin/status/1364633263952695301?s=21"
          description="Product Designer, GitHub"
          body={`I was seriously considering building a service to do this. I'm tired of Twitter FOMO. 

Now: one email per day with stuff I should know about: top links, and the best tweets from people I follow.

Very, very happy the Mailbrew crew built this.`}
        />
        <UserCard
          avatarImage={JanelImage}
          name="Janel"
          // twitterLink="JanelSGM"
          description="BrainPint, Newsletter OS"
          body={`I'm truly obsessed with Mailbrew. It's a massive time saver & game changer for my curation workflow. 

I love keeping tabs on multiple topics and Mailbrew makes sure that I don't miss anything important by delivering everything to my inbox. `}
        />
        <UserCard
          avatarImage={DHHImage}
          name="David Heinemeier Hansson"
          // twitterLink="dhh"
          description="Basecamp & Hey Co-Founder"
          body={`I've been using Mailbrew to keep up with Twitter peeps who don't post often, but whom I'd really hate to miss what they have to say. 
            
            It's pretty awesome. In many ways, Twitter-over-email is the healthier twitter!`}
        />
        {/* <UserCard
          avatarImage={DanielVassalloImage}
          name="Daniel Vassallo"
          twitterLink="dvassallo"
          description="The Good Parts of AWS author"
          body={`Mailbrew is so simple and yet so useful if you’re on Twitter.
            
            There are some accounts I like to read everything they put out, and Mailbrew puts this digest in my inbox every morning.`}
        /> */}
        {/* <UserCard
          avatarImage={PatWallsImage}
          name="Pat Walls"
          // twitterLink="thepatwalls"
          description="Founder Starter Story"
          body={`Mailbrew keeps me update on all the people and topics I need, without having to mindlessly check social media every 5 minutes.

I stay logged out of Twitter. I get everything from Mailbrew now.`}
        /> */}

        <UserCard
          avatarImage={ViticciImage}
          // twitterLink="thepatwalls"
          name="Federico Viticci"
          description="Founder, MacStories"
          body={`I get all my newsletters delivered to Mailbrew, together with top links from Twitter.
          
          I am in love with Mailbrew and have come to expect my daily brew at the end of the day.`}
          isPatron
        />
      </Grid>
    </Box>
  );
};

const UserCard = ({ avatarImage, name, description, body, twitterHandle, twitterLink, customLink, isPatron }) => {
  const config = useConfig();
  return (
    <Card inline>
      <Stack gap={2} noWrap overflow="hidden" align="spaced" maxWidth="100%">
        <Stack vertical>
          <Stack
            as={twitterLink || customLink ? "a" : "div"}
            href={twitterLink ? "https://twitter.com/" + twitterLink : customLink}
            target="_blank"
            noWrap
            overflow="hidden"
            maxWidth="100%"
          >
            <img src={avatarImage} alt={name} style={{ width: "40px", height: "40px", borderRadius: "40px" }} />
            <Stack vertical gap={0.5}>
              <P1 size="16px" letterSpacing="-0.1px" noWrap overflow="hidden" maxW="100%" weight="600" lineHeight="1.3">
                {name}
              </P1>
              <P2 size="13px" noWrap overflow="hidden" maxW="100%" lineHeight="1.3">
                {description}
              </P2>
            </Stack>
            {isPatron && (
              <Badge variant="secondary" style={{ position: "absolute", top: "10px", right: "10px" }}>
                🔥 Patron
              </Badge>
            )}
          </Stack>
          {body && (
            <P2 color={config.colors.c2} size="15px" style={{ whiteSpace: "pre-line" }}>
              {body}
            </P2>
          )}
        </Stack>
        {twitterHandle && (
          <Button
            noStretch
            color="#1DA1F2"
            variant={["secondary", "small"]}
            icon="twitter"
            onClick={() => window.open(`https://twitter.com/${twitterHandle}`)}
          >
            Follow
          </Button>
        )}
      </Stack>
    </Card>
  );
};

const PaywallContent = ({ inModal, selectedProductId, setSelectedProductId, maxWidth, substack }) => {
  const config = useConfig();
  const { user, monetization, config: userConfig, highlightedFeature, productId: userPlanId } = usePaywallState();

  const userIsSubscribed = getUserIsSubscribed(monetization);
  const userHasAccess = getUserHasAccess(monetization);

  const isTrialing = monetization?.status.includes("trialing");

  const freemium = !user || userConfig?.freemium;

  const isTrialingWithCc = monetization?.status === "trialing_cc";

  const showPatronFeatures = (() => {
    if (userPlanId === "patron") return true;

    if (isTrialingWithCc && userPlanId === "patron") return true;

    if (!isTrialingWithCc && !userIsSubscribed) return true;

    return false;
  })();
  const patronFeaturesActive = selectedProductId === "patron" || userPlanId === "patron";

  return (
    <Stack mt={inModal ? -8 : 4} mb={4} vertical gap={0} w={maxWidth} maxW="100%" mx="auto">
      {!substack && (
        <FeaturesStack mt={4} gap={4}>
          {freemium && (
            <Fragment>
              <Feature
                highlighted={highlightedFeature === "brews_limit"}
                active={true}
                icon="coffeeAlt"
                title="Unlimited brews"
                descriptionHtml={`Go beyond the limit of 1 free brew.`}
              />
              <Feature
                highlighted={highlightedFeature === "sources_limit"}
                active={true}
                icon="list"
                title="Unlimited sources"
                descriptionHtml={`Don't stop at 5 sources per brew.`}
              />
              <Feature
                highlighted={highlightedFeature === "layout_options"}
                active={true}
                icon="layout"
                title="Customize"
                descriptionHtml={`More layout and style options.`}
              />
              <Feature
                highlighted={highlightedFeature === "locked_price"}
                active={true}
                icon="heart"
                title="Lock price forever"
                descriptionHtml={`Even with new sources & features.`}
              />
              {/* <Feature
              highlighted={highlightedFeature === "inbox"}
              active={true}
              icon="email"
              title="Newsletters"
              descriptionHtml={`Receive newsletters in Mailbrew.`}
            /> */}
            </Fragment>
          )}
          {!freemium && (
            <Fragment>
              <Feature
                active={true}
                icon="lightningAlt"
                title="Receive your brews"
                descriptionHtml={`Keep receiving your digests.`}
              />
              <Feature
                active={true}
                icon="heartBold"
                title="Support development"
                descriptionHtml={`Help us grow and improve Mailbrew.`}
              />
            </Fragment>
          )}
          {showPatronFeatures && (
            <Feature
              active={patronFeaturesActive}
              onClick={() =>
                !userIsSubscribed &&
                (patronFeaturesActive ? setSelectedProductId("brewer") : setSelectedProductId("patron"))
              }
              icon="chat"
              title="Early access"
              descriptionHtml={`Get early access to new features.`}
            />
          )}
          {showPatronFeatures && (
            <Feature
              active={patronFeaturesActive}
              onClick={() =>
                !userIsSubscribed &&
                (patronFeaturesActive ? setSelectedProductId("brewer") : setSelectedProductId("patron"))
              }
              icon="lightningAlt"
              title="Get featured"
              descriptionHtml={`We'll add you to our <a target="_blank" href="https://mailbrew.com/patrons">Patrons</a> page.`}
            />
          )}
        </FeaturesStack>
      )}
      {/* {freemium && <SectionHeader title={subscribed ? "All our sources" : "Unlock these sources"} />} */}
      {freemium && !substack && (
        <SourcesCarousel
          mt={4}
          sourcesIds={["twitter_top_links", "calendar", "stocks", "crypto", "exchange_rate", "readwise"]}
          highlightedFeature={highlightedFeature}
        >
          <Stack pt={5} pl={5} gap={1.5} w="100%">
            <ActiveBadge active mr={2} />
            {!isTrialing && (
              <P1 color={config.colors.c1} size="17.5px" weight="600">
                Unlock all
              </P1>
            )}
            <ProBadge />
            <P1 color={config.colors.c1} size="17.5px" weight="600">
              Sources
            </P1>
          </Stack>
        </SourcesCarousel>
      )}
      <SectionHeader title={userIsSubscribed ? "Thank you, seriously" : "Made by a small, indie team"} />
      <AboutUs />
      <Testimonials />
      {!userHasAccess && <AboutPricing />}
    </Stack>
  );
};

export const ProBadge = ({ color, fontSize = "13.5px", radius = "4px", zoom = 1, invert, style, ...otherProps }) => {
  const config = useConfig();
  const { monetization } = usePaywallState();
  const userHasAccess = getUserHasAccess(monetization);

  return (
    <Box
      border={"1px solid " + (color ?? (invert ? config.colors.bg0 : config.colors.c2))}
      p="0 4px 1px 5px"
      radius={radius}
      style={{ zoom, ...style }}
      {...otherProps}
    >
      {/* <Icon
        mr="3px"
        name={userHasAccess ? "checkmarkBold" : "starBold"}
        size={userHasAccess ? "12px" : "10px"}
        color={invert ? config.colors.c2 : config.colors.bg0}
      /> */}
      <SmallText
        fontSize={fontSize}
        fontWeight={invert ? "600" : "700"}
        lineHeight="1"
        letterSpacing="0.5px"
        color={color ?? (invert ? config.colors.bg0 : config.colors.c2)}
        position="relative"
      >
        PRO
      </SmallText>
    </Box>
  );
};

const TitleWithLine = ({ children, width }) => {
  return (
    <Fragment>
      <H2 align="center">{children}</H2>
      <Line mt={1.5} width={width} />
    </Fragment>
  );
};

const Line = ({ width = "225", ...otherProps }) => {
  return (
    <Box
      as="svg"
      width={width}
      height="11px"
      viewBox="0 0 225 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.886 6.4956C157.495 2.77523 79.0818 5.00557 2.11657 9.99541C1.29065 10.0598 0.623813 9.63171 0.51055 8.839C0.397286 8.04629 1.08867 7.34106 1.92618 7.27239C79.8265 0.885236 158.541 -2.06444 223.114 3.5043C223.94 3.56736 224.559 4.2881 224.496 5.11412C224.433 5.94014 223.712 6.55865 222.886 6.4956Z"
        fill="#F75858"
      />
    </Box>
  );
};

function formatPrice(price, freeLabel) {
  if (price === null || typeof price === "undefined") return null;
  if (price === 0 && freeLabel) {
    return "Free";
  }
  if (price % 1 === 0) {
    return "$" + price;
  } else {
    return "$" + price.toFixed(2);
  }
}

function calculateSaving(currentPrice, originalPrice) {
  const saving = Math.round((1 - currentPrice / originalPrice) * 100);

  if (saving && saving < 0) {
    return null;
  }

  if (saving) {
    return saving + "%";
  } else {
    return null;
  }
}

function couponSupportsYearlyBilling(couponInfo) {
  if (!couponInfo) return true;
  if (!couponInfo["metadata.allow_yearly"]) return true;

  return couponInfo["metadata.allow_yearly"].trim() === "true";
}

function getUserHasAccess(monetization) {
  const monetizationStatus = monetization?.status;
  return (
    monetizationStatus === "subscribed" ||
    monetizationStatus === "free_access" ||
    monetizationStatus === "past_due" ||
    monetizationStatus === "canceled_with_access" ||
    monetizationStatus === "trialing_cc"
  );
}

function getUserIsSubscribed(monetization) {
  const monetizationStatus = monetization?.status;
  return (
    monetizationStatus === "subscribed" ||
    monetizationStatus === "free_access" ||
    monetizationStatus === "past_due" ||
    monetizationStatus === "canceled_with_access"
  );
}

export const highlightableFeatures = [
  "calendar",
  "inbox",
  "stocks",
  "crypto",
  "exchange_rate",
  "readwise",
  "brews_limit",
  "sources_limit",
  "layout_options",
];
