import { useEffect, useState } from "react";
import randomItem from "../utils/randomItem";

export default function useRandomItem(arr) {
  const [item, setItem] = useState("");

  useEffect(() => {
    setItem(randomItem(arr));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [item];
}
