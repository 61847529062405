import {
  Button,
  Grid,
  H1,
  HStack,
  Icon,
  P1,
  SafeAreaInsetBottom,
  Section,
  Spacer,
  useBreakpoint,
  useConfig,
  VStack,
} from "@mailbrew/uikit";
import { motion } from "framer-motion";
import { sourcesData } from "../../data/sourcesData";

const OnboardingUI = (props) => {
  const {
    children,
    icon,
    title,
    description,
    backButton,
    minHeight = 0,
    badgeLabel,
    fadeIn,
    buttons,
    hideProgress,
    ...rest
  } = props;

  const SourceIcon = sourcesData[icon]?.thumb;

  const hit = useBreakpoint(480);

  return (
    <>
      <Section
        as={motion.div}
        initial={fadeIn && { scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", duration: 0.8, bounce: 0.1, delay: 0.1 }}
        noPadding
        py={hit ? 6 : 4}
        style={{ paddingLeft: "8px", paddingRight: "8px" }}
        center={!hit}
      >
        {/* <CardWrapper animated width={width} {...rest}> */}
        <VStack align="center" gap={3}>
          {badgeLabel && <Button variant={["pill", "line"]}>{badgeLabel}</Button>}
          <HStack align="center" vAlign="center" mb={2} breakAt={640}>
            {SourceIcon && <SourceIcon width="34px" height="34px" style={{ flex: "0 0 34px" }} />}
            {title && <H1 weight="400" align="center" dangerouslySetInnerHTML={{ __html: title }}></H1>}
          </HStack>
        </VStack>
        {description && (
          <P1 style={{ whiteSpace: "pre-wrap" }} align="center" size="17px">
            {description}
          </P1>
        )}
        <Spacer size={6} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            minHeight: minHeight,
          }}
        >
          {children}
        </div>
        {buttons && <OnboardingButtons buttons={buttons} backButton={backButton} />}
        {/* </CardWrapper> */}
        <SafeAreaInsetBottom />
      </Section>
    </>
  );
};

export const OnboardingButtons = ({ buttons, backButton }) => {
  const config = useConfig();
  return (
    <VStack w="100%" align="center" gap="0.4rem" mt={9}>
      <Grid w="100%" align="center" vAlign="center" columns={backButton ? "2rem 1fr 2rem" : "1fr"}>
        {backButton && (
          <Icon name="chevronLeft" size="1.6rem" color={config.colors.c5} onClick={() => window.history.back()} />
        )}
        {buttons.continue && (
          <Button
            disabled={buttons.continue.loading || buttons.continue.disabled}
            onClick={buttons.continue.onClick}
            loading={buttons.continue.loading}
            minWidth="12em"
            noStretch
          >
            {buttons.continue.label}
          </Button>
        )}
        {backButton && <div />}
      </Grid>
      {buttons.cancel && (
        <Button
          mt={1}
          style={{ fontWeight: 400 }}
          color={config.colors.c4}
          variant="link"
          onClick={buttons.cancel.onClick}
        >
          {buttons.cancel.label}
        </Button>
      )}
    </VStack>
  );
};

export default OnboardingUI;
