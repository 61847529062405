/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import {
  Box,
  CircleButton,
  Expandable,
  HStack,
  P1,
  P2,
  SubtleCard,
  useBreakpoint,
  useConfig,
  VStack,
} from "@mailbrew/uikit";
import SearchField from "components/editor/SearchField";
import OnboardingUI from "components/onboarding/OnboardingUI";
import Page from "components/Page";
import api from "dependencies/api";
import debounce from "just-debounce-it";
import { Fragment, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedFeedsSelector,
  selectedTopicsSelector,
  setSelectedFeeds,
  setUserAddedFeeds,
  userAddedFeedsSelector,
} from "reducers/onboardingReducer";
import useSWR from "swr";
import avatarFromUrl from "utils/avatarFromUrl";

const RssStep = ({ progress, onNextClick, buttonLabel }) => {
  const dispatch = useDispatch();

  const selectedTopics = useSelector(selectedTopicsSelector);

  const { data: suggestions = [], isValidating } = useSWR(
    selectedTopics.length > 0 && `/onboarding/rss_suggestions/?topics=${selectedTopics.join(",")}`
  );

  const stateUserAddedFeeds = useSelector(userAddedFeedsSelector);
  const stateSelectedFeeds = useSelector(selectedFeedsSelector);

  const [userAddedSuggestions, setUserAddedSuggestions] = useState(stateUserAddedFeeds);
  const [selectedSuggestions, setSelectedSuggestions] = useState(stateSelectedFeeds);

  const allSuggestions = useMemo(() => {
    if (!suggestions) return userAddedSuggestions;
    return userAddedSuggestions.concat(suggestions);
  }, [suggestions, userAddedSuggestions]);

  const handleFeedUrlSubmit = (_, __, feed) => {
    if (!feed) return;

    if (!allSuggestions.find((sugg) => sugg.feed_url === feed.feed_url)) {
      setUserAddedSuggestions([...userAddedSuggestions, feed]);
    }

    if (!selectedSuggestions.find((sugg) => sugg.feed_url === feed.feed_url)) {
      setSelectedSuggestions([feed, ...selectedSuggestions]);
    }
  };

  return (
    <Page title="Blogs" noNavigation>
      <OnboardingUI
        icon="rss_feed"
        title="Websites & Blogs"
        description="The latest items from these feeds will be included in your digest."
        backButton
        progress={progress}
        buttons={{
          cancel: {
            label: "Add later",
            onClick: () => {
              onNextClick({ skip: true });
            },
          },
          continue: {
            label: buttonLabel,
            onClick: () => {
              dispatch(setUserAddedFeeds(userAddedSuggestions));
              dispatch(setSelectedFeeds(selectedSuggestions));
              onNextClick();
            },
          },
        }}
      >
        <ResponsiveSubtleCard style={{ overflow: "hidden" }}>
          <VStack w="100%">
            <SearchField
              initialValue=""
              placeholder="Search or paste URL"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="off"
              fetchSuggestions={searchRssFeeds}
              onSuggestionClick={handleFeedUrlSubmit}
              autofillSuggestionClick="empty"
            />
            <Expandable w="100%" expanded={!isValidating && allSuggestions.length > 0}>
              {!isValidating && allSuggestions.length > 0 && (
                <Fragment>
                  <P2 align="center" w="100%" mt={2} mb={2}>
                    Some suggestions for you (click to add)
                  </P2>
                  <Suggestions
                    suggestions={isValidating ? [] : allSuggestions}
                    suggestionComponent={RssSuggestion}
                    keyFn={(suggestion) => suggestion.feed_url}
                    selectedSuggestions={selectedSuggestions}
                    setSelectedSuggestions={setSelectedSuggestions}
                  />
                </Fragment>
              )}
            </Expandable>
          </VStack>
        </ResponsiveSubtleCard>
      </OnboardingUI>
    </Page>
  );
};

export const ResponsiveSubtleCard = ({ width, minHeight, children, ...rest }) => {
  const breakpointHit = useBreakpoint();
  if (breakpointHit) {
    return (
      <Box w="100%" {...rest}>
        {children}
      </Box>
    );
  } else {
    return (
      <SubtleCard w="100%" {...rest}>
        {children}
      </SubtleCard>
    );
  }
};

export const searchRssFeeds = debounce(
  async (
    query,
    {
      setSuggestions,
      setSuggestionsNames,
      setSuggestionsDetails,
      setSuggestionsImages,
      setSuggestionsPayloads,
      setLoading,
      setError,
    }
  ) => {
    function updateSuggestions(feeds) {
      setSuggestions(feeds.map((s) => s.feed_url));
      setSuggestionsNames(feeds.map((s) => s.title));
      setSuggestionsDetails(feeds.map((s) => s.site_url));
      setSuggestionsImages(feeds.map((s) => avatarFromUrl(s.site_url)));
      setSuggestionsPayloads(feeds);
    }

    if (!query || query.length < 2) {
      setSuggestions([]);
      setError(null);
      return;
    }

    setLoading(true);
    setError("");

    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
    if (query.match(urlRegex)) {
      // fetch feed info via typed url
      try {
        const res = await api.get("feed_fetcher/", { params: { url: query } });
        const feed = { title: res.data.title, feed_url: res.data.feed_url, site_url: res.data.site_url };
        updateSuggestions([feed]);
      } catch (err) {
        const errorMessage = err?.response?.data?.detail || "Can't find any RSS feed there.";
        setSuggestions([]);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    } else {
      // search results in backend
      try {
        const res = await api.get("/rss_search/", { params: { q: query } });
        updateSuggestions(res.data);
      } catch (err) {
        const errorMessage = err?.response?.data?.detail || "Can't search RSS feeds right now.";
        setSuggestions([]);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  },
  500
);

const RssSuggestion = ({ suggestion, isSelected, onClick }) => {
  return (
    <Pill
      title={suggestion.title}
      url={suggestion.site_url}
      iconUrl={avatarFromUrl(suggestion.site_url)}
      fallbackIconUrl={"https://mailbrew-assets.s3.us-east-1.amazonaws.com/newletters-templates/sources/rss.png"}
      isSelected={isSelected}
      onClick={onClick}
    />
  );
};

export const Pill = ({ title, isSelected, url, iconUrl: providedIconUrl, fallbackIconUrl, onClick }) => {
  const config = useConfig();

  const [iconUrl, setIconUrl] = useState(providedIconUrl);

  const containerCss = css`
    border: 1px solid ${isSelected ? config.colors.accent1 : config.colors.uiBorderColor};
    padding: 4px 5px;
    /* margin: 0 6px 6px 0; */
    border-radius: 2rem;
    background-color: ${isSelected ? config.colors.accent1 : config.colors.bg0};
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
    p {
      font-size: 15px;
      ${iconUrl && url
        ? `
        padding-left: 4px;
        letter-spacing: -0.5px;
      `
        : `
          padding-left: 5px;
          letter-spacing: 0px;
      `}
    }
    img {
      width: 24px;
      height: 24px;
      border-radius: 24px;
    }
    @media (max-width: 640px) {
      padding: 3px 4px;
      /* margin: 0 3px 3px 0; */
      overflow: hidden;
      p {
        font-size: 14px;
      }
      img {
        width: 20px;
        height: 20px;
        border-radius: 20px;
      }
    }
  `;

  const onImageError = (e) => {
    fallbackIconUrl && setIconUrl(fallbackIconUrl);
  };

  return (
    <Box css={containerCss} onClick={onClick}>
      <HStack align="left" vAlign="center" gap={0} noWrap>
        {iconUrl && <img src={iconUrl} alt={title} onError={onImageError} />}
        <P1 weight="600" color={isSelected ? "white" : config.colors.c3} noWrap overflow="hidden">
          {title}
        </P1>
      </HStack>
      {url && (
        <CircleButton
          variant="white"
          ml={2}
          icon="open"
          size="26px"
          iconSize="14px"
          strokeWidth="1.5px"
          background={config.colors.bg0}
          textColor={config.colors.c3}
          onClick={(e) => {
            window.open(url);
            e.stopPropagation();
          }}
          style={{
            flex: "0 0 26px",
          }}
        />
      )}
    </Box>
  );
};

export const Suggestions = ({
  suggestions,
  keyFn,
  suggestionComponent: Suggestion,
  selectedSuggestions,
  setSelectedSuggestions,
}) => {
  if (!suggestions) {
    return null;
  }

  const handleClick = (suggestion) => {
    const key = keyFn(suggestion);
    if (selectedSuggestions.find((sugg) => key === keyFn(sugg))) {
      setSelectedSuggestions(selectedSuggestions.filter((sugg) => keyFn(sugg) !== key));
    } else {
      setSelectedSuggestions([...selectedSuggestions, suggestion]);
    }
  };

  const wrapperCss = css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 32.6%);
    grid-gap: 5px;
    box-sizing: border-box;
    @media (max-width: 640px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 4px;
    }
  `;

  return (
    <div css={wrapperCss}>
      {suggestions.map((suggestion) => {
        const key = keyFn(suggestion);
        const selected = selectedSuggestions.find((suggestion) => key === keyFn(suggestion));
        return (
          <Suggestion key={key} suggestion={suggestion} isSelected={selected} onClick={() => handleClick(suggestion)} />
        );
      })}
    </div>
  );
};

export default RssStep;
