import { Button, Grid, H4, P1, Stack, useBreakpoint, useConfig } from "@mailbrew/uikit";
import Axios from "axios";
import useHandleShareUrl from "hooks/useHandleShareUrl";
import Link from "next/link";
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import useSWR from "swr";
import fetchNewsletterShareLink from "utils/fetchNewsletterShareLink";
import { fromNow } from "utils/formatDate";
import plausible from "utils/plausible";
import { keyInboxSourceMessageByID } from "utils/swrKeys";
import ExpandableIFrame from "./ExpandableIFrame";
import { FullWidthCard } from "./FullWidthCard";
import ItemActionButtons from "./ItemActionButtons";
import KeepReadingButton from "./KeepReadingButton";
import { isValidKeyboardShortcutEvent } from "./KeyboardShortcutsManager";
import { TAB_BAR_BREAKPOINT } from "./Page";
import ReadingProgressIndicator from "./ReadingProgressIndicator";
import SenderDropDown from "./SenderDropDown";
import SmallSecondaryButton from "./SmallSecondaryButton";
import UnstyledA from "./UnstyledA";

const InboxMessageView = React.memo(
  ({
    message: providedMessage,
    index,
    messageId,
    src,
    fullTitle,
    subjectLinksToMessage,
    showCard,
    fullWidthCard,
    onMessageError,
    expandable = false,
    onClickDelete,
    onClickArchive,
    onHtmlReady,
    height = "180px",
    children,
    externalChildren,
    itemActionsOverride,
    withReadLater = true,
    withArchive = true,
    modalRef,
    keepReadingButtonRef,
    keepReadingEnabled: providedKeepReadingEnabled = true,
    actionsEnabled = true,
    keepReadingZIndex,
    keepReadingExtraOffset,
    mt = 0,
    mb = 5,
    style,
    noDarkMode,
    onKeyUp,
    isPublicSharePage,
  }) => {
    const [messageLoaded, setMessageLoaded] = useState(false);

    const user = useSelector(authUserSelector);
    const [expanded, setExpanded] = useState(expandable ? false : true);
    const [didModifyMessage, setDidModifyMessage] = useState(false);
    const containerRef = useRef(null);

    const swrKey =
      providedMessage && didModifyMessage
        ? keyInboxSourceMessageByID(providedMessage.message_id, src)
        : messageId
        ? keyInboxSourceMessageByID(messageId, src)
        : null;

    const { data, isValidating: loading, error: fetchingError, mutate } = useSWR(swrKey);
    const message = useMemo(() => data || providedMessage, [data, providedMessage]);

    const isUserMessageOwner = user?.id === message?.user;

    useEffect(() => {
      if (fetchingError) {
        onMessageError && onMessageError();
      }
    }, [fetchingError, onMessageError]);

    useEffect(() => {
      if (expanded && message) {
        plausible.track("Expand Newsletter", {
          subject: message.subject,
          sender: message.sender_name,
          email: message.sender_email,
        });
      }
    }, [expanded, message]);

    function handleExpand() {
      if (expanded && containerRef.current) {
        setTimeout(() => {
          containerRef.current.scrollIntoView({ block: "center" });
        }, 200);
      }
      // switches to single-fetch message so we react to reading progress mutations
      if (providedKeepReadingEnabled) setDidModifyMessage(true);
      setExpanded(!expanded);
    }

    const handleChangeReadingProgress = useCallback(() => {
      // don't change reading progress if user reading is not the owner
      if (!isUserMessageOwner || !providedKeepReadingEnabled) {
        return;
      }

      // after modifying the message, switch to fetching the individual message instead of using the providedMessage
      setDidModifyMessage(true);
      mutate();
    }, [isUserMessageOwner, providedKeepReadingEnabled, mutate]);

    /* --------------------------- get message content -------------------------- */
    // don't use SWR because message.content_url changes at each fetch and triggers an ugly re-render

    const [messageHtml, setMessageHtml] = useState(null);
    useEffect(() => {
      if (message && message.content_url) {
        Axios.get(message.content_url).then((res) => {
          setMessageHtml(res.data);
          onHtmlReady && onHtmlReady();
          setMessageLoaded(true);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message?.id]);

    const keepReadingEnabled = providedKeepReadingEnabled && !isPublicSharePage && isUserMessageOwner && messageHtml;

    const noOutline = showCard ? {} : { outline: "none" };

    function handleDelete() {
      message && onClickDelete(message.message_id);
    }

    function handleArchive(showNotification) {
      if (!message) return;

      const doArchive = () => onClickArchive(message.message_id, message.archived, showNotification);

      // closing it before archiving better preserves the scroll position in the messages list
      if (expandable && expanded) {
        containerRef.current.scrollIntoView({ block: "start" });
        setTimeout(doArchive, 700);
      } else {
        doArchive();
      }
    }

    return (
      <Fragment>
        <FullWidthCard
          noCard={!showCard}
          ref={containerRef}
          minHeight={showCard && expanded && !messageLoaded ? "100vh" : "auto"}
          mt={mt}
          mb={mb}
          pb={expanded ? 3 : 0}
          tabIndex={index ? index + 1 : 0}
          data-hotkey-index={index}
          onKeyUp={(e) => {
            if (!isValidKeyboardShortcutEvent(e)) return;

            if (e.key === "Enter" || e.key === "o") {
              handleExpand();
            } else if (e.key === "Backspace") {
              handleDelete();
            } else if (e.key === "e") {
              handleArchive();
            } else if (onKeyUp) {
              onKeyUp(e);
            }
          }}
          style={{ scrollMargin: "40px", ...noOutline, ...style }}
        >
          {!loading && (
            <ExpandableIFrame
              key={swrKey}
              html={messageHtml}
              showCard={false}
              fullWidthCard={fullWidthCard}
              showFooter={expandable}
              expanded={expanded}
              onExpand={handleExpand}
              headerComponent={
                <InboxMessageHeader
                  isPublicSharePage={isPublicSharePage}
                  message={message}
                  subjectLinksToMessage={subjectLinksToMessage}
                  fullTitle={fullTitle}
                  keepReadingEnabled={keepReadingEnabled}
                  noDarkMode={noDarkMode}
                  showCard={showCard}
                  actionsEnabled={actionsEnabled}
                />
              }
              footerComponent={
                <InboxMessageFooter
                  message={message}
                  expanded={expanded}
                  handleExpand={handleExpand}
                  onClickDelete={handleDelete}
                  onClickArchive={handleArchive}
                  actionsEnabled={actionsEnabled}
                  onChangeReadingProgress={handleChangeReadingProgress}
                  itemActionsOverride={itemActionsOverride}
                  withReadLater={withReadLater}
                  withArchive={withArchive}
                  onLinkSaved={() => handleArchive(false)}
                >
                  {keepReadingEnabled && expandable && (
                    <KeepReadingButton
                      kind="inbox_message"
                      initialReadingProgress={message.reading_progress}
                      id={message.message_id}
                      onClick={() => setExpanded(true)}
                      objRef={containerRef}
                      saveEnabled={expanded}
                      source="Newsletters Page"
                      scrollElement={modalRef?.current || window}
                      ref={keepReadingButtonRef}
                      zIndex={keepReadingZIndex}
                      expandable={expandable}
                      extraOffset={keepReadingExtraOffset}
                    />
                  )}
                </InboxMessageFooter>
              }
              isNewsletter
              isTextOnly={message?.content_type === 1}
              transparent={false}
              nonExpandedHeight={height}
            />
          )}
          {typeof children === "function" ? children(message) : children}
          {keepReadingEnabled && !expandable && (
            <KeepReadingButton
              kind="inbox_message"
              id={message.message_id}
              initialReadingProgress={message.reading_progress}
              onClick={() => setExpanded(true)}
              objRef={containerRef}
              fixedPosition
              inModal={modalRef?.current}
              delay={1000}
              source="Inbox Message Page"
              scrollElement={modalRef?.current || window}
              ref={keepReadingButtonRef}
              zIndex={keepReadingZIndex}
              expandable={expandable}
              extraOffset={keepReadingExtraOffset}
            />
          )}
        </FullWidthCard>
        {typeof externalChildren === "function" ? externalChildren(message) : externalChildren}
      </Fragment>
    );
  }
);

const InboxMessageHeader = ({
  message,
  subjectLinksToMessage,
  fullTitle,
  keepReadingEnabled,
  isPublicSharePage,
  noDarkMode,
  showCard,
  actionsEnabled,
}) => {
  const config = useConfig();
  const _breakpointHit = useBreakpoint();
  const breakpointHit = showCard ? _breakpointHit : false;
  const user = useSelector(authUserSelector);

  const color = noDarkMode ? "black" : config.colors.c1;

  if (!message) return null;

  return (
    <Stack vertical align={breakpointHit ? "left" : "center"} w="100%" gap={0} mb={3}>
      {subjectLinksToMessage ? (
        <EmailSubject breakpointHit={breakpointHit} fullTitle={fullTitle} color={color}>
          <Link href={message?.read_url_relative ?? ""}>
            <UnstyledA>{message.subject}</UnstyledA>
          </Link>
        </EmailSubject>
      ) : (
        <EmailSubject breakpointHit={breakpointHit} fullTitle={fullTitle} color={color}>
          {message.subject}
        </EmailSubject>
      )}
      <Stack width="100%" my={2.5} align={breakpointHit ? "left" : "center"} noWrap overflow="hidden">
        <InlineMessageSender message={message} color={color} actionsEnabled={actionsEnabled} />
        {user && !isPublicSharePage && (
          <Stack noWrap>
            <a href={`mailto:${message.sender_email}?subject=Re:%20${encodeURI(message.subject)}`}>
              <SmallSecondaryButton noLabelOnMobile tooltip="Reply to author" icon="reply">
                Reply
              </SmallSecondaryButton>
            </a>
            <InboxMessageShareButton message={message} />
          </Stack>
        )}
      </Stack>
      {keepReadingEnabled && <ReadingProgressIndicator progress={message.reading_progress} />}
    </Stack>
  );
};

const InboxMessageShareButton = ({ message }) => {
  const handleShare = useHandleShareUrl();

  function handleClick() {
    fetchNewsletterShareLink(message.message_id, handleShare);
  }

  return (
    <SmallSecondaryButton noLabelOnMobile icon="shareAlt" onClick={handleClick}>
      Share
    </SmallSecondaryButton>
  );
};

const InlineMessageSender = ({ message, color, actionsEnabled }) => {
  const config = useConfig();
  const user = useSelector(authUserSelector);

  const Content = () => (
    <Stack gap={1.7} vAlign="center" noWrap overflow="hidden" maxW="100%">
      <img
        src={message.avatar_url}
        alt={message.sender_name}
        style={{
          flex: "0 0 22px",
          width: "22px",
          height: "22px",
          borderRadius: "22px",
          boxShadow: "inset 0px 0px 1px rgba(0,0,0,0.7)",
        }}
      />
      <P1 size="14px" bold color={color ?? config.colors.c1} noWrap overflow="hidden">
        {message.sender_name}
      </P1>
      <P1 align="center" size="14px" color={config.colors.c4} noWrap>
        {fromNow(message.date, true)}
      </P1>
    </Stack>
  );

  if (user && actionsEnabled) {
    return (
      <SenderDropDown id={message.message_id} style={{ maxWidth: "100%", overflow: "hidden" }}>
        <Content />
      </SenderDropDown>
    );
  } else {
    return <Content />;
  }
};

const InboxMessageFooter = ({
  message,
  expanded,
  handleExpand,
  onClickDelete,
  onClickArchive,
  actionsEnabled,
  children,
  itemActionsOverride,
  withReadLater,
  withArchive,
  onLinkSaved,
}) => {
  const config = useConfig();
  const breakpointHit = useBreakpoint(TAB_BAR_BREAKPOINT);

  return (
    <Grid columns="1fr auto 1fr" position="relative">
      {children}
      <div />
      <Stack align="center">
        <Button
          small={breakpointHit}
          icon={expanded ? "chevronUp" : "chevronDown"}
          variant={!expanded ? "white" : null}
          color={expanded ? config.colors.c3 : undefined}
          onClick={handleExpand}
        >
          {expanded ? "Close" : "Read"}
        </Button>
      </Stack>
      <Stack align="right" noWrap>
        {actionsEnabled && !itemActionsOverride && (
          <ItemActionButtons
            title={message.subject}
            url={message.read_url}
            onDeleteClick={onClickDelete}
            withArchive={withArchive}
            onArchiveClick={onClickArchive}
            isArchived={message.archived}
            withReadLater={withReadLater}
            onLinkSaved={onLinkSaved}
          />
        )}
        {itemActionsOverride && itemActionsOverride}
      </Stack>
    </Grid>
  );
};

const EmailSubject = ({ fullTitle, children, breakpointHit, ...otherProps }) => {
  const config = useConfig();
  return (
    <H4
      align={breakpointHit ? "left" : "center"}
      color={config.colors.c1}
      noWrap={!fullTitle}
      width="100%"
      overflow={fullTitle ? "auto" : "hidden"}
      {...otherProps}
    >
      {children}
    </H4>
  );
};

export default InboxMessageView;
