/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button, notif } from "@mailbrew/uikit";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyButton = (props) => {
  const { copyText, onCopy, children, icon, ...otherProps } = props;

  return (
    <CopyToClipboard
      text={copyText}
      onCopy={() => {
        onCopy && onCopy();
        notif.success("Copied");
      }}
    >
      <Button noStretch icon={icon} {...otherProps}>
        Copy
      </Button>
    </CopyToClipboard>
  );
};

export default CopyButton;
