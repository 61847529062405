import { useEventListener } from "@mailbrew/uikit";
import { useRef, useState } from "react";

export default function useIsAtTopOrBottomOfScroll({
  scrollElement,
  offset = 100,
  initialState = true,
  showOnScrollUp = true,
}) {
  const [isTopOrBottom, setIsTopOrBottom] = useState(initialState);

  const isAtTop = useRef(initialState);
  const isAtBottom = useRef(false);
  const isScrollingUp = useRef(false);

  const prevScrollY = useRef(0);

  useEventListener(
    "scroll",
    (e) => {
      let { scrollTop, clientHeight: windowHeight, scrollHeight: totalHeight } = e.target;

      if (!scrollElement && typeof window !== undefined) {
        scrollTop = window.scrollY;
        windowHeight = window.innerHeight;
        totalHeight = document.documentElement.scrollHeight;
      }

      isAtTop.current = scrollTop < offset;
      isAtBottom.current = scrollTop + windowHeight > totalHeight - offset;

      if (scrollTop - prevScrollY.current < -20) {
        isScrollingUp.current = true;
      }

      if (scrollTop - prevScrollY.current > 20 && !isAtBottom.current) {
        isScrollingUp.current = false;
      }

      if (showOnScrollUp) {
        setIsTopOrBottom(isAtTop.current || isAtBottom.current || isScrollingUp.current);
      } else {
        setIsTopOrBottom(isAtTop.current || isAtBottom.current);
      }

      prevScrollY.current = scrollTop;
    },
    scrollElement,
    { debounceDelay: 25 }
  );

  return [isTopOrBottom, setIsTopOrBottom];
}
