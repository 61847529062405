/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Input, RadioGroup, Spacer } from "@mailbrew/uikit";
import EditorSubpage from "components/editor/EditorSubpage";
import InfoText from "components/editor/InfoText";
import SettingSwitchRow from "components/editor/SettingSwitchRow";
import SourceEditorSection from "components/editor/SourceEditorSection";
import Fuse from "fuse.js";
import { useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import useSWR from "swr";
import { move } from "utils/array";
import { AssetRow } from "./StocksSourceEditor";

const CryptoSourceEditor = (props) => {
  const { source, sourceIndex } = props;
  const { display, assets, show_holding_value } = source;

  const dispatch = useDispatch();

  const { data: cryptoList } = useSWR("/crypto_list/", { revalidateOnFocus: false });

  const handleAddAsset = (s) => {
    if (!assets.find((el) => el.symbol === s.symbol)) {
      const newAssets = [...assets, { symbol: s.symbol, name: s.name, currency: s.currency, holding: null }];
      dispatch(updateSourceField(sourceIndex, "assets", newAssets));
    }
  };

  const handleRemoveAsset = (symbol) => {
    const newAssets = assets.filter((s) => s.symbol !== symbol);
    dispatch(updateSourceField(sourceIndex, "assets", newAssets));
  };

  const handleChangeHolding = (asset, holding) => {
    const updatedStockIndex = assets.findIndex((s) => s.symbol === asset.symbol);
    const newAssets = assets.slice();
    newAssets.splice(updatedStockIndex, 1, { ...asset, holding });
    dispatch(updateSourceField(sourceIndex, "assets", newAssets));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newAssets = move(assets, result.source.index, result.destination.index);
    dispatch(updateSourceField(sourceIndex, "assets", newAssets));
  };

  return (
    <EditorSubpage type={source.type} sourceIndex={sourceIndex}>
      <SourceEditorSection title="Title" icon="writeBold" noPadding noBorderTop>
        <Input
          name="title"
          placeholder="Crypto"
          type="text"
          autoComplete="off"
          value={source.title}
          onChange={(e) => dispatch(updateSourceField(sourceIndex, "title", e.target.value))}
        />
      </SourceEditorSection>

      <SourceEditorSection title="Your Crypto" subtitle="Search and add crypto currencies here." icon="bitcoin">
        <CryptoSearchField
          cryptoList={cryptoList}
          placeholder="Bitcoin or BTC"
          autoFocus={true}
          onSuggestionClick={(searchResult) => {
            handleAddAsset(searchResult);
          }}
        />
        {assets.length > 0 && <Spacer />}
        {assets?.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {assets.map((asset, index) => (
                    <Draggable key={`${asset.symbol}`} draggableId={`${asset.symbol}-${index}`} index={index}>
                      {(provided) => (
                        <AssetRow
                          title={asset.name}
                          holding={asset.holding}
                          onChangeHolding={(holding) => handleChangeHolding(asset, holding)}
                          onRemoveClick={() => handleRemoveAsset(asset.symbol)}
                          provided={provided}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {assets?.length > 0 && (
          <InfoText
            label={'How does the "holding" field work?'}
            modalIcon="growth"
            modalTitle="Track your portfolio"
            modalBody={`If you add the number of coins you own, we'll show your holding value for each asset, and you'll also get an extra card with <strong>your total portfolio value</strong>.<br/><br/>Make sure all your assets refer to a single currency (we don't do currency conversion).`}
            image="crypto-explainer.png"
          />
        )}
      </SourceEditorSection>

      <SourceEditorSection
        title="Display"
        subtitle="If you add one or more holdings, you can display a total portfolio value alongside individual assets."
        icon="eye"
      >
        <RadioGroup
          options={["asset_portfolio", "asset", "portfolio"]}
          optionsNames={["Assets and Portfolio", "Assets", "Portfolio"]}
          selectedOption={display}
          onSelect={(mode) => {
            dispatch(updateSourceField(sourceIndex, "display", mode));
          }}
        />
        <Spacer size={6} />
        <SettingSwitchRow
          state={show_holding_value}
          onChange={(on) => dispatch(updateSourceField(sourceIndex, "show_holding_value", on))}
          copy="Show holding value instead of asset price"
          icon="money"
        />
      </SourceEditorSection>
    </EditorSubpage>
  );
};

const CryptoSearchField = (props) => {
  const { cryptoList, onSuggestionClick, width = "100%", placeholder } = props;
  const [query, setQuery] = useState("");

  const [suggestions, setSuggestions] = useState([]);
  const [suggestionNames, setSuggestionNames] = useState([]);
  const [suggestionDetails, setSuggestionDetails] = useState([]);
  const [suggestionPayloads, setSuggestionPayloads] = useState([]);

  const fuse = useMemo(() => {
    if (!cryptoList) return;
    return new Fuse(cryptoList, { keys: ["symbol", "name"], shouldSort: false, threshold: 0.2 });
  }, [cryptoList]);

  if (!fuse) {
    return <Input width={width} type="text" autoComplete="off" icon="search" loading placeholder="Loading tokens..." />;
  }

  return (
    <Input
      width={width}
      value={query}
      onChange={(e) => {
        const query = e.target.value;
        setQuery(query);
        const results = fuse.search(query).slice(0, 10);

        setSuggestions(results.map((r) => r.item.name));
        setSuggestionNames(results.map((r) => r.item.name));
        setSuggestionDetails(results.map((r) => r.item.symbol));
        setSuggestionPayloads(results.map((r) => r.item));
      }}
      type="text"
      autoComplete="off"
      icon="search"
      placeholder={placeholder}
      suggestions={suggestions}
      suggestionsNames={suggestionNames}
      suggestionsDetails={suggestionDetails}
      suggestionsIconsSize="2em"
      suggestionsImagesSize="2em"
      onSuggestionClick={(_, index) => {
        setQuery("");
        onSuggestionClick && onSuggestionClick(suggestionPayloads[index]);
      }}
    />
  );
};

export default CryptoSourceEditor;
