import { Modal } from "@mailbrew/uikit";
import { useRouter } from "next/router";
import { useEffect } from "react";
import urls from "urls";
import Paywall from "./Paywall";

export default function PaywallModal({ show, setShow }) {
  const router = useRouter();

  // automatically show on success so we can handle the completion from everywhere
  useEffect(() => {
    const checkoutSessionID = router.query.checkout_id;
    if (checkoutSessionID && router.pathname !== urls.upgrade()) {
      setShow(true);
    }
  }, [router.pathname, router.query.checkout_id, setShow]);

  return (
    <Modal closeButton={{ offset: -10 }} bottomSheet bottomSheetWidth="800px" pb={6} show={show} setShow={setShow}>
      <Paywall inModal={true} onKeepBrewingClick={() => setShow(false)} maxWidth="720px" />
    </Modal>
  );
}
