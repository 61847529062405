import { Button, Stack, useBreakpoint, useConfig } from "@mailbrew/uikit";
import api from "dependencies/api";
import { AnimatePresence, motion } from "framer-motion";
import useEventListener from "hooks/useEventListener";
import { forwardRef, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { mutate } from "swr";
import { useDebouncedCallback } from "use-debounce/lib";
import plausible from "utils/plausible";
import { TAB_BAR_BREAKPOINT } from "./Page";

const KeepReadingButton = forwardRef((props, ref) => {
  const {
    id, // id of the brew/inbox_message
    kind, // "brew" or "inbox_message"
    initialReadingProgress = 0,
    objRef, // The element you want to keep track of
    onClick,
    saveEnabled = true, // A condition to enable the tracking behavior
    fixedPosition, // To make the position fixed in the page
    inModal,
    delay, // Extra delay before showing the button
    scrollElement = window,
    expandable = false,
    extraOffset = 0,
    zIndex = "1",
  } = props;

  const config = useConfig();
  const breakpointHit = useBreakpoint(TAB_BAR_BREAKPOINT);

  /* ---------------------------------- State --------------------------------- */

  const [scrollPosition, setScrollPosition] = useState(0);
  const [elementHeight, setElementHeight] = useState(null);
  // Scroll position from the reading progress (%).
  const [savedReadingProgress, setSavedReadingProgress] = useState(initialReadingProgress || 0);
  const savedScrollPosition = Math.max(Math.max(elementHeight - window.innerHeight, 0) * savedReadingProgress - 20, 0);
  const readingProgress = Math.max(
    Math.min(parseFloat((scrollPosition / (elementHeight - window.innerHeight)).toFixed(2)) || 0, 1),
    0
  );

  const finishedReading = savedReadingProgress > 0.8;

  const [clickedBeforeSavedEnabled, setClickedBeforeSavedEnabled] = useState(false);

  const [showKeepReading, setShowKeepReading] = useState(false);

  // I need to know where the object is in the page to scroll correctly
  const brewOffsetTop = useMemo(() => {
    if (objRef.current) {
      return objRef.current.offsetTop;
    } else {
      return 0;
    }
    // Also recompute when saveEnabled changes to account for possible layout shifts in the page
    // (saveEnabled changes when opening/closing the issue/newsletter reader).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objRef, saveEnabled]);

  // this takes care of hiding the "keep reading" button when marking a message as read
  useEffect(() => {
    setSavedReadingProgress(initialReadingProgress);
  }, [initialReadingProgress]);

  /* --------------------------- Get Element Height --------------------------- */

  useEffect(() => {
    if (saveEnabled && objRef.current && !finishedReading) {
      const timeout = setTimeout(() => {
        if (objRef.current) {
          const height = Math.round(objRef.current.offsetHeight);
          setElementHeight(height);
        }
      }, 1000);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objRef.current, saveEnabled]);

  /* ---------------------------- Handle scroll --------------------------- */

  useEventListener(
    "scroll",
    (e) => {
      if (saveEnabled && objRef.current && elementHeight && !finishedReading) {
        const currentScrollPosition = -Math.round(objRef.current.getBoundingClientRect().top);

        if (currentScrollPosition > 0) {
          setScrollPosition(currentScrollPosition);
        }
      }
    },
    scrollElement
  );

  /* -------------------------- Reset Scroll Position ------------------------- */

  useEffect(() => {
    if (!saveEnabled) {
      setScrollPosition(0);
    }
  }, [saveEnabled]);

  /* --------------------- Sync Reading State -------------------- */

  const readableItemSwrKey =
    kind === "brew" ? `/issues/${id}/` : kind === "inbox_message" ? `/inbox_source_messages/${id}/` : null;

  const [debouncedSyncProgress] = useDebouncedCallback((progress) => {
    api.patch(readableItemSwrKey, { reading_progress: progress }).then(() => {
      setSavedReadingProgress(progress);
      mutate(readableItemSwrKey);
    });
  }, 500);

  useEffect(() => {
    if (!saveEnabled || !objRef.current) return;
    // Don't do anything if there's a previously saved HIGHER scrolled position
    if (savedReadingProgress >= readingProgress) return;
    // If there's a scroll and not finished reading
    if (readingProgress && !finishedReading) {
      debouncedSyncProgress(readingProgress);
    }
  }, [
    debouncedSyncProgress,
    readableItemSwrKey,
    finishedReading,
    id,
    objRef,
    readingProgress,
    saveEnabled,
    savedReadingProgress,
  ]);

  /* ---------------------------- Show/hide button --------------------------- */

  useEffect(() => {
    // Show Keep Reading button when relevant
    if (!isNaN(savedReadingProgress) && savedReadingProgress > 0 && !finishedReading) {
      const timeout = setTimeout(() => {
        setShowKeepReading(true);
      }, delay);

      return () => {
        window.clearTimeout(timeout);
      };
    } else {
      setShowKeepReading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveEnabled, id, delay, finishedReading]);

  // Hide Keep Reading button when you cross the saved scroll position
  useEffect(() => {
    if (savedReadingProgress && scrollPosition && scrollPosition > savedScrollPosition) {
      setShowKeepReading(false);
    }
  }, [saveEnabled, savedReadingProgress, scrollPosition, savedScrollPosition]);

  /* ------------------------------ handle scroll ----------------------------- */

  function handleButtonClick() {
    plausible.track("Click on Keep Reading", { kind });
    if (!saveEnabled) {
      /**
       * If user clicks on the Keep Reading button before it's enabled, just trigger the
       * external onClick behaviour. The use next useEffect makes sure the rest of the
       * behaviour is triggered next.
       * */
      onClick && onClick();
      setClickedBeforeSavedEnabled(true);
    } else {
      // Scroll to the last read position if available
      setShowKeepReading(false);
      // parseFloat((scrollPosition / (elementHeight - window.innerHeight)).toFixed(2)) || 0,
      scrollElement.scrollTo({ top: brewOffsetTop + savedScrollPosition });
    }
  }

  // Now scroll to appropriate position after the onClick is done
  useEffect(() => {
    if (clickedBeforeSavedEnabled && saveEnabled && savedScrollPosition) {
      setShowKeepReading(false);
      scrollElement.scrollTo({ top: brewOffsetTop + savedScrollPosition });
      setClickedBeforeSavedEnabled(false);
    }
  }, [brewOffsetTop, clickedBeforeSavedEnabled, saveEnabled, savedScrollPosition, scrollElement]);

  const tabBarOffset = fixedPosition && breakpointHit && !inModal ? parseInt(config.TabBar.height) : 0;

  const position = tabBarOffset ?? 30;

  const KeepReadingInner = (
    <AnimatePresence>
      {showKeepReading && (
        <Stack
          key="btn"
          as={motion.div}
          initial={{ bottom: position + extraOffset, opacity: 0 }}
          animate={{ bottom: position + 15 + extraOffset, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          transition={{
            type: "spring",
            duration: 0.6,
            bounce: 0.15,
            delay: showKeepReading ? 0.5 : 0,
          }}
          position={fixedPosition ? "fixed" : "absolute"}
          left="0"
          right="0"
          align="center"
          zIndex={zIndex}
        >
          <Button
            ref={ref}
            mb={expandable ? 8 : "env(safe-area-inset-bottom)"}
            icon="arrowDown"
            variant="small"
            color="#337bff"
            onClick={handleButtonClick}
            radius="50px"
            size="14px"
          >
            Keep Reading
          </Button>
        </Stack>
      )}
    </AnimatePresence>
  );

  if (fixedPosition) {
    return window && ReactDOM.createPortal(KeepReadingInner, document.querySelector("body"));
  } else {
    return KeepReadingInner;
  }
});

export default KeepReadingButton;
