/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { HStack } from "@mailbrew/uikit";
import tinycolor from "tinycolor2";

const ColorPicker = (props) => {
  const { selectedColor, palette, onChange } = props;

  const selectedIndex = colorPickerPalette[palette].indexOf(selectedColor);

  return (
    <HStack style={{ width: "100%", marginTop: "0.3em" }} align="spaced" vAlign="center" gap={0}>
      {colorPickerPalette[palette].map((color, index) => {
        const active = selectedIndex === index;

        const bg = editColor(color, { luminance: 0.6 });
        const border = editColor(color, { luminance: 0.4 });

        return (
          <div
            key={index}
            css={css`
              width: 1em;
              height: 1em;
              border-radius: 1em;
              background: ${bg};
              border: 2px solid ${active ? border : bg};
              cursor: pointer;
              transform: scale(${active ? 1.15 : 1});
              opacity: ${active ? 1 : 0.6};
              transition: 0.15s;
              :hover {
                transform: scale(1.2);
              }
            `}
            onClick={() => onChange(colorPickerPalette[palette][index])}
          />
        );
      })}
    </HStack>
  );
};

export const colorPickerPalette = {
  quote: [
    "hsl(0, 28%, 96%)",
    "hsl(36, 28%, 96%)",
    "hsl(72, 28%, 96%)",
    "hsl(108, 28%, 96%)",
    "hsl(144, 28%, 96%)",
    "hsl(180, 28%, 96%)",
    "hsl(216, 28%, 96%)",
    "hsl(252, 28%, 96%)",
    "hsl(288, 28%, 96%)",
    "hsl(324, 28%, 96%)",
    "hsl(231, 11%, 96%)",
  ],
  today: [
    "hsl(352, 90%, 58%)",
    "hsl(20, 90%, 55%)",
    "hsl(40, 90%, 50%)",
    "hsl(72, 90%, 45%)",
    "hsl(108, 90%, 42%)",
    "hsl(180, 87%, 46%)",
    "hsl(216, 92%, 60%)",
    "hsl(252, 90%, 65%)",
    "hsl(288, 90%, 61%)",
    "hsl(310, 90%, 60%)",
    "hsl(231, 11%, 35%)",
  ],
};

export function editColor(color, props) {
  const { saturation, luminance, alpha } = props || {};

  const hsv = tinycolor(color).toHsl();

  if (saturation) {
    hsv.s = saturation;
  } else {
    if (hsv.s > 0.7) hsv.s = 0.7;
  }

  if (luminance) hsv.l = luminance;
  if (alpha) hsv.a = alpha;

  return tinycolor(hsv).toHslString();
}

export default ColorPicker;
