import { Card, Grid, Icon, Input, P1, RadioGroup, Spacer, useConfig } from "@mailbrew/uikit";
import debounce from "just-debounce-it";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import api from "dependencies/api";
import useInputValidator from "hooks/useInputValidator";
import useRandomItem from "hooks/useRandomItem";
import SettingSwitchRow from "components/editor/SettingSwitchRow";
import { updateSourceField } from "reducers/newslettersReducer";
import { move } from "utils/array";
import { handleNetworkError } from "utils/networkErrors";
import { floatOrEmptyValidator } from "utils/numberValidator";
import EditorSubpage from "components/editor/EditorSubpage";
import SearchField from "components/editor/SearchField";
import { DragHandle } from "components/editor/SourceCard";
import SourceEditorSection from "components/editor/SourceEditorSection";
import InfoText from "components/editor/InfoText";

const StocksSourceEditor = (props) => {
  const { source, sourceIndex } = props;
  const { display, stocks, show_holding_value } = source;

  const dispatch = useDispatch();
  const sourceIsValid = source.stocks.length > 0 || source.title?.length > 0;
  const placeholder = useRandomItem(placeholders);

  const handleAddStock = (s) => {
    if (!stocks.find((el) => el.symbol === s.symbol)) {
      const newStocks = [...stocks, { security_name: s.security_name, logo: s.logo, symbol: s.symbol, holding: null }];
      dispatch(updateSourceField(sourceIndex, "stocks", newStocks));
    }
  };

  const handleRemoveStock = (symbol) => {
    const newStocks = stocks.filter((s) => s.symbol !== symbol);
    dispatch(updateSourceField(sourceIndex, "stocks", newStocks));
  };

  const handleChangeHolding = (stock, holding) => {
    const updatedStockIndex = stocks.findIndex((s) => s.symbol === stock.symbol);
    const newStocks = stocks.slice();
    newStocks.splice(updatedStockIndex, 1, { ...stock, holding });
    dispatch(updateSourceField(sourceIndex, "stocks", newStocks));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newStocks = move(stocks, result.source.index, result.destination.index);
    dispatch(updateSourceField(sourceIndex, "stocks", newStocks));
  };

  return (
    <EditorSubpage type={source.type} sourceIndex={sourceIndex} sourceIsValid={sourceIsValid}>
      <SourceEditorSection title="Title" icon="writeBold" noPadding noBorderTop>
        <Input
          name="title"
          placeholder="Stocks"
          type="text"
          autoComplete="off"
          value={source.title}
          onChange={(e) => dispatch(updateSourceField(sourceIndex, "title", e.target.value))}
        />
      </SourceEditorSection>

      <SourceEditorSection title="Your Stocks" subtitle="Search and add stocks here." icon="currency">
        <SearchField
          placeholder={placeholder}
          autoFocus={true}
          fetchSuggestions={fetchSuggestions}
          onSuggestionClick={(_, __, searchResult) => {
            handleAddStock(searchResult);
          }}
          autofillSuggestionClick="empty"
        />
        {stocks.length > 0 && <Spacer />}
        {stocks?.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {stocks.map((stock, index) => (
                    <Draggable key={`${stock.symbol}`} draggableId={`${stock.symbol}-${index}`} index={index}>
                      {(provided) => (
                        <AssetRow
                          title={stock.symbol}
                          imageURL={stock.logo}
                          holding={stock.holding}
                          onChangeHolding={(holding) => handleChangeHolding(stock, holding)}
                          onRemoveClick={() => handleRemoveStock(stock.symbol)}
                          provided={provided}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {stocks?.length > 0 && (
          <InfoText
            label={'How does the "holding" field work?'}
            modalIcon="growth"
            modalTitle="Track your portfolio"
            modalBody={`If you add the number of shares you own, we'll show your holding value for each stock, and you'll also get an extra card with <strong>your total portfolio value</strong>.`}
          />
        )}
      </SourceEditorSection>

      <SourceEditorSection
        title="Display"
        subtitle="If you add one or more holdings, you can display a total portfolio value alongside individual stocks."
        icon="eye"
      >
        <RadioGroup
          options={["stocks_portfolio", "stocks", "portfolio"]}
          optionsNames={["Stocks and Portfolio", "Stocks", "Portfolio"]}
          selectedOption={display}
          onSelect={(mode) => {
            dispatch(updateSourceField(sourceIndex, "display", mode));
          }}
        />
        <Spacer size={6} />
        <SettingSwitchRow
          state={show_holding_value}
          onChange={(on) => dispatch(updateSourceField(sourceIndex, "show_holding_value", on))}
          copy="Show holding value instead of stock price"
          icon="money"
        />
      </SourceEditorSection>
    </EditorSubpage>
  );
};

export const AssetRow = ({ title, imageURL, holding: providedHolding, onRemoveClick, onChangeHolding, provided }) => {
  const config = useConfig();

  const { style: draggableStyle, ...otherDraggableProps } = provided?.draggableProps ?? {};

  const [holding, setHolding, holdingError] = useInputValidator(providedHolding, floatOrEmptyValidator, (value) => {
    const holdingValue = value.length ? parseFloat(value) : null;
    onChangeHolding(holdingValue);
  });

  const gridColumns = imageURL ? "auto 1fr auto auto auto" : "1fr auto auto auto";

  return (
    <Card
      inline
      mb={2}
      px={1}
      py={0}
      radius={config.appearance.smallRadius}
      h="44px"
      style={{ ...draggableStyle }}
      flex
      ai="center"
      ref={provided?.innerRef}
      {...otherDraggableProps}
    >
      <Grid columns={gridColumns} vAlign="center" width="100%" gap={3} px={2}>
        {imageURL && (
          <img
            src={imageURL}
            alt={title}
            style={{ width: "1.6em", height: "1.6em", borderRadius: "4px", objectFit: "contain" }}
          />
        )}
        <P1>{title}</P1>
        <Input
          type="number"
          wrapperStyle={{ height: "30px", background: "none", borderRadius: "7px" }}
          style={{ textAlign: "right" }}
          fontSize="15px"
          small
          placeholder={title.includes("Bitcoin to") ? "Hodling" : "Holding"}
          value={holding ?? ""}
          width={"6em"}
          onChange={(e) => setHolding(e.target.value)}
          error={!!holdingError}
        />
        <RemoveButton onClick={onRemoveClick} />
        <DragHandle color={config.colors.c3} {...provided?.dragHandleProps} />
      </Grid>
    </Card>
  );
};

const RemoveButton = ({ onClick, disabled }) => {
  const config = useConfig();
  return (
    <Icon
      color={disabled ? config.colors.c5 : config.colors.accent1}
      name="minusBold"
      size="20px"
      onClick={disabled ? undefined : onClick}
    />
  );
};

const fetchSuggestions = debounce(
  async (
    query,
    {
      setSuggestions,
      setSuggestionsNames,
      setSuggestionsDetails,
      setSuggestionsImages,
      setSuggestionsPayloads,
      setLoading,
      setError,
    }
  ) => {
    if (!query || query.length <= 2) {
      setSuggestions([]);
      setError(null);
      return;
    }

    setLoading(true);

    try {
      setError("");
      const res = await api.get("/search_stock/", {
        params: { q: query },
      });
      setSuggestions(res.data.map((s) => s.symbol));
      setSuggestionsNames(res.data.map((s) => s.symbol));
      setSuggestionsImages(res.data.map((s) => s.logo));
      setSuggestionsDetails(res.data.map((s) => s.security_name));
      setSuggestionsPayloads(res.data);
    } catch (err) {
      const handled = handleNetworkError(err);
      if (handled) return;
      const errorMessage = err.response.data.detail || "Can't find this stock right now.";
      setSuggestions([]);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  },
  300
);

// prettier-ignore
const placeholders = [
  "AAPL or Apple",
  "AMZN or Amazon",
  "AMZN or Microsoft",
  "TSLA or Tesla",
];

export default StocksSourceEditor;
