import { useStripe } from "@stripe/react-stripe-js";
import { usePaywallState } from "components/PaywallStateProvider";
import api from "dependencies/api";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "reducers/authReducer";
import plausible from "utils/plausible";

export default function useStripeCheckout({ freeTrialEnabled, handlesCompletion, redirectPath }) {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [upgradeError, setUpgradeError] = useState(null);
  const { setJustUpgraded } = usePaywallState();

  const handleUpgrade = ({ priceId, coupon = undefined, trial_period_days = undefined }) => {
    plausible.track("Start Checkout", {
      price_id: priceId,
      couponId: coupon?.id,
    });

    setUpgradeLoading(true);
    setUpgradeError(null);

    api
      .post("/stripe_checkout_session/", {
        price_id: priceId,
        coupon: coupon?.id,
        free_trial: freeTrialEnabled,
        trial_period_days,
        redirect_path: redirectPath,
      })
      .then((res) => {
        const session_id = res.data.session_id;
        stripe.redirectToCheckout({ sessionId: session_id });
      })
      .catch((err) => {
        const msg = err?.response?.data?.detail || "Can't upgrade. Please retry.";
        setUpgradeError(msg);
      })
      .finally(() => {
        setUpgradeLoading(false);
      });
  };

  const router = useRouter();
  const checkoutSessionID = router.query.checkout_id;

  useEffect(() => {
    if (!handlesCompletion) return;
    if (!checkoutSessionID || checkoutSessionID.length <= 0) return;

    setUpgradeLoading(true);

    api
      .post("/complete_stripe_checkout_session/", { session_id: checkoutSessionID })
      .then((res) => {
        const user = res.data;
        dispatch(setUser(user));
        setJustUpgraded(true);
      })
      .finally(() => setUpgradeLoading(false));
  }, [checkoutSessionID, dispatch, handlesCompletion, setJustUpgraded]);

  return { handleUpgrade, upgradeLoading, upgradeError };
}
