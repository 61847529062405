import { useDispatch } from "react-redux";
import { updateSourceField } from "reducers/newslettersReducer";
import EditorSubpage from "components/editor/EditorSubpage";
import SourceEditorSection from "components/editor/SourceEditorSection";
import ColorPicker from "components/editor/ColorPicker";

const TodaySourceEditor = (props) => {
  const { source, sourceIndex } = props;

  const dispatch = useDispatch();

  return (
    <EditorSubpage title="Today" type="today" showAddAnother={false} sourceIndex={sourceIndex}>
      <SourceEditorSection title="Accent Color" icon="bucket" noBorderTop>
        <ColorPicker
          palette="today"
          selectedColor={source.accent_color}
          onChange={(color) => {
            dispatch(updateSourceField(sourceIndex, "accent_color", color));
          }}
        />
      </SourceEditorSection>
    </EditorSubpage>
  );
};

export default TodaySourceEditor;
