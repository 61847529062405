import { Input, SmallText } from "@mailbrew/uikit";
import EditorSubpage from "components/editor/EditorSubpage";
import LayoutOptions from "components/editor/LayoutOptions";
import NumPostsSourceEditor from "components/editor/NumPostsSourceEditor";
import SearchField from "components/editor/SearchField";
import SourceEditorSection from "components/editor/SourceEditorSection";
import api from "dependencies/api";
import useRandomItem from "hooks/useRandomItem";
import debounce from "just-debounce-it";
import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { resetError } from "reducers/appReducer";
import { updateSourceField } from "reducers/newslettersReducer";
import { handleNetworkError } from "utils/networkErrors";

const YouTubePlaylistSourceEditor = (props) => {
  const { source, sourceIndex, dispatch } = props;

  const sourceIsValid = source?.playlist_id;

  const placeholder = useRandomItem([
    "Marques Brownlee",
    "The Verge",
    "CNET",
    "Austin Evans",
    "Google Tech Talks",
    "Unbox Therapy",
    "TED",
    "Vsauce",
    "Linus Tech Tips",
    "NASA",
    "Jonathan Morrison",
    "The Nerdwriter",
    "Wisecrack",
    "FilmmakerIQ",
    "PewDiePie",
    "No Small Parts",
    "Fandor",
    "Chris Stuckmann",
    "First Ten Minutes",
    "Glowing Screens",
    "Folding Ideas",
    "Kaptain Kristian",
    "Lessons from the screenplay",
  ]);

  useEffect(() => dispatch(resetError("youtube_playlist")), [dispatch, source]);

  return (
    <EditorSubpage title={source.title || "YouTube Channel"} type={source.type} sourceIndex={sourceIndex}>
      <SourceEditorSection
        title="YouTube Channel"
        subtitle="Enter the channel name to search it."
        icon="youtube"
        noBorderTop
      >
        <SearchField
          initialValue={source.title}
          placeholder={placeholder}
          autoFocus={!source.playlist_id}
          fetchSuggestions={fetchSuggestions}
          autofillSuggestionClick="name"
          onSuggestionClick={(playlistID, title) => {
            dispatch(updateSourceField(sourceIndex, "playlist_id", playlistID));
            dispatch(updateSourceField(sourceIndex, "title", title));
          }}
        />
        <SmallText mt={2}>Pro tip: you can also search for playlists.</SmallText>
      </SourceEditorSection>

      {sourceIsValid && (
        <Fragment>
          <SourceEditorSection title="Title" subtitle="We can take care of this for you." icon="writeBold">
            <Input
              name="title"
              placeholder="Title"
              type="text"
              autoComplete="off"
              value={source.title}
              onChange={(e) => dispatch(updateSourceField(sourceIndex, "title", e.target.value))}
            />
          </SourceEditorSection>

          <NumPostsSourceEditor
            title="Included Videos"
            source={source}
            sourceIndex={sourceIndex}
            fieldName="max_videos"
            min={1}
            max={20}
            shortExplanationBuilder={(num) =>
              num === 1
                ? "We'll send you the latest video from the channel."
                : `We'll send you the ${num} latest videos from the channel.`
            }
          />

          <SourceEditorSection>
            <LayoutOptions
              selected={source.columns || 1}
              onChange={(option) => dispatch(updateSourceField(sourceIndex, "columns", option))}
            />
          </SourceEditorSection>
        </Fragment>
      )}
    </EditorSubpage>
  );
};

const fetchSuggestions = debounce(
  async (
    query,
    {
      setSuggestions,
      setSuggestionsNames,
      setSuggestionsDetails,
      setSuggestionsIcons,
      setSuggestionsImages,
      setLoading,
      setError,
    }
  ) => {
    if (!query || query.length <= 2) {
      setSuggestions([]);
      setError(null);
      return;
    }

    setLoading(true);

    try {
      setError("");
      const res = await api.get("/youtube_search/", { params: { q: query } });

      const suggestionNames = res.data.map((searchRes) => {
        if (searchRes.kind === "channel") {
          return `${searchRes.title}`;
        } else if (searchRes.kind === "playlist") {
          return `${searchRes.channel_title} | ${searchRes.title}`;
        } else {
          return null;
        }
      });

      setSuggestions(res.data.map((s) => s.playlist_id));
      setSuggestionsImages(res.data.map((s) => s.kind === "channel" && s.thumbnails?.default.url));
      setSuggestionsIcons(res.data.map((s) => s.kind === "playlist" && "playlist"));
      setSuggestionsDetails(res.data.map((s) => s.description));
      setSuggestionsNames(suggestionNames);
    } catch (err) {
      const handled = handleNetworkError(err);
      if (handled) return;
      const errorMessage = err.response.data.detail || "Can't search Reddit right now.";
      setSuggestions([]);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  },
  1000
);

export default connect((state) => ({
  newsletter: state.newsletters.currentNewsletter,
  loading: state.app.loading,
  errors: state.app.errors,
}))(YouTubePlaylistSourceEditor);
