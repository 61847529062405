import { useEffect, useState } from "react";

export default function useDelayedValue(value, delay, options) {
  const [delayedValue, setDelayedValue] = useState(value);

  const { onlyDelayIf = true } = options;

  useEffect(() => {
    let timeout = setTimeout(
      () => {
        setDelayedValue(value);
      },
      onlyDelayIf ? delay : 0
    );
    return () => clearTimeout(timeout);
  }, [value, delay, onlyDelayIf]);

  return delayedValue;
}
