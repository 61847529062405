const baseConfig = {};

const darkConfig = {};

baseConfig.baseUnit = 0.25; // rem

baseConfig.colors = {
  c1: "var(--c1)",
  c2: "var(--c2)",
  c3: "var(--c3)",
  c4: "var(--c4)",
  c5: "var(--c5)",
  c6: "var(--c6)",
  buttonWhite: "var(--buttonWhite)",
  buttonWhiteText: "var(--buttonWhiteText)",
  uiBorderColor: "var(--uiBorderColor)",
  orange: "var(--orange)",
  lightOrange: "var(--lightOrange)",
  lightOrangeBorder: "var(--lightOrangeBorder)",
  red: "var(--red)",
  redBorder: "var(--redBorder)",
  bg0: "var(--bg0)",
  bg1: "var(--bg1)",
  bg2: "var(--bg2)",
  bg3: "var(--bg3)",
  bg4: "var(--bg4)",
  bg5: "var(--bg5)",
  tooltip: "var(--tooltip)",
  toolbarBackground: "var(--toolbarBackground)",
  accent1: "var(--accent1)",
  accent2: "var(--accent2)",
  accent3: "var(--accent3)",
  twitter: "var(--twitter)",
  error: "var(--error)",
  success: "var(--success)",
  shadowColor: "var(--shadowColor)",
  selection: "var(--selection)",
  focus: "var(--focus)",
  shortcuts: "var(--shortcuts)",
  beta: "var(--beta)",
};

baseConfig.fonts = {
  faces: {
    H1: `"new-spirit", Georgia, "Times New Roman", Times, serif`,
    H2: `"new-spirit", Georgia, "Times New Roman", Times, serif`,
    H3: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
    H4: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
    H5: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
  },
  sizes: {
    normal: "15.5px",
    P2: ["14.5px", "14px"],
    SmallText: ["14px", "13px"],
    H1: ["32px", "30px"],
    H2: ["28px", "26px"],
    H3: ["24px", "22px"],
    H4: ["22px", "20px"],
    H5: ["20px", "18px"],
    Button: "15px",
    Input: "15px",
    smallHeading: ["15.5px", "15px"],
    Badge: ["0.9em", "15px"],
  },
  colors: {
    P1: (c) => c.colors.c2,
    P2: (c) => c.colors.c3,
    H3: (c) => c.colors.c1,
    H4: (c) => c.colors.c2,
    H5: (c) => c.colors.c2,
    smallHeading: (c) => c.colors.c2,
    SmallText: (c) => c.colors.c3,
  },
  weights: {
    normal: 400,
    H1: 400,
    H2: 400,
    H3: 700,
    H4: 700,
    H5: 500,
    Button: 500,
    smallHeading: 500,
  },
  lineHeights: {
    P1: 1.5,
    P2: 1.45,
    H1: 1.3,
    H2: 1.3,
    H3: 1.2,
    H4: 1.25,
    H5: 1.3,
  },
};

baseConfig.motionEffects = {
  press: {
    whileTap: { scale: 0.985, transition: { type: "spring", duration: 0.3 } },
  },
};

baseConfig.appearance = {
  bigRadius: "1rem",
  smallRadius: "0.7rem",
};

baseConfig.Spinner = {
  color: (c) => c.colors.c4,
  stroke: 2,
};

baseConfig.effects = {
  bigShadow: (c) => `0px 4px 16px ${c.colors.shadowColor}, 0px 10px 44px -6px ${c.colors.shadowColor}`,
  smallShadow: (c) => `0px 1px 5px ${c.colors.shadowColor}, 0px 2px 10px -1px ${c.colors.shadowColor}`,
};

baseConfig.divider = {
  color: (c) => c.colors.c6,
};

baseConfig.Modal = {
  width: "32rem",
};

baseConfig.sidebar = {
  width: "400px",
  floatingAt: "900px",
  innerBorders: (c) => `1px solid ${c.colors.c6}`,
  background: (c) => c.colors.bg4, // invert with header bg
  headerFooterBackground: (c) => c.colors.bg1,
  padding: {
    lateral: "14px",
    vertical: "16px",
    header: "1rem",
  },
  handle: {
    color: (c) => c.colors.c4,
  },
};

baseConfig.Tooltip = {
  maxWidth: 240,
  titleStyle: (c) => ({
    color: c.colors.c2,
    fontSize: "14.5px",
    lineHeight: 1.3,
    fontWeight: 500,
  }),
  hotkeyStyle: (c) => ({
    color: c.colors.c3,
    fontSize: "14px",
  }),
  descriptionStyle: (c) => ({
    color: c.colors.c3,
    fontSize: "14px",
    lineHeight: 1.35,
  }),
  contentStyle: {
    padding: "2px 0px 3px",
    display: "flex",
    flexFlow: "column nowrap",
  },
};

baseConfig.DropDown = {
  preferX: "RIGHT",
  border: (c) => `1px solid ${c.colors.uiBorderColor}`,
  textColor: (c) => c.colors.c1,
  fontSize: "15px",
  iconColor: (c) => c.colors.c3,
  activeColor: (c) => c.colors.c1,
  activeBackgroundColor: (c) => c.colors.bg4,
  iconPadding: ".5em",
  optionsIconSize: "17px",
  optionsImageSize: "1.4rem",
  optionsImageCornerRadius: "0.2em",
  headerPadding: "0.2rem 0.8rem",
  detailsSize: "0.8rem",
  detailsColor: (c) => c.colors.c4,
  wrapperStyle: {
    lateralPadding: "13px",
    verticalPadding: "5px",
    scrollableHeight: "300px",
  },
};

// baseConfig.DropDownMenu = {
//   buttonColor: "white",
// };

baseConfig.layout = {
  padding: "16px",
  width: "1040px",
};

baseConfig.Stack = {
  gap: 2,
};

baseConfig.Section = {
  padding: "16px",
};

baseConfig.TabBar = {
  background: (c) => c.colors.bg2,
  className: "tab-bar",
};

baseConfig.Tabs = {
  tabStyle: (c) => ({
    fontSize: "15px",
    padding: ".4em",
    height: "30px",
    margin: "0 .2em",
    color: c.colors.c2,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    lineHeight: 1,
  }),
  activeTabStyle: (c) => ({
    color: c.colors.accent1,
  }),
  underlineStyle: (c) => ({
    height: "2px",
    background: c.colors.accent1,
  }),
  arrows: (c) => ({
    color: c.colors.c3,
    icons: ["chevronLeft", "chevronRight"],
    padding: c.layout.padding,
  }),
  breakPoint: "0px",
};

baseConfig.Header = {
  background: "transparent",
  border: "none",
  shadow: "none",
  height: "3.2em",
  linksGap: "0.6em",
  breakPoint: "650px",
  centerLinksOnFullscreen: true,
  centeredElementsZoom: 1.3,
  fixed: false,
  // centeredElementsGap: "1.2rem",
};

baseConfig.Slider = {
  trackColor: (c) => c.colors.bg0,
  trackHeight: 14,
  thumbSize: 18,
  border: (c) => `1px solid ${c.colors.uiBorderColor}`,
};

baseConfig.ProgressBar = {
  background: (c) => c.colors.c5,
};

baseConfig.breadcrumbs = {
  spacing: "0px",
  separator: {
    icon: "slash",
    color: (c) => c.colors.c4,
    size: "18px",
    strokeWidth: "2px",
  },
  crumbStyle: {
    fontSize: "17px",
    fontWeight: 500,
  },
  emphasizedCrumbStyle: {
    fontWeight: 500,
    color: (c) => c.colors.c2,
  },
};

baseConfig.floatingFooter = {
  background: (c) => c.colors.bg0,
  border: (c) => `1px solid ${c.colors.uiBorderColor}`,
  height: "4em",
  shadow: (c) => `0 -4px 10px ${c.colors.shadowColor}`,
};

baseConfig.Card = {
  // References in main.css
  bigPadding: `22px`,
  smallPadding: `16px`,
  // border: "none",
  smallRadius: "12px",
};

baseConfig.CardFooter = {
  border: (c) => `1px solid ${c.colors.uiBorderColor}`,
  smallRadius: "12px",
};

baseConfig.SubtleCard = {
  background: (c) => c.colors.c3,
  alpha: 0.06,
  border: "none",
};

baseConfig.Callout = {
  color: (c) => c.colors.c2,
  iconSize: "1.6rem",
  alphas: {
    background: 0.05,
    body: 0.9,
  },
  borders: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  style: {
    padding: "12px 14px",
    borderRadius: (c) => c.Button.radius,
  },
};

baseConfig.Button = {
  textColor: "white",
  darkColor: "hsl(220, 6%, 46%)",
  height: "38px",
  shadowSize: `0 2px 2px`,
  shadowAlpha: 0.08,
  padding: "0 13px",
  radius: "9px",
  variantProps: {
    small: {
      height: "32px",
      padding: "0 12px",
      radius: "0.5rem",
      fontSize: "14px",
      lineHeight: 1.2,
      iconSize: "14px",
    },
    secondary: {
      backgroundAlpha: 0.08,
      color: (c) => c.colors.c2,
      hoverColor: (c) => c.colors.c1,
    },
  },
};

baseConfig.Notifications = {
  position: "bottom-left",
  toastOptions: (c) => ({
    className: "notif",
    duration: 4000,
    style: {
      fontSize: "14.5px",
      color: c.colors.c6,
      background: c.colors.c2,
      boxShadow: `0px 2px 5px ${c.colors.shadowColor}, 0px 5px 12px ${c.colors.shadowColor}`,
      borderRadius: c.appearance.smallRadius,
      padding: "4px 6px 4px 8px",
    },
  }),
};

baseConfig.Toolbar = {
  background: (c) => c.colors.toolbarBackground,
  padding: "6px 10px",
};

baseConfig.ToolbarButton = {
  hoverBackground: (c) => c.colors.bg5,
  padding: "7px 9px 0px",
};

baseConfig.SegmentedControl = {
  radius: "12px",
  padding: "3px",
  iconProps: (c) => ({
    mr: c.Button.iconGap,
    size: "14px",
    offset: "1px",
  }),
  style: {
    boxShadow: "inset 0px 0px 1px 1px rgba(0,0,0,.05)",
  },
  activeStyle: (c) => ({
    color: "white",
  }),
  buttonStyle: (c) => ({
    background: "transparent",
    margin: 0,
    color: c.colors.c3,
    height: "30px",
    padding: "0 0.9em",
    fontSize: "14.5px",
  }),
  backdropStyle: (c) => ({
    borderRadius: c.Button.radius,
    background: c.colors.accent1,
    shadowAlpha: 0.1,
    shadowSize: `0 2px 2px 1px`,
    hoverLighten: 3,
    activeLighten: -3,
    ...c.motionEffects.press,
  }),
};

baseConfig.iconBadge = {
  backgroundAlpha: 0.08,
};

baseConfig.CircleButton = {
  size: "34px",
  iconSize: "20px",
};

baseConfig.Input = {
  colorFocus: (c) => c.colors.accent2,
  height: "36px",
  outlineWidth: 3,
  outlineAlpha: 0.3,
  iconColor: (c) => c.colors.c4,
  iconStrokeWidth: 2.5,
};

baseConfig.Switch = {
  normal: {
    width: 38,
    height: 26,
    padding: 3,
  },
  small: {
    width: 32,
    height: 20,
    padding: 2,
  },
};

baseConfig.inlineLinks = {
  color: "inherit",
  fontWeight: "500",
  border: {
    show: true,
    size: "1px",
    color: (c) => c.colors.c1,
    alpha: 0.25,
  },
};

baseConfig.Icon = {
  color: baseConfig.colors.c2,
  strokeWidth: 2.5,
  strokeInButtons: 3,
  size: "18px",
  sizeInButtons: "17px",
};

/**
 * DARK CONFIG
 */

darkConfig.DropDown = {
  wrapperStyle: {
    background: (c) => c.colors.bg2,
    activeBackgroundColor: (c) => c.colors.bg1,
  },
};

darkConfig.Button = {
  shadowSize: `0`,
  invertLuminanceThreshold: true,
};

darkConfig.divider = {
  color: (c) => c.colors.uiBorderColor,
};

darkConfig.sidebar = {
  background: (c) => c.colors.bg4, // invert with header bg
  headerFooterBorder: (c) => `1px solid ${c.colors.uiBorderColor}`,
};

darkConfig.CircleButton = {
  shadowSize: "0",
};

export { baseConfig, darkConfig };
