import { Box, Button, Header, MediaQuery, P1, useBreakpoint, useConfig } from "@mailbrew/uikit";
import Link from "next/link";
import urls from "../urls";
import { usePaywallState } from "./PaywallStateProvider";

export default function MonetizationStatusBanner() {
  const config = useConfig();

  const { user, monetization, setPaywallModalShown } = usePaywallState();

  function showPaywall() {
    setPaywallModalShown(true);
  }

  if (!user) return null;

  // current_period_end, cancel_at_period_end
  const monetizationStatus = monetization.status;

  const linkStyle = {
    color: config.colors.accent1,
    border: "none",
    fontWeight: "600",
  };

  const paragraphProps = { size: "15px", weight: "500", color: config.colors.c1, align: "center" };
  const isTrialing = monetizationStatus === "trialing" && !user.monetization.data.is_in_grace_period;
  const isInGracePeriod = monetizationStatus === "trialing" && user.monetization.data.is_in_grace_period;
  const trialAndGracePeriodEnded = monetizationStatus === "trial_ended" && !user.monetization.data.valid;
  const trialDaysLeft = user.monetization.data.days_left;

  if (isTrialing && trialDaysLeft <= 13) {
    return (
      <Container>
        {(() => {
          if (trialDaysLeft > 3) {
            return (
              <P1 {...paragraphProps}>
                <MediaQuery showAbove={640} display="inline">
                  You have{" "}
                </MediaQuery>
                {trialDaysLeft} days left on your trial.{" "}
                <Button variant="link" style={linkStyle} onClick={showPaywall}>
                  Upgrade here.
                </Button>
              </P1>
            );
          }
          if (trialDaysLeft > 1) {
            return (
              <P1 {...paragraphProps}>
                Trial almost over.{" "}
                <Button variant="link" style={linkStyle} onClick={showPaywall}>
                  Upgrade now.
                </Button>
              </P1>
            );
          }
          if (trialDaysLeft === 1) {
            return (
              <P1 {...paragraphProps}>
                Last day on your trial.{" "}
                <Button variant="link" style={linkStyle} onClick={showPaywall}>
                  Upgrade now.
                </Button>
              </P1>
            );
          }
          return (
            <P1 {...paragraphProps}>
              You trial ended.{" "}
              <Button variant="link" style={linkStyle} onClick={showPaywall}>
                Upgrade now.
              </Button>
            </P1>
          );
        })()}
      </Container>
    );
  }

  if (isInGracePeriod || trialAndGracePeriodEnded) {
    return (
      <Container variant="red">
        <P1 {...paragraphProps}>
          Trial ended.{" "}
          <Button variant="link" style={linkStyle} onClick={showPaywall}>
            Upgrade now.
          </Button>
        </P1>
      </Container>
    );
  }

  if (monetizationStatus === "canceled") {
    return (
      <Container variant="red">
        <P1 {...paragraphProps}>
          Invalid Subscription.{" "}
          <Button variant="link" style={linkStyle} onClick={showPaywall}>
            Subscribe again.
          </Button>
        </P1>
      </Container>
    );
  }

  if (monetizationStatus === "canceled_with_access" && monetization.data.current_period_end_days_left < 30) {
    return (
      <Container variant="red">
        <P1 {...paragraphProps}>
          Subscription canceled.{" "}
          <Button variant="link" style={linkStyle} onClick={showPaywall}>
            Reactivate it.
          </Button>
        </P1>
      </Container>
    );
  }

  if (monetizationStatus === "past_due") {
    return (
      <Container variant="red">
        <P1 {...paragraphProps}>
          Last charge failed.{" "}
          <Link href={urls.settings_billing()} passHref>
            <a style={linkStyle}>Fix payment method.</a>
          </Link>
        </P1>
      </Container>
    );
  }

  return null;
}

const Container = ({ children, variant = "lightOrange" }) => {
  const config = useConfig();
  const hit = useBreakpoint(375);

  let bgColor;
  let borderColor;

  if (variant === "lightOrange") {
    bgColor = config.colors.lightOrange;
    borderColor = config.colors.lightOrangeBorder;
  } else if (variant === "red") {
    bgColor = config.colors.red;
    borderColor = config.colors.redBorder;
  }

  return (
    <Header
      background={bgColor}
      height="34px"
      border={`1px solid ${borderColor}`}
      breakPoint={0}
      mb={1}
      style={{ zoom: hit ? 0.9 : 1 }}
    >
      <Box w="100%" flexCenter>
        {children}
      </Box>
    </Header>
  );
};
