import { Box, SafeAreaInsetBottom, useBreakpoint, useConfig } from "@mailbrew/uikit";
import { useEffect, useRef, useState } from "react";

const SidebarFooter = ({ children }) => {
  const config = useConfig();
  const ref = useRef(null);

  const breakpointHit = useBreakpoint(800);

  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setFooterHeight(ref.current.offsetHeight);
    }
  }, [ref, children]);

  return (
    <>
      <Box h={`${footerHeight - 4}px`} style={{ flex: `0 0 ${footerHeight - 4}px` }} />
      <Box
        px={config.sidebar.padding.lateral}
        py={config.sidebar.padding.vertical}
        w={breakpointHit ? "100%" : config.sidebar.width}
        maxW="100%"
        fixed
        bottom={0}
        bg={config.colors.bg4}
        bt={`1px solid ${config.colors.uiBorderColor}`}
        ref={ref}
        style={{
          WebkitTransform: "translate3d(0,0,0)",
          WebkitBackfaceVisibility: "hidden",
          zIndex: 4,
        }}
      >
        {children}
        <SafeAreaInsetBottom />
      </Box>
    </>
  );
};

export default SidebarFooter;
