import { notif, useDetectBrowser } from "@mailbrew/uikit";
import copy from "copy-to-clipboard";

export default function useHandleShareUrl() {
  const { hasTouch } = useDetectBrowser();

  function handleShare(url, title) {
    if (typeof navigator !== "undefined" && navigator.share && hasTouch) {
      try {
        navigator.share({
          title,
          url: url,
        });
      } catch (err) {
        // navigator.share throws if the user cancels sharing
      }
    } else {
      notif.success("Link copied");
      copy(url);
    }
  }

  return handleShare;
}
