import { useBrowserStorage } from "@mailbrew/uikit";
import { usePaywallState } from "components/PaywallStateProvider";
import { sourcesData } from "data/sourcesData";
import api from "dependencies/api";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { currentNewsletterSelector, removeSource, sourceBeingEditedIndexSelector } from "reducers/newslettersReducer";
import { mutate } from "swr";
import urls from "urls";
import { isClient } from "utils/env";
import { keyNewsletterById } from "utils/swrKeys";

export default function useEditorBackNavigationHandler() {
  const router = useRouter();
  const dispatch = useDispatch();
  const newsletter = useSelector(currentNewsletterSelector);
  const sourceIndex = useSelector(sourceBeingEditedIndexSelector);
  const isRootPage = useIsEditorRootPage();
  const [visitedPages] = useBrowserStorage("mb:visited:pages", [], isClient ? window.sessionStorage : null);
  const prevPathname = visitedPages?.find((page) => !page.startsWith("/edit"));
  const { afterOnboarding } = usePaywallState();

  function navigate() {
    // navigate back from inside a source
    if (!isRootPage) {
      const source = newsletter.sources?.[sourceIndex];

      if (source) {
        const validator = sourcesData[source.type].isValid;
        if (validator) {
          const isSourceValid = validator(source);
          if (!isSourceValid) {
            dispatch(removeSource(sourceIndex));
          }
        }
      }

      router.replace(urls.editNewsletter(newsletter.id));
      return;
    }
    // navigate back to the app
    else {
      // after onboarding we always want to go to /
      if (afterOnboarding) {
        router.push(urls.brews() + "?autogenerate=true", urls.brews());
        return;
      }
      // generic handling
      else if (prevPathname) {
        router.push(prevPathname);
        return;
      }
    }
    // fallback
    router.push(urls.manageBrews());
  }

  return () => {
    // delete empty newsletter when navigating away from the editor
    if (isRootPage && newsletter && newsletter.untitled && !newsletter.sources?.length) {
      // delete then navigate

      api
        .delete(keyNewsletterById(newsletter.id))
        .finally(() => mutate("/newsletters/"))
        .then(navigate);
    } else {
      // navigate immediately
      navigate();
    }
  };
}

export function useIsEditorRootPage() {
  const router = useRouter();
  const pathname = router.asPath;
  return !!pathname.match(/\/edit\/[^/]+$/);
}
