/* eslint-disable jsx-a11y/accessible-emoji */
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Section, useConfig, useEventListener } from "@mailbrew/uikit";
import { useRef, useState } from "react";
import zIndexes from "zIndexes";

const StickySection = ({ children, style, ...otherProps }) => {
  const config = useConfig();
  const ref = useRef(null);

  const [isAtTop, setIsAtTop] = useState(false);

  useEventListener("scroll", (e) => {
    if (ref.current) {
      const currentScrollPosition = ref.current.getBoundingClientRect().top;
      setIsAtTop(currentScrollPosition < 2);
    }
  });

  return (
    <Section
      bg={config.colors.bg1}
      zIndex={zIndexes.brewsTabs}
      ref={ref}
      noPadding
      noLateralPadding
      width="100%"
      style={{
        transition: "0.3s",
        position: "sticky",
        top: 0,
        borderBottom: `1px solid ${config.colors.uiBorderColor}`,
        flex: "0 1 auto",
        ...style,
      }}
      // The following CSS removes blurred background on top of Safari UI during scrolling
      css={
        isAtTop
          ? css`
              position: relative;
              ::before {
                content: "";
                position: absolute;
                top: -150px;
                left: 0;
                width: 100%;
                height: 150px;
                background: ${config.colors.bg1};
              }
            `
          : null
      }
      {...otherProps}
    >
      {children}
    </Section>
  );
};

export default StickySection;
