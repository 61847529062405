import { usePaywallState } from "components/PaywallStateProvider";
import useSWR from "swr";

export default function useShowSourceProBadge(sourceType) {
  const { user, config } = usePaywallState();
  const { data: proSources } = useSWR(`/paid_sources/`);

  if (!config.freemium) return false;

  const isLocked = !user?.monetization.data.features.sources.includes(sourceType);
  const isPro = proSources?.includes(sourceType);

  return isLocked || isPro;
}
