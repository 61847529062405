import { P1, SmallText, Spacer } from "@mailbrew/uikit";
import { useDispatch, useSelector } from "react-redux";
import { userTwitterAccountSelector } from "reducers/authReducer";
import { removeTwitterAccount } from "reducers/settingsReducer";
import { connectTwitterAccount } from "utils/twitterAuth";
import ServiceCard from "components/ServiceCard";

const ConnectedTwitterAccount = ({ align = "left" }) => {
  const twitterAccount = useSelector(userTwitterAccountSelector);

  const dispatch = useDispatch();

  const onClickRemoveTwitterAccount = async () => {
    await dispatch(removeTwitterAccount());
  };

  const onClickReconnectTwitterAccount = async () => {
    connectTwitterAccount();
  };

  const twitterAccountConnected = twitterAccount && !twitterAccount.error;

  return (
    <>
      {!twitterAccount?.screen_name ? (
        <P1 mb={2} align={align}>
          Please authenticate to let us retrieve tweets.
        </P1>
      ) : (
        <Spacer size={2} />
      )}
      <ServiceCard
        type="twitter"
        connected={twitterAccountConnected}
        user={twitterAccount?.screen_name}
        onClickRemove={onClickRemoveTwitterAccount}
        onClickReconnect={onClickReconnectTwitterAccount}
      />
      {!twitterAccount?.screen_name && (
        <SmallText align={align}>
          No Twitter account?{" "}
          <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
            Sign up here.
          </a>
        </SmallText>
      )}
    </>
  );
};

export default ConnectedTwitterAccount;
