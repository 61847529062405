import { P2, SegmentedControl, useConfig } from "@mailbrew/uikit";
import SettingSwitchRow from "components/editor/SettingSwitchRow";
import SourceEditorSection from "components/editor/SourceEditorSection";
import ColorPicker from "components/editor/ColorPicker";

const QuoteMode = (props) => {
  const { enabled, onEnabledChange, color, onColorChange, quoteTextOption, onQuoteTextOptionChange } = props;

  const config = useConfig();

  return (
    <>
      <SourceEditorSection>
        <SettingSwitchRow
          state={enabled}
          onChange={onEnabledChange}
          copy="Quote Mode"
          icon="quote"
          color={config.colors.c3}
        />
        <P2>Format the post title or body like a big quote.</P2>
      </SourceEditorSection>
      {enabled && (
        <>
          <SourceEditorSection title="Quote Text" icon="quote">
            <SegmentedControl
              color={config.colors.c3}
              options={["title", "body"]}
              optionsNames={["Post Title", "Post Body"]}
              active={quoteTextOption}
              onOptionChange={onQuoteTextOptionChange}
            />
          </SourceEditorSection>
          <SourceEditorSection title="Background Color" icon="bucket">
            <ColorPicker selectedColor={color} onChange={onColorChange} palette="quote" />
          </SourceEditorSection>
        </>
      )}
    </>
  );
};

export default QuoteMode;
