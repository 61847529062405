import { Expandable, Stack, useConfig } from "@mailbrew/uikit";
import ConnectedTwitterAccount from "components/editor/ConnectedTwitterAccount";
import SourceEditorSection from "components/editor/SourceEditorSection";
import { useState } from "react";
import { PillButton } from "./PillButton";

export default function ConnectTwitterAccountSection(props) {
  const config = useConfig();
  const [expanded, setExpanded] = useState(false);

  return (
    <SourceEditorSection {...props}>
      <Stack mb={3} align="center">
        <PillButton
          onClick={() => setExpanded(!expanded)}
          variant={["secondary", "pill"]}
          color={config.colors.twitter}
          icon="twitter"
        >
          {expanded ? "Hide" : "Show"} Connected Account
        </PillButton>
      </Stack>
      <Expandable expanded={expanded} overflow="hidden">
        <ConnectedTwitterAccount />
      </Expandable>
    </SourceEditorSection>
  );
}
