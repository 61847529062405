const indexes = {
  userMenu: 2,
  brewsTabs: 2,
  brewPreview: 3,
  inboxMessageModal: 5,
  tabBar: 4,
  inboxModalKeepReading: 6,
  helpWidget: 8,
  readerViewModal: 13,
  embedCodeModal: 15,
};

// Convert to strings for our UI Kit components
let parsedIndexes = {};
Object.keys(indexes).forEach((key) => (parsedIndexes[key] = indexes[key].toString()));

export default parsedIndexes;
